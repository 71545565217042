import { useEffect, useState } from 'react'

import Select from '../graphic/Select'

// import { getUserGroups } from '../../lib/adminFunctions'
import useVersionStore from '../../lib/stores/version'
import useDocumentStore from '../../lib/stores/document'

export default function SelectVersion() {
	// const [userGroups, setUserGroups] = useState()
	const [selected, setSelected] = useState()
	const [options, setOptions] = useState()

	const { versions, selectedVersion, selectedVersionIndex, versionNumber, fetchVersions, selectVersion, deleteVersion, newVersion } = useVersionStore(
		(state) => ({
			versions: state.versions,
			selectedVersion: state.selectedVersion,
			selectedVersionIndex: state.selectedVersionIndex,
			versionNumber: state.versionNumber,
			fetchVersions: state.fetchVersions,
			selectVersion: state.selectVersion,
			deleteVersion: state.deleteVersion,
			newVersion: state.newVersion,
		})
	)

	const { removeVersion, setVersion, id } = useDocumentStore((state) => ({
		removeVersion: state.removeVersion,
		setVersion: state.setVersion,
		id: state.document && state.document.id,
	}))

	useEffect(() => {
		//
		fetchVersions()
	}, [id, fetchVersions])

	useEffect(() => {
		let newOptions = Array.isArray(versions)
			? versions.map((version, i) => {
					return { value: version._id, label: version.note ? version.note : version.versionNumber, index: i }
			  })
			: []

		newOptions = [
			{ value: 'remove', label: 'Versione Base' },
			{ value: 'add', label: 'Aggiungi versione' },
			...newOptions,
			{ value: 'delete', label: 'Elimina versione selezionata' },
		]

		setOptions(newOptions)
	}, [versions])

	useEffect(() => {
		//
		if (Array.isArray(options)) {
			let newSelected = selectedVersion ? options.find((option) => option.value === selectedVersion._id) : null

			if (!newSelected) {
				newSelected = options[0]
			}
			newSelected = newSelected.value

			setSelected(newSelected)
		}
	}, [options, versions, selectedVersion, selectedVersionIndex, versionNumber])

	// console.log(selected, selectedVersion)

	// console.log(versions, options)

	return (
		<div className="center-it-div" style={{ marginLeft: '5px' }}>
			<Select
				selected={selected}
				options={options}
				onSelect={(option, i, event) => {
					if (option.index !== undefined && option.index >= 0) {
						selectVersion(option.index)
						setVersion(versions[option.index])
					} else {
						switch (option.value) {
							case 'delete':
								let r = window.confirm('Sei sicuro di voler Eliminare questa versione?')

								if (r) {
									deleteVersion()
								}
								break
							case 'remove':
								removeVersion()
								break
							case 'add':
								newVersion()
								// setVersion()
								break
							default:
						}
					}
				}}
			/>
		</div>
	)
}
