import create from 'zustand'
// import { sha256 } from 'js-sha256'
// import axios from 'axios'

// import useDocumentStore from './document'
// import useQuotesStore from './quotes'
// import useProductsStore from './products'
// import useRegistersStore from './registers'
// import useConfigurationStore from './configurations'

// let serverUrl = process.env.REACT_APP_SERVER_URL

const initialSessionStore = {
	loggedIn: false,
	loading: true,
}

// Store for user User, possible TODO: load user info from server??
const useSessionStore = create((set, get) => ({
	...initialSessionStore,
	setLoading: (value) => set({ loading: value }),
	setLoggedIn: (value) => set({ loggedIn: value }),
	login: () => set({ loggedIn: true }),
	logout: () => set({ loggedIn: false }),
}))

export default useSessionStore
