import { get, set, del } from 'idb-keyval'

const IDBStorage = {
	getItem: async (name) => {
		// Exit early on server
		if (typeof indexedDB === 'undefined') {
			return null
		}

		return (await get(name)) || null
	},
	setItem: async (name, value) => {
		// Exit early on server
		if (typeof indexedDB === 'undefined') {
			return null
		}
		set(name, value)
	},
	removeItem: async (name) => {
		// Exit early on server
		if (typeof indexedDB === 'undefined') {
			return null
		}
		del(name)
	},
}

export default IDBStorage
