import { useState, useRef } from 'react'

import isChildOf from '../../lib/isChildOf'

export default function Select({ options, selected, open, placeholder = "Selezionare un'opzione...", onSelect = () => {} }) {
	const [isOpen, setIsOpen] = useState(false)
	const inputRef = useRef()

	function handleKeyDownMacros(event, index, type, option) {
		if (event.ctrlKey) {
			switch (event.key) {
				case 'q':
					event.preventDefault()
					setIsOpen(false)
					break
				case 'Enter':
					event.preventDefault()
					break
				default:
			}
		} else {
			switch (event.key) {
				case 'Escape':
					event.preventDefault()
					setIsOpen(false)
					break
				case 'Enter':
					if (option) {
						event.preventDefault()
						setIsOpen(false)
						// if (searchInputRef.current) searchInputRef.current.focus()
					}
					break
				case 'ArrowUp':
					if (type === 'list') {
						if (event.target.previousElementSibling && typeof event.target.previousElementSibling.focus === 'function') {
							event.preventDefault()
							event.target.previousElementSibling.focus()
						}
					}

					break
				case 'ArrowDown':
					if (type === 'list') {
						if (event.target.nextElementSibling && typeof event.target.nextElementSibling.focus === 'function') {
							event.preventDefault()
							event.target.nextElementSibling.focus()
						}
					} else if (type === 'input') {
						if (
							event.target.nextElementSibling &&
							event.target.nextElementSibling.firstChild &&
							typeof event.target.nextElementSibling.firstChild.focus === 'function'
						) {
							event.preventDefault()
							event.target.nextElementSibling.firstChild.focus()
						}
					}
					break
				default:
			}
		}
	}

	let selectedIndex = -1

	if (Array.isArray(options)) {
		selectedIndex = options.findIndex((option) => selected !== undefined && option.value === selected)
	}

	// console.log(selectedIndex, options, selected)

	return (
		<div className="select-container">
			{/* <div
				ref={inputRef}
				className="select-input"
				tabIndex="-1"
				// placeholder={placeholder}
				value={(Array.isArray(options) && options[selectedIndex] && options[selectedIndex].label) || ''}
				onFocus={(e) => {
					setIsOpen(true)
				}}
				onBlur={(e) => {
					if (!isChildOf(e.target.nextElementSibling, e.relatedTarget)) {
						setIsOpen(false)
					}
				}}
				onKeyDown={(e) => handleKeyDownMacros(e, 0, 'input')}
				onInput={(e) => e.preventDefault()}
			>
				{(Array.isArray(options) && options[selectedIndex] && options[selectedIndex].label) || placeholder || ''}
			</div> */}
			<input
				ref={inputRef}
				className="select-input"
				placeholder={placeholder}
				value={(Array.isArray(options) && options[selectedIndex] && options[selectedIndex].label) || ''}
				onFocus={(e) => {
					setIsOpen(true)
				}}
				onBlur={(e) => {
					if (!isChildOf(e.target.nextElementSibling, e.relatedTarget)) {
						setIsOpen(false)
					}
				}}
				onKeyDown={(e) => handleKeyDownMacros(e, 0, 'input')}
				onInput={(e) => e.preventDefault()}
			/>
			<div className={`select-options-window ${open === undefined ? (isOpen ? 'open' : '') : open ? 'open' : ''}`}>
				{Array.isArray(options) &&
					options.map((option, i) => {
						return (
							<div
								key={i}
								tabIndex="-1"
								className="select-option"
								onClick={(event) => {
									onSelect(option, i, event)
								}}
								onKeyDown={(e) => handleKeyDownMacros(e, i, 'list', option)}
								onBlur={(e) => {
									// console.dir(e.relatedTarget)
									if (!isChildOf(e.target.parentNode, e.relatedTarget) && e.target.parentNode.previousElementSibling !== e.relatedTarget) {
										setIsOpen(false)
									}
								}}
							>
								{option.label}
							</div>
						)
					})}
			</div>
		</div>
	)
}
