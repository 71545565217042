import { useCallback, useEffect, useMemo, useState } from 'react'

export default function useIsMobile(maxWidth = 768) {
	let [width, setWidth] = useState(null)

	let handleWidthChange = useCallback((newWidth) => {
		setWidth(newWidth)
	}, [])

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const newWidth = entry.borderBoxSize?.[0].inlineSize
				handleWidthChange(newWidth)
			}
		})

		observer.observe(document.body, { box: 'border-box' })
	}, [handleWidthChange])

	let isMobile = useMemo(() => width && width < maxWidth, [width, maxWidth])
	return isMobile
}
