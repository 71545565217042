export default function calculateItemLength(item) {
	let lengthString = item.showLength || (item.product && item.product.category === 'profiles') ? item.length : 1
	let length
	let lengthUnit

	length = typeof lengthString === 'string' ? lengthString.match(/^[+-]?[0-9]*[.,]?[0-9]*/gimu) : 1
	lengthUnit = typeof lengthString === 'string' ? lengthString.match(/[a-zA-Z]+/gimu) : ''
	lengthUnit = Array.isArray(lengthUnit) && lengthUnit.length > 0 ? lengthUnit[0] : ''

	let lengthDisclaimer = false

	if (length === 1 || !length || length.length < 1 || !length[0]) {
		length = 1
	} else {
		length = length[0]
	}
	if (lengthUnit.toLowerCase() === 'mm' || lengthUnit.toLowerCase() === 'millimetri') {
		length = length / 10
	} else if (lengthUnit.toLowerCase() === 'm' || lengthUnit.toLowerCase() === 'metri') {
		length = length * 100
	} else if (length <= 6) {
		length = length * 100
	} else if (length < 50) {
		lengthDisclaimer = true
	} else if (length >= 1000) {
		length = length / 10
	}

	if (length > 600) {
		lengthDisclaimer = true
		length = 600
	}

	return [length, lengthDisclaimer, lengthUnit]
}
