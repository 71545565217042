import React from 'react'

import CustomProduct from '../../../../components/elements/CustomProducts'

import configurationStore from '../../configurations'
import userStore from '../../user'

export default async function exportText(get, set) {
	const document = get().document
	const configurations = configurationStore.getState().configurations
	const getUserGroup = userStore.getState().getUserGroup
	// const getUserGroupId = userStore.getState().getUserGroupId
	const exportCod = configurations.exportCod
	const exportIva = configurations.exportIva
	const exportRemovePrice = configurations.exportRemovePrice
	const exportDiscountPrices = configurations.exportDiscountPrices

	let userGroup = await getUserGroup()
	// let userGroupDownload = await getUserGroupId(document && document.userGroupId)

	userGroup = userGroup && userGroup.data

	let date
	if (document.quoteDate) {
		date = new Date(document.quoteDate)
	} else if (document.updatedAt) {
		date = new Date(document.updatedAt)
	} else {
		date = new Date(Date.now())
	}

	// if (document.id) {
	// 	let userSlug = user.slug
	// 	let id = document.id
	//	let userGroupId = userGroupDownload && userGroupDownload.id

	// 	id = id.replace(/^0+/, '')

	// 	pdfJson.content.push({
	// 		text: `id preventivo: ${userSlug}${userGroupId !== undefined ? '-' + userGroupId : ''}-${id}${
	// 			document.versionNumber ? '_' + document.versionNumber : ''
	// 		}`,
	// 		style: 'referer',
	// 	})
	// }

	return (
		<div style={{ overflow: 'auto', paddingRight: '4em' }}>
			<div suppressContentEditableWarning="true" contentEditable="true" style={{ outline: 'none' }} className="quote-html">
				{/* {content} */}
				<div>{`${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`}</div>
				<br />
				{Array.isArray(document.elements) &&
					document.elements.map((element, i) => {
						switch (element.type) {
							case 'title':
								if (element.title && element.title !== '') {
									return (
										<React.Fragment key={i}>
											<h2>{element.title}</h2>
											<br />
										</React.Fragment>
									)
								}
								break
							case 'subtitle':
								if (element.subtitle && element.subtitle !== '') {
									return (
										<React.Fragment key={i}>
											<h3>{element.subtitle}</h3>
											<br />
										</React.Fragment>
									)
								}
								break
							case 'description':
								if (element.plainText && element.plainText !== '') {
									let description = element.plainText ? element.plainText : ''
									if (typeof description === 'string') {
										description = description.replace(/(\r\n|\r|\n){3,}/g, '$1\n')
									}

									return (
										<React.Fragment key={i}>
											<p dangerouslySetInnerHTML={{ __html: element.description }}></p>
											<br />
										</React.Fragment>
									)
								}
								break
							case 'separator':
								return (
									<React.Fragment key={i}>
										<hr />
										<br />
									</React.Fragment>
								)
							case 'shipping':
								if (!element.shipping) return null
								return (
									<React.Fragment key={i}>
										<h3 style={{ margin: '3px' }}>Spedizione:</h3>
										<div style={{ marginLeft: '10px' }}>
											<b>Nome:</b>
											{element.shipping.name}
											&nbsp;
											<b>Prezzo: </b>
											{element.shipping.price}
											<br />
											<b>Note: </b>
											{element.shipping.notesPlainText}
										</div>
										<br />
									</React.Fragment>
								)
							case 'zone':
								let total = 0
								return (
									<React.Fragment key={i}>
										{element.title && element.title !== '' && (
											<>
												<h3>{element.title}</h3>
												<br />
											</>
										)}
										{Array.isArray(element.items) &&
											element.items.map((item, j) => {
												if (!isNaN(item.total)) {
													total -= -item.total
												}

												switch (item.type) {
													case 'customProduct':
														return (
															<>
																<br />
																<CustomProduct itemInfo={item.productInfo} quantity={item.quantity} />
																<br />
																{exportRemovePrice ? null : exportDiscountPrices ? (
																	<>
																		{(!isNaN(parseFloat(item.price)) && parseFloat(item.price).toFixed(2)) || '0.00'}€ + IVA
																		cad,&nbsp;
																		{item.discount ? item.discount : '0'}
																		&nbsp;
																		{/* {(!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00'}€
																		+ IVA cad, netto,&nbsp; */}
																		{(!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00'}€ + IVA
																		tot
																	</>
																) : (
																	<>
																		{(!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00'}€
																		+ IVA cad,&nbsp;
																		{(!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00'}€ + IVA
																		tot
																	</>
																)}
																<br />
															</>
														)
													case 'descriptiveItem':
														return (
															<div key={j}>
																{item.quantity}x,&nbsp;
																<b>
																	{exportCod && item.product ? `${item.product.cod || ''} ~ ${item.name}` : item.name}
																	&nbsp;
																</b>
																{exportRemovePrice ? null : exportDiscountPrices ? (
																	<>
																		{(!isNaN(parseFloat(item.price)) && parseFloat(item.price).toFixed(2)) || '0.00'}€ + IVA
																		cad,&nbsp;
																		{item.discount ? item.discount : '0'}
																		&nbsp;
																		{/* {(!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00'}€
																		+ IVA cad, netto,&nbsp; */}
																		{(!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00'}€ + IVA
																		tot
																	</>
																) : (
																	<>
																		{(!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00'}€
																		+ IVA cad,&nbsp;
																		{(!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00'}€ + IVA
																		tot
																	</>
																)}
															</div>
														)
													default:
														let info = item.infoPlainText ? item.infoPlainText : ''
														if (typeof info === 'string') {
															info = info.replace(/(\r\n|\r|\n){3,}/g, '$1\n')
														}

														return (
															<div key={j}>
																{item.quantity}x,&nbsp;
																<b>
																	{exportCod && item.product ? `${item.product.cod || ''} ~ ${item.name}` : item.name}
																	&nbsp;
																	{/* {item.product && item.product.category === 'profiles' && ', ' + item.length > 6
																		? `${item.length}cm`
																		: `${item.length * 100}cm`} */}
																	{item.product && item.product.category === 'profiles' && ', ' + item.length}
																	{/[a-z]+/gimu.test(item.length)
																		? ''
																		: item.length > 6
																		? item.length >= 1000
																			? 'mm'
																			: 'cm'
																		: 'm'}
																</b>
																&nbsp;
																{info ? `${info}, ` : ''}
																{exportRemovePrice ? null : exportDiscountPrices ? (
																	<>
																		{(!isNaN(parseFloat(item.price)) && parseFloat(item.price).toFixed(2)) || '0.00'}€ + IVA
																		cad,&nbsp;
																		{item.discount ? item.discount : '0'}
																		&nbsp;
																		{/* {(!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00'}€
																		+ IVA cad, netto,&nbsp; */}
																		{(!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00'}€ + IVA
																		tot
																	</>
																) : (
																	<>
																		{(!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00'}€
																		+ IVA cad,&nbsp;
																		{(!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00'}€ + IVA
																		tot
																	</>
																)}
															</div>
														)
												}
											})}
										<br />
										{exportRemovePrice ? null : (
											<>
												<span>
													Totale IVA esc.: <b>{(!isNaN(parseFloat(total)) && parseFloat(total).toFixed(2)) || '0.00'}€</b> + IVA
												</span>
												<br />
												{exportIva && (
													<span>
														Totale IVA inc: <b>{(!isNaN(parseFloat(total)) && (parseFloat(total) * 1.22).toFixed(2)) || '0.00'}€</b>
														<br />
													</span>
												)}
											</>
										)}
										<br />
										<br />
									</React.Fragment>
								)
							default:
								return null
						}
						return null
					})}
				{userGroup && userGroup.disclaimer && (
					<>
						<div>{userGroup.disclaimer}</div>
						<br />
						<br />
					</>
				)}
			</div>
			<style>{`h1,h2,h3,h4, b{font-weight: bold;} h2{font-size: 1.4em;} h3{font-size: 1.2em;} h4{font-size: 1.1em;} .quote-html{text-align: left;}`}</style>
		</div>
	)
	// download('', 'quote_text', 'text/html')
}
