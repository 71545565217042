import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'

export default function Seprator({ separator, elementIndex, provided }) {
	return (
		<div className="element">
			<hr className="element-separator element-full" />
			{provided && <DragElement provided={provided} />}
			<RemoveElement element={separator} elementIndex={elementIndex} mid={true} />
		</div>
	)
}
