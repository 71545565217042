import { useRef, useEffect } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'

import RemoveElement from '../RemoveElement'
import CopyElement from '../graphic/CopyElement'
import DragElement from '../graphic/DragElement'
import CutListItem from './CutListItem'

import useDocumentStore from '../../lib/stores/document'

import '../../styles/components/Zone.scss'
import React from 'react'

export default function CutList({ cutList, elementIndex, provided }) {
	const setElement = useDocumentStore((state) => state.setElement)
	const titleRef = useRef()

	// console.log(cutList)
	// console.log(modals)

	useEffect(() => {
		if (titleRef && titleRef.current) {
			titleRef.current.focus()
			titleRef.current.select()
		}
	}, [])

	function handleInput(key, value) {
		if (typeof setElement === 'function') {
			const element = { ...cutList }
			element[key] = value
			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	// function setItem(i, item) {
	// 	const items = [...cutList.items]
	// 	items[i] = item
	// 	handleInput('items', items)
	// }

	function copyItem(i, item) {
		const items = [...cutList.items]
		let temp = { ...items[i] }
		temp.id = uuidv4()
		items.splice(i + 1, 0, temp)
		handleInput('items', items)
	}

	// handle input on item (and update price if needed)
	function handleItemInput(index, keys, values) {
		const items = [...cutList.items]
		// console.log(index, key, value)

		if (items[index]) {
			let keysLength = Array.isArray(keys) ? keys.length : 1
			for (let i = 0; i < keysLength; i++) {
				let key = Array.isArray(keys) ? keys[i] : keys
				let value = Array.isArray(keys) ? values[i] : values

				items[index][key] = value
			}
			handleInput('items', items)
		}
	}

	function deleteItem(index) {
		const items = [...cutList.items]
		if (items.length > 1) {
			items.splice(index, 1)
			handleInput('items', items)
		}
	}

	function addItem(item) {
		const items = [...cutList.items]
		items.push({ id: uuidv4(), quantity: 1, name: '', length: 1, ...item })

		handleInput('items', items)
	}

	if (!cutList) {
		return null
	}

	return (
		<div className="element zone">
			<input
				ref={titleRef}
				className="zone-title element-title"
				value={cutList.title}
				placeholder="Distinta di taglio..."
				onChange={(e) => {
					e.preventDefault()
					handleInput('title', e.target.value)
				}}
			/>
			<div className="zone-content shadow">
				<div className="zone-products-list">
					<div>
						<div className="zone-product-header">
							<label className="zone-product-entry small">Qty</label>
							<label className="zone-product-entry grow">Prodotto</label>
							<label className="zone-product-entry medium">Lunghezza</label>
							{/* <label className="zone-product-entry drag">Drag</label> */}
						</div>
					</div>

					{provided ? (
						<Droppable droppableId={`droppableItemsElem_${elementIndex}`} index={elementIndex} type="droppableZoneItem">
							{(provided, snapshot) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{Array.isArray(cutList.items) &&
										cutList.items.map((item, i, arr) => {
											return (
												<Draggable draggableId={item.id} index={i} key={item.id}>
													{(provided, snapshot) => (
														<CutListItem
															item={item}
															i={i}
															provided={provided}
															handleItemInput={handleItemInput}
															copyItem={copyItem}
															addItem={addItem}
															deleteItem={deleteItem}
															autoselect={i !== 0}
														/>
													)}
												</Draggable>
											)
										})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					) : (
						<div>
							{Array.isArray(cutList.items) &&
								cutList.items.map((item, i, arr) => {
									return (
										<CutListItem
											key={item.id}
											item={item}
											i={i}
											provided={provided}
											handleItemInput={handleItemInput}
											copyItem={copyItem}
											addItem={addItem}
											deleteItem={deleteItem}
											autoselect={i !== 0}
										/>
									)
								})}
						</div>
					)}

					<div className="zone-product-footer">
						<label className="zone-product-entry small">
							{/* {true && ( */}
							<div
								className="zone-add-product"
								onClick={() => {
									addItem()
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
								</svg>
							</div>
							{/* )} */}
						</label>
					</div>
					{/* zone.items && zone.items.length < 1 */}
				</div>

				{provided && <DragElement provided={provided} />}
				<RemoveElement element={cutList} elementIndex={elementIndex} />
				<CopyElement element={cutList} elementIndex={elementIndex} />
			</div>
		</div>
	)
}
