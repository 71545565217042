import { useEffect, useRef } from 'react'
import { useDebounce } from 'rooks'

function clearStyle(target) {
	if (target) {
		// console.d(target.)
		// if (target.style && target.style.whiteSpace) target.style.whiteSpace = 'normal'
		target.style = {}
		for (let i = 0; i < target.children.length; i++) {
			clearStyle(target.children[i])
		}
	}
}

export default function ContentEditable({ value, html = true, updateValue, timer = 1000, ...props }) {
	const ref = useRef()

	// const onInputDebouced = useDebounce(props.onInput, timer)

	useEffect(() => {
		ref.current.innerHTML = value
		// eslint-disable-next-line
	}, [updateValue])

	useEffect(() => {
		if (value !== ref.current.innerHTML) {
			ref.current.innerHTML = value
		}
	}, [value])

	return (
		<div
			ref={ref}
			tabIndex="0"
			{...props}
			onInput={(e) => {
				// clear white space
				clearStyle(e.target)

				if (html) {
					e.target.value = e.target.innerHTML
				} else {
					e.target.value = e.target.innerText
				}
				e.target.htmlAsText = e.target.innerText

				props.onInput(e)
				// onInputDebouced(e)
			}}
			onPaste={(e) => {
				// e.target.innerHTML = e.target.innerText

				// if (html) {
				// 	e.target.value = e.target.innerHTML
				// } else {
				// 	e.target.value = e.target.innerText
				// }
				// e.target.htmlAsText = e.target.innerText

				// props.onInput(e)
				clearStyle(ref.current)

				if (html) {
					ref.current.value = ref.current.innerHTML
				} else {
					ref.current.value = ref.current.innerText
				}
				ref.current.htmlAsText = ref.current.innerText

				props.onInput({ target: ref.current })
				// onInputDebouced({ target: ref.current })
			}}
			suppressContentEditableWarning={true}
			contentEditable={true}
		></div>
	)
}
