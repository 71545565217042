import { useEffect } from 'react'
import { add, round } from 'mathjs'

import useDocumentStore from '../lib/stores/document'

export default function Footer() {
	const { document, priceTotal, forcePriceUpdate, setPriceTotal, setIdList } = useDocumentStore((state) => ({
		document: state.document,
		forcePriceUpdate: state.forcePriceUpdate,
		priceTotal: state.priceTotal,
		setPriceTotal: state.setPriceTotal,
		idList: state.idList,
		setIdList: state.setIdList,
	}))

	// console.log(idList)

	// console.log(document)

	useEffect(() => {
		let tempPrice = 0
		let newIdList = []
		if (document && Array.isArray(document.elements)) {
			document.elements.forEach((element) => {
				switch (element.type) {
					case 'cutDrawing':
						if (element.compositionInfo) {
							if (!isNaN(element.compositionInfo.price)) tempPrice = add(tempPrice, element.compositionInfo.price)
							if (element.compositionInfo?.id && !newIdList.includes(element.compositionInfo.id)) newIdList.push(element.compositionInfo.id)
						}
						break
					case 'zone':
						if (Array.isArray(element.items)) {
							element.items.forEach((item) => {
								if (!isNaN(item.total)) tempPrice = add(tempPrice, item.total)
								if (item?.productInfo?.id && !newIdList.includes(item.productInfo.id)) newIdList.push(item.productInfo.id)
							})
						}
						break
					case 'shipping':
						if (element.shipping) {
							if (!isNaN(element.shipping.price)) tempPrice = add(tempPrice, element.shipping.price)
						}
						break
					default:
				}
			})
		}

		// setPrice(tempPrice)
		setPriceTotal(tempPrice)
		setIdList(newIdList)
	}, [document, setPriceTotal, setIdList, forcePriceUpdate])

	return <footer className="footer">Prezzo Totale = {round(priceTotal, 2)}€</footer>
}
