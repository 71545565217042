import { useEffect, useState, useMemo } from 'react'

import Pagination from '../graphic/Pagination'
import Filters from '../graphic/Filters'
import RegisterForm from './RegisterForm'

import useFilters from '../../lib/useFilters'
import useDocumentStore from '../../lib/stores/document'
import useModalStore from '../../lib/stores/modal'
import useRegistersStore from '../../lib/stores/registers'
import useUserStore from '../../lib/stores/user'

import '../../styles/components/List.scss'

export default function RegistersList() {
	const addModal = useModalStore((state) => state.addModal)
	const setRegister = useDocumentStore((state) => state.setRegister)
	const { _hasHydrated, fetchRegisters, registers, deleteManyRegisters } = useRegistersStore((state) => ({
		_hasHydrated: state._hasHydrated,
		fetchRegisters: state.fetchRegisters,
		registers: state.registers,
		deleteManyRegisters: state.deleteManyRegisters,
	}))
	const user = useUserStore((state) => state.user)

	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)
	const [attributes, setAttributes] = useState([
		{ key: 'email', enabled: false, visible: false },
		{ key: 'firstname', enabled: false, visible: false },
		{ key: 'lastname', enabled: false, visible: false },
		{ key: 'fiscalCode', enabled: false, visible: false },
		{ key: 'company', enabled: false, visible: false },
		{ key: 'certEmail', enabled: false, visible: false },
	])
	const [searchString, setSearchString] = useState('')
	const [selectedList, setSelectedList] = useState({})

	// Update registers, downloading from server After loading from local
	useEffect(() => {
		if (_hasHydrated && typeof fetchRegisters === 'function') {
			fetchRegisters()
		}
	}, [_hasHydrated, fetchRegisters])

	const filteredData = useFilters({ data: registers, attributes: attributes, searchString: searchString })

	const pageData = useMemo(() => {
		if (!Array.isArray(filteredData)) {
			return []
		}

		const firstPageIndex = (currentPage - 1) * pageSize
		const lastPageIndex = firstPageIndex - -pageSize
		return filteredData.slice(firstPageIndex, lastPageIndex)
	}, [currentPage, pageSize, filteredData])

	return (
		<div className="list-list" style={{ marginTop: 0, minWidth: '80vw' }}>
			<div className="list-actions">
				<div
					className="action-button"
					onClick={() => {
						fetchRegisters()
					}}
				>
					Sincronizza
				</div>
				<div
					className="action-button"
					onClick={() => {
						addModal({
							modal: <RegisterForm mode="new" />,
							id: 'RegisterRegisterModal',
							background: true,
							replaceModal: true,
							customModal: true,
							disposableModal: true,
						})
					}}
				>
					Nuovo
				</div>
			</div>
			<Filters data={registers} attributes={attributes} setAttributes={setAttributes} searchString={searchString} setSearchString={setSearchString} />
			<Pagination
				// className="pagination-bar"
				currentPage={currentPage}
				totalCount={(filteredData && filteredData.length) || 1}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPageChange={(page) => setCurrentPage(page)}
			/>

			<div className="list-row row-header">
				<div className="list-column item-medium">Email</div>
				<div className="list-column item-medium">{`Nome Cognome Azienda`}</div>
				<div className="list-column item-small">Collega</div>
				<div className="list-column item-small">Modifica</div>
				{((user && user.authLevel >= 750) || (user && user.userGroup && user.userGroup.authLevel >= 500)) && (
					<div className="list-column item-small">
						<div
							style={{ cursor: 'pointer', marginBottom: '6px' }}
							onClick={() => {
								const r = window.confirm(
									`are you sure you want to delete ${
										Object.keys(selectedList || {}).length === 1 ? '1 anagrafica' : Object.keys(selectedList || {}).length + ' anagrafiche'
									}?`
								)

								if (r) {
									if (typeof deleteManyRegisters === 'function') {
										deleteManyRegisters(Object.keys(selectedList || {})).then((res) => setSelectedList({}))
									}
								}
							}}
						>
							Elimina ({Object.keys(selectedList || {}).length})
						</div>
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setSelectedList({})
							}}
						>
							Deseleziona
						</div>
					</div>
				)}
			</div>
			{Array.isArray(pageData) &&
				pageData.map((register, i) => {
					return (
						<div key={register._id} className="list-row">
							<div className="list-column item-medium">{register.email}</div>
							<div className="list-column item-medium">{`${register.firstname} ${register.lastname} ${
								register.company ? `- ${register.company}` : ''
							}`}</div>
							<div
								className="list-column item-small item-clickable"
								onClick={() => {
									setRegister(register)
								}}
							>
								Collega
							</div>
							<div
								className="list-column item-small item-clickable"
								onClick={() => {
									addModal({
										modal: <RegisterForm registerData={register} mode="edit" />,
										id: 'EditRegisterModal',
										background: true,
										replaceModal: true,
										customModal: true,
										disposableModal: true,
									})
								}}
							>
								Modifica
							</div>
							{((user && user.authLevel >= 750) || (user && user.userGroup && user.userGroup.authLevel >= 500)) && (
								<div
									className="list-column item-small hoverable item-clickable"
									style={{ userSelect: 'none' }}
									onClick={(e) => {
										// e.preventDefault()
										if (selectedList[register._id]) {
											delete selectedList[register._id]
										} else {
											selectedList[register._id] = true
										}
										setSelectedList({ ...selectedList })
									}}
								>
									<input type="checkbox" checked={selectedList[register._id] || false} onChange={() => {}} />
								</div>
							)}
						</div>
					)
				})}
		</div>
	)
}
