import React from 'react'
import { renderToString } from 'react-dom/server'
import json2md from 'json2md'

import CustomProduct from '../../../../components/elements/CustomProducts'

import configurationStore from '../../configurations'
import userStore from '../../user'
import { download } from './utils'

export default async function exportMarkdown(get, set) {
	const document = get().document
	const configurations = configurationStore.getState().configurations
	const getUserGroup = userStore.getState().getUserGroup
	const getUserGroupId = userStore.getState().getUserGroupId
	const user = userStore.getState().user
	const exportCod = configurations.exportCod
	const exportIva = configurations.exportIva
	const exportRemovePrice = configurations.exportRemovePrice
	const exportDiscountPrices = configurations.exportDiscountPrices

	let userGroup = await getUserGroup()
	let userGroupDownload = await getUserGroupId(document && document.userGroupId)

	userGroup = userGroup && userGroup.data

	let content = []

	let date
	if (document.quoteDate) {
		date = new Date(document.quoteDate)
	} else if (document.updatedAt) {
		date = new Date(document.updatedAt)
	} else {
		date = new Date(Date.now())
	}

	content.push({ date: `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}` })

	if (document.referer) content.push({ referer: document.referer })

	if (document.id) {
		let userSlug = user.slug
		let id = document.id
		let userGroupId = userGroupDownload && userGroupDownload.id

		if (userGroupDownload instanceof Error) {
			userGroupId = userGroup && userGroup.id
		}

		id = id.replace(/^0+/, '')

		content.push({
			referer: `id preventivo: ${userSlug}${userGroupId ? '-' + userGroupId : ''}-${id}${document.versionNumber ? '_' + document.versionNumber : ''}`,
		})
	}

	if (Array.isArray(document.elements)) {
		document.elements.forEach((element) => {
			switch (element.type) {
				case 'title':
					if (element.title && element.title !== '') {
						content.push({ h2: element.title })
					}
					break
				case 'subtitle':
					if (element.subtitle && element.subtitle !== '') {
						content.push({ h3: element.subtitle })
					}
					break
				case 'description':
					if (element.description && element.description !== '') {
						content.push({ p: element.description })
					}
					break
				case 'separator':
					content.push({ hr: '' })
					break
				case 'shipping':
					if (element.shipping) {
						content.push({ h3: 'Spedizione' })
						content.push({ p: `${element.shipping.name} - ${element.shipping.price}€\n${element.shipping.notes}` })
					}

					break
				case 'zone':
					if (element.title && element.title !== '') {
						content.push({ h3: element.title })
					}

					// let tableHeaders = ['Qtà', 'Prodotto', 'Lung.', 'Info', 'Prezzo cad.', 'Sconto', 'Prezzo netto', 'Prezzo tot.']
					let tableHeaders = ['Qtà', 'Prodotto', 'Lung.', 'Info', 'Prezzo cad.', 'Sconto', 'Prezzo tot.']

					let tableRows = []

					// Check if length is set in at least 1 item, if there is none, remove length from headers
					let length =
						Array.isArray(element.items) && element.items.some((item) => item.showLength || (item.product && item.product.category === 'profiles'))
					if (!length) {
						tableHeaders = tableHeaders.filter((header) => header !== 'Lung.')
					}

					// Check if info is set in at least 1 item, if there is none, remove info from headers
					let infoPresent = Array.isArray(element.items) && element.items.some((item) => item.info)
					if (!infoPresent) {
						tableHeaders = tableHeaders.filter((header) => header !== 'Info')
					}

					// remove price Headers
					if (exportRemovePrice) {
						// tableHeaders = tableHeaders.filter(
						// 	(header) => header !== 'Prezzo cad.' && header !== 'Prezzo tot.' && header !== 'Sconto' && header !== 'Prezzo netto'
						// )
						tableHeaders = tableHeaders.filter((header) => header !== 'Prezzo cad.' && header !== 'Prezzo tot.' && header !== 'Sconto')
					}

					if (!exportDiscountPrices) {
						// tableHeaders = tableHeaders.filter((header) => header !== 'Sconto' && header !== 'Prezzo netto')
						tableHeaders = tableHeaders.filter((header) => header !== 'Sconto')
					}

					let total = 0
					if (Array.isArray(element.items)) {
						element.items.forEach((item) => {
							// if item is empty (no name and info) ignore
							if (!item.name && !item.info && item.type !== 'customProduct') return

							// calculate total price
							if (!isNaN(item.total)) {
								total -= -item.total
							}

							let rowComponents = []

							switch (item.type) {
								case 'customProduct':
									rowComponents.push(item.quantity)

									rowComponents.push(renderToString(<CustomProduct itemInfo={item.productInfo} />))
									if (length) {
										rowComponents.push('')
									}
									if (infoPresent) {
										rowComponents.push('')
									}
									break
								case 'descriptiveItem':
									rowComponents.push(item.quantity)

									rowComponents.push(item.name)
									if (length) {
										rowComponents.push('')
									}
									if (infoPresent) {
										rowComponents.push('')
									}
									break
								default:
									rowComponents.push(item.quantity)

									if (exportCod && item.product) {
										rowComponents.push(`${item.product.cod || ''} ~ ${item.name}`)
									} else {
										rowComponents.push(item.name)
									}
									if (length) {
										if (item.product && item.product.category === 'profiles') {
											// rowComponents.push(item.length > 6 ? `${item.length}cm` : `${item.length * 100}cm`)
											rowComponents.push(
												item.length +
													(/[a-z]+/gimu.test(item.length) ? '' : item.length > 6 ? (item.length >= 1000 ? 'mm' : 'cm') : 'm')
											)
										} else {
											rowComponents.push('')
										}
									}
									if (infoPresent) {
										rowComponents.push(item.info)
									}
							}

							if (!exportRemovePrice) {
								if (exportDiscountPrices) {
									rowComponents.push((!isNaN(parseFloat(item.price)) && parseFloat(item.price).toFixed(2)) || '0.00')
									rowComponents.push(item.discount ? item.discount : '0')
									// rowComponents.push((!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00')
									rowComponents.push((!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00')
								} else {
									rowComponents.push((!isNaN(parseFloat(item.priceCad)) && parseFloat(item.priceCad).toFixed(2)) || '0.00')
									rowComponents.push((!isNaN(parseFloat(item.total)) && parseFloat(item.total).toFixed(2)) || '0.00')
								}
							}

							tableRows.push(rowComponents)
						})
					}
					if (!exportRemovePrice) {
						let tableTot = (!isNaN(parseFloat(total)) && parseFloat(total).toFixed(2)) || '0.00'
						let tableTotIva = (!isNaN(parseFloat(total)) && (parseFloat(total) * 1.22).toFixed(2)) || '0.00'

						// con prezzo netto
						// let totalPriceArr = ['', '', '', '', '', '', 'Totale IVA esc.:', tableTot]
						// let totalPriceIvaArr = ['', '', '', '', '', '', 'Totale IVA inc:', tableTotIva]

						let totalPriceArr = ['', '', '', '', '', 'Totale IVA esc.:', tableTot]
						let totalPriceIvaArr = ['', '', '', '', '', 'Totale IVA inc:', tableTotIva]
						if (!length) {
							totalPriceArr.shift()
							totalPriceIvaArr.shift()
						}

						if (!infoPresent) {
							totalPriceArr.shift()
							totalPriceIvaArr.shift()
						}

						// remove price Headers
						if (exportRemovePrice) {
							// totalPriceArr.shift()
							totalPriceArr.shift()
							totalPriceArr.pop()
							totalPriceArr.pop()
							// totalPriceIvaArr.shift()
							totalPriceIvaArr.shift()
							totalPriceIvaArr.pop()
							totalPriceIvaArr.pop()
						} else if (!exportDiscountPrices) {
							totalPriceArr.shift()
							// totalPriceArr.shift()
							// totalPriceIvaArr.shift()
							totalPriceIvaArr.shift()
						}

						tableRows.push(totalPriceArr)
						if (exportIva) {
							tableRows.push(totalPriceIvaArr)
						}

						// tableRows.push(['', '', ...(length ? [''] : []), ...(infoPresent ? [''] : []), 'Totale IVA esc.:', tableTot])
						// if (exportIva) {
						// 	tableRows.push(['', '', ...(length ? [''] : []), ...(infoPresent ? [''] : []), 'Totale IVA inc:', tableTotIva])
						// }
					}

					content.push({
						table: { headers: tableHeaders, rows: tableRows },
					})

					break
				default:
			}
		})
	}

	if (userGroup && userGroup.disclaimer) content.push({ p: userGroup.disclaimer })

	// console.log(content)

	json2md.converters.referer = function (input, json2md) {
		return `<div style="text-align: right ">riferimento: ${input}</div>`
	}
	json2md.converters.date = function (input, json2md) {
		return `<div style="text-align: right ">${input}</div>`
	}

	let mdQuoteString = json2md(content, '')

	// const mdStyles = Configs.exportStyles
	let mdStyles =
		'.markdown-body table{width:100%;display:table}.markdown-body table td,.markdown-body table th{text-align:left}.markdown-body table th:first-child{width:60px}.markdown-body table th:nth-last-child(2)..markdown-body table th:nth-last-child(1){width:140px}.markdown-body table tr:last-child>td:last-child{background-color:#d5f0ff}.markdown-body table tr:last-child>td:nth-last-child(2){font-weight:700}'

	if (exportRemovePrice)
		mdStyles = `.markdown-body table{width:100%;display:table}.markdown-body table td,.markdown-body table th{text-align:left}.markdown-body table th:first-child{width:60px}`

	const now = new Date()
	const dd = String(now.getDate()).padStart(2, '0')
	const mn = String(now.getMonth() + 1).padStart(2, '0')
	const yyyy = now.getFullYear()
	const hh = String(now.getHours()).padStart(2, '0')
	const mm = String(now.getMinutes() + 1).padStart(2, '0')

	const today = yyyy + mn + dd + '_' + hh + mm
	const fileName = 'Preventivo_' + today + '.md'

	download(mdQuoteString + '\n\n<style>' + mdStyles + '</style>', fileName, 'text/markdown')
}
