import create from 'zustand'
import { sha256 } from 'js-sha256'
import { persist } from 'zustand/middleware'
import axios from 'axios'

import useDocumentStore from './document'
import useQuotesStore from './quotes'
import useProductsStore from './products'
import useRegistersStore from './registers'
import useConfigurationStore from './configurations'

let serverUrl = process.env.REACT_APP_SERVER_URL

const initialUserStore = {
	user: {},
	// cachedUserEmail: '',
	token: '',
	quotes: [],
}

const getUserInfoFromToken = (set, get) => {
	const token = get().token
	if (!token) {
		return null
	}

	return axios
		.get(`${serverUrl}/api/login/token`, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		.then((res) => {
			if (res.data) {
				set({ user: res.data.user, token: res.data.token })
				return true
			}

			return true
		})
		.catch((e) => {
			console.error(e)
			set({ ...initialUserStore })
			return false
		})
}

// Store for user User, possible TODO: load user info from server??
const useUserStore = create(
	persist(
		(set, get) => ({
			...initialUserStore,
			clearUser: () => set({ ...initialUserStore }),
			checkLogin: async () => {
				return getUserInfoFromToken(set, get)
			},
			logout: () => {
				set({ ...initialUserStore })
			},
			refreshToken: () => {
				const token = get().token

				return axios
					.post(
						`${serverUrl}/api/login/refresh/token`,
						{},
						{
							headers: {
								authorization: token,
							},
						}
					)
					.then((res) => {
						set({ token: res.data })
					})
					.catch((e) => {
						console.error(e)
						return false
					})
			},
			login: (email, password) => {
				const store = get()
				if (!email || !password) {
					// throw new Error()
					return Promise.reject(new Error('Inserire Utente e Password'))
				}

				return axios
					.post(`${serverUrl}/api/login`, { email: email, password: password })
					.then((res) => {
						// console.log(res)

						if (res.data) {
							const hashEmail = sha256(email)
							const cachedUser = store.cachedUserEmail

							if (hashEmail === cachedUser) {
								set({ user: res.data.user, token: res.data.token })
							} else {
								const documentStore = useDocumentStore.getState()
								const quotesStore = useQuotesStore.getState()
								const productsStore = useProductsStore.getState()
								const registersStore = useRegistersStore.getState()
								const configurationStore = useConfigurationStore.getState()

								documentStore.clearStore()
								quotesStore.clearStore()
								productsStore.clearStore()
								registersStore.clearStore()
								configurationStore.clearStore()
								set({ user: res.data.user, token: res.data.token, cachedUserEmail: hashEmail })
							}

							return true
						}
					})
					.catch((error) => {
						console.error(error)
						throw error
					})
			},
			getUserGroup: () => {
				const token = get().token

				return axios
					.get(`${serverUrl}/api/userGroups/user`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						set({ userGroup: res.data })
						return res
						// return true
					})
					.catch((e) => {
						console.error(e)
						return false
					})
			},
			getUserGroupId: (id) => {
				const token = get().token

				// console.log(id)

				if (!id) {
					return new Promise((resolve, reject) => {
						reject(new Error('No id'))
					})
					// return async () => Promise.reject(new Error('No id'))
				}

				return axios
					.get(`${serverUrl}/api/userGroups/${id}`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						// set({ userGroup: res.data })
						// console.log(id, res.data)
						return res.data
						// return true
					})
					.catch((e) => {
						console.error(e)
						return e
					})
			},
			uploadDisclaimer: (disclaimer) => {
				const token = get().token

				axios
					.post(
						`${serverUrl}/api/userGroups/disclaimer/`,
						{
							disclaimer: disclaimer,
						},
						{
							headers: {
								authorization: token,
							},
						}
					)
					.then((res) => {
						// console.log(res)
						// set({ userGroup: res.data })
						// return res
						return true
					})
					.catch((e) => {
						console.error(e)
						return false
					})
			},
			getUserInfoFromToken: () => getUserInfoFromToken(set, get),
			downloadQuotes: () => {
				const token = get().token

				axios
					.get(`${serverUrl}/api/quotes/user`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						// console.log(res)
						return true
					})
					.catch((e) => {
						console.error(e)
						return false
					})
			},
			uploadQuote: (name) => {
				const token = get().token
				const store = useDocumentStore.getState()
				const quote = JSON.stringify(store.exportDocument())
				axios
					.post(
						`${serverUrl}/api/quotes/user`,
						{ quote: quote, name: 'test' },
						{
							headers: {
								authorization: token,
							},
						}
					)
					.then((res) => {
						// console.log(res)
					})
					.catch((e) => {
						console.error(e)
						return false
					})
			},
			// setUser: (user) => set({ user: user }),
		}),
		{
			name: 'user-storage', // unique name
			// storage: typeof window !== 'undefined' ? window.localStorage : dummyStorageApi,
		}
	)
)

export default useUserStore
