import { Routes, Route } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'
import Document from './Document'
import UserList from './user/UserList'
import QuotesList from './graphic/QuotesList'
import AdminQuotesList from './graphic/AdminQuotesList'
import Configurator from './extra/Configurator'
import ConfigurationPage from './configurationPage'
import FilesDownload from './graphic/FilesDownload'
import Analytics from './pages/Analytics'

export default function Layout() {
	return (
		<div className="layout">
			<Header />
			<div className="container">
				<Routes>
					<Route
						path="/"
						element={
							<>
								<main className="content">
									<Document />
									<Footer />
								</main>
								<Sidebar />
							</>
						}
					/>
					<Route path="admin/users" element={<UserList />} />
					<Route path="admin/quotes" element={<AdminQuotesList />} />
					<Route path="admin/analytics" element={<Analytics />} />
					<Route path="preventivi" element={<QuotesList />} />
					<Route path="config" element={<ConfigurationPage />} />
					<Route
						path="testConfigurator"
						element={
							<div style={{ width: '90vw', height: '90vh' }}>
								<Configurator />
							</div>
						}
					/>
					<Route path="test" element={<FilesDownload />} />
				</Routes>
			</div>
		</div>
	)
}
