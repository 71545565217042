import useConfigurationStore from './stores/configurations'
import useUserStore from './stores/user'

export default function getAttribute(product, attribute) {
	if (!product) {
		return product
	}

	const userStore = useUserStore.getState()
	const configurationStore = useConfigurationStore.getState()

	// check if user HAS attributeNames
	let savedAttribute
	if (userStore.user && userStore.user.attributeNames && userStore.user.attributeNames[0]) {
		savedAttribute = configurationStore.configurations && configurationStore.configurations.attributeNames

		// if there was not saved attribute, get user's first attribute
		if (!savedAttribute) {
			savedAttribute = userStore.user.attributeNames[0]

			// check if saved configuration is present in user (if user changes saved attribute may not be available for new user)
		} else if (!userStore.user.attributeNames.find((name) => name === savedAttribute)) {
			savedAttribute = userStore.user.attributeNames[0]
			const configurations = configurationStore.configurations || {}
			configurations.attributeNames = savedAttribute
			useConfigurationStore.setState({ configurations: configurations })
		}
	}

	let defaultReturn
	switch (attribute) {
		case 'name':
			// if (changableAttribute) {
			// 	return product.name
			// }
			defaultReturn = product.name
			switch (savedAttribute) {
				case 'led_pro':
					return product.name || defaultReturn
				case 'aeoss':
					return product.name_aeoss || defaultReturn
				default:
					return defaultReturn
			}
		case 'line':
			// if (changableAttribute) {
			// 	return product.savedAttribute && product.savedAttribute.line_ledpro && product.savedAttribute.line_ledpro.value
			// }
			defaultReturn = product.line_ledpro
			switch (savedAttribute) {
				case 'led_pro':
					return product.line_ledpro || defaultReturn
				case 'aeoss':
					return product.line_aeoss || defaultReturn
				default:
					return defaultReturn
			}
		default:
			return product[attribute]
	}
}
