export default function DragElement({ provided }) {
	return (
		<div className="element-action element-drag" {...provided.dragHandleProps} tabIndex="-1">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="1.5rem"
				height="1.5rem"
				// style={{ transform: 'scaleX(3)' }}
			>
				{/* <path fill="none" d="M0 0h24v24H0z" />
				<path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" /> */}
				<path fill="none" d="M0 0h24v24H0z" />
				<path d="M12 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
			</svg>
		</div>
	)
}
