import useSessionStore from './stores/session'

export default function checkTokenError(e) {
	let message

	const sessionStore = useSessionStore.getState()
	const setLoggedIn = sessionStore && sessionStore.setLoggedIn

	if (e && e.response && e.response.data && e.response.data.message) {
		if (
			e.response.data.message === 'No token was sent' ||
			/jwt/gimu.test(e.response.data.message) ||
			e.response.data.message === 'invalid token' ||
			e.response.data.message === 'invalid signature'
		) {
			if (typeof setLoggedIn === 'function') setLoggedIn(false)
			message = 'Sessione scaduta, rifare il login'
		}
	}
	return message
}
