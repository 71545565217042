import { useMemo, useState } from 'react'

export default function Profile({ profiles, stepSelect = () => {}, selected, collapsed, setCollapsed = () => {}, attributeInfo }) {
	const [selectedWidths, setSelectedWidths] = useState({ top: '0px', left: '0px' })
	let [filterString, setFilterString] = useState('')

	let data = useMemo(() => {
		if (Array.isArray(profiles)) {
			return profiles.filter((profile) => {
				let profileName = ''
				// let profileDescription = ''
				switch (attributeInfo) {
					case 'led_pro':
						if (profile) {
							profileName = typeof profile.name_ledpro === 'string' ? profile.name_ledpro : profile.name
							// profileDescription = typeof profile.description_ledpro === 'string' ? profile.description_ledpro : profile.description
						}
						break
					case 'aeoss':
						if (profile) {
							profileName = profile.name
							// profileDescription = profile.description
						}
						break
					default:
						if (profile) {
							profileName = profile.name
							// profileDescription = profile.description
						}
				}

				let returnValue = false

				if (typeof profileName === 'string' && profileName !== '') {
					returnValue = profileName.toLowerCase().includes(filterString.toLowerCase()) ? true : false
				}
				if (!returnValue && typeof profile.cod === 'string') {
					returnValue = profile.cod.toLowerCase().includes(filterString.toLowerCase()) ? true : false
				}

				return returnValue
			})
		} else {
			return []
		}
	}, [filterString, profiles, attributeInfo])

	return (
		<>
			<div className="configurator-input-box">
				<input
					type="search"
					className="configurator-input"
					value={filterString}
					id={`configuratorProfileFilter`}
					onInput={(e) => {
						setFilterString(e.target.value)
					}}
				/>
				<label htmlFor={`configuratorProfileFilter`} className="configurator-input-label">
					Filtro
				</label>
			</div>
			<div className="step-profile-list">
				{Array.isArray(data) &&
					data.map((profile, i) => {
						let cssClass = ''
						if (selected) {
							if (selected.slug) {
								if (selected.slug === profile.slug) {
									cssClass = 'selected'
								}
							} else {
								if (selected.cod === profile.cod) {
									cssClass = 'selected'
								}
							}
						}

						if (!cssClass) {
							if (collapsed) {
								cssClass = 'collapsed'
							} else {
								cssClass += 'not-collapsed'
							}
						} else {
							cssClass += ' not-collapsed'
						}

						let profileName = ''
						let profileDescription = ''
						switch (attributeInfo) {
							case 'led_pro':
								if (profile) {
									profileName = typeof profile.name_ledpro === 'string' ? profile.name_ledpro : profile.name
									profileDescription = typeof profile.description_ledpro === 'string' ? profile.description_ledpro : profile.description
								}
								break
							case 'aeoss':
								if (profile) {
									profileName = profile.name
									profileDescription = profile.description
								}
								break
							default:
								if (profile) {
									profileName = profile.name
									profileDescription = profile.description
								}
						}

						// console.log(profile)
						return (
							<div
								key={i}
								className={`step-profile-option ${cssClass}`}
								style={{
									'--animation-delay': 100 + 50 * i + 'ms',
									...(cssClass.includes('selected') && collapsed ? { '--top': '0px', '--left': selectedWidths.left } : {}),
								}}
								onClick={(e) => {
									const parentDiv = e.target.closest('.step-profile-option')
									// let left = parentDiv.offsetLeft - (parentDiv.offsetLeft % parentDiv.offsetWidth)
									let left = parentDiv.offsetLeft - 10

									setSelectedWidths({
										top: `${parentDiv.offsetTop * -1}px`,
										left: `${left * -1}px`,
									})

									// setCollapsed(true)
									stepSelect(profile, profile.name)
								}}
							>
								<div className="profile-option-image">
									<img src={`./images/profiles/${profile.image}`} alt={profile.imageAlt} />
								</div>
								<div className="profile-option-details">
									<h2 className="profile-details-title">{profileName}</h2>
									<div className="profile-details-description">{profileDescription}</div>
									{/* <ul>
								<li>taglio: {(profile.cut / 10).toFixed(2)}cm</li>
								<li>
									watt/m: {profile.power_m} lumen/m: {profile.lumen_m}
								</li>
								<li>taglio: {profile.cut}</li>
							</ul> */}
								</div>
							</div>
						)
					})}
			</div>
		</>
	)
}
