import create from 'zustand'
import { persist } from 'zustand/middleware'
import axios from 'axios'

import IDBStorage from '../IDBStorage'
import useUserStore from './user'

let serverUrl = process.env.REACT_APP_SERVER_URL

//TODO: Sync local document with db one
const initialUploadsStore = {
	uploads: {},
}

// Store for user Document, possible TODO: load document info from server??
const useUploadsStore = create((set, get) => ({
	...initialUploadsStore,
	clearStore: () => set({ ...initialUploadsStore }),
	setUploads: (uploads) => {
		set({ uploads: uploads })
	},
	setUploadData: (id, data) => {
		let newUploads = { ...get().uploads }
		newUploads[id] = data
		set({ uploads: newUploads })
	},
}))

const initialPersistUploadsStore = {
	uploadsArray: [],
	lastUpload: 0,
}

export const usePersistUploadsStore = create(
	persist(
		(set, get) => ({
			...initialPersistUploadsStore,
			clearStore: () => set({ ...initialPersistUploadsStore }),
			fetchUploadsArray: () => {
				const token = useUserStore.getState().token
				const lastUpload = new Date(get().lastUpload)

				console.log(
					lastUpload.getTime(),
					new Date(Date.now()).getTime(),
					new Date(Date.now()).getTime() - lastUpload.getTime(),
					new Date(Date.now()).getTime() - lastUpload.getTime() >= 1000 * 60 * 5,
					get().isDownloading
				)

				// fetch at most each five minutes
				// And avoid sending multiple fetch requests using isDownloading
				if (new Date(Date.now()).getTime() - lastUpload.getTime() >= 1000 * 60 * 5 && !get().isDownloading) {
					console.log('downloading uploads')
					set({ isDownloading: true })
					axios
						.get(`${serverUrl}/api/uploads`, { headers: { authorization: token } })
						.then((res) => {
							if (Array.isArray(res.data)) {
								set({ uploadsArray: res.data, lastUpload: new Date(Date.now()), isDownloading: false })
							}
						})
						.catch((e) => {
							console.error(e)
							set({ isDownloading: false })
						})
				}
			},
			setUploadsArray: (uploadsArray) => set({ uploadsArray: uploadsArray }),

			_hasHydrated: false,
		}),
		{
			name: 'uploads-persist-storage',
			getStorage: () => IDBStorage,
			onRehydrateStorage: () => () => {
				usePersistUploadsStore.setState({ _hasHydrated: true })
			},
		}
	)
)

export default useUploadsStore
