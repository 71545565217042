export default function Option({ options, stepSelect = () => {}, selected, collapsed, setCollapsed = () => {} }) {
	return (
		<div className="step-option-list">
			{Array.isArray(options) &&
				options.map((option, i) => {
					let cssClass = ''
					if (selected) {
						if (selected.slug) {
							if (selected.slug === option.slug) {
								cssClass = 'selected'
							}
						} else {
							if (selected.cod === option.cod) {
								cssClass = 'selected'
							}
						}
					}

					if (collapsed) {
						cssClass += ' collapsed'
					} else {
						cssClass += ' not-collapsed'
					}

					return (
						<div
							key={i}
							className={`step-option-option ${cssClass} ${option.image ? '' : 'no-image'}`}
							style={{
								'--animation-delay': 100 + 50 * i + 'ms',
							}}
							onClick={(e) => {
								// setCollapsed(true)
								stepSelect(option, option.name)
							}}
						>
							{option.image && (
								<div className="option-option-image">
									<img src={`./images/${option.image}`} alt={option.imageAlt} />
								</div>
							)}
							<div className="option-option-details">
								<h2 className="option-details-title">{option.name}</h2>
								<div className="option-details-description">{option.description}</div>
								{/* <ul>
								<li>taglio: {(option.cut / 10).toFixed(2)}cm</li>
								<li>
									watt/m: {option.power_m} lumen/m: {option.lumen_m}
								</li>
								<li>taglio: {option.cut}</li>
							</ul> */}
							</div>
						</div>
					)
				})}
		</div>
	)
}
