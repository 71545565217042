import create from 'zustand'
import { v4 as uuidv4 } from 'uuid'
// uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

const initialModalStore = {
	modals: [],
}

// Store for user Modal
const useModalStore = create((set, get) => ({
	...initialModalStore,
	clearModal: () => set({ ...initialModalStore }),
	removeModal: (id) => {
		const modals = Array.from(get().modals)
		const index = modals.findIndex((modal) => modal.id === id)

		if (index !== -1) {
			if (typeof modals[index].onClose === 'function') {
				modals[index].onClose()
			}
			modals.splice(index, 1)
			const providerVisible = modals.some((modal) => modal.visible)
			set({ modals: modals, visible: providerVisible })
		}
	},
	toggleModalVisibility: (id, visible) => {
		const modals = Array.from(get().modals)
		const index = modals.findIndex((modal) => modal.id === id)

		if (index !== -1) {
			if (visible !== 'undefined') {
				modals[index].visible = visible
			} else {
				modals[index].visible = !modals[index].visible
			}

			const providerVisible = modals.some((modal) => modal.visible)
			set({ modals: modals, visible: providerVisible })
		}
	},
	addModal: ({ modal, id = null, background = false, timeout = null, replaceModal = true, customModal = false, disposableModal = false, onClose }) => {
		const modals = Array.from(get().modals)

		const modalItem = {
			id: uuidv4(),
			modal: modal,
			background: background,
			timeout: timeout,
			visible: true,
			customModal: customModal,
			disposableModal: disposableModal,
			onClose: onClose,
		}
		if (id) {
			modalItem.id = id
		}

		const index = modals.findIndex((modal) => modal.id === modalItem.id)
		if (index !== -1) {
			if (replaceModal) {
				modals[index] = modalItem
			} else {
				modals[index].visible = !modals[index].visible
			}
		} else {
			modals.push(modalItem)
		}

		set({ modals: modals, visible: true })
	},
}))

export default useModalStore
