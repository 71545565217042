/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserShield, faUsers, faTags, faAt, faHouse } from '@fortawesome/free-solid-svg-icons'

import FormEntry from '../graphic/FormEntry'

import { registerWebsiteUser, editWebsiteUser, getUserGroups, getPriceLists } from '../../lib/adminFunctions'

import '../../styles/components/Form.scss'

export default function WebsiteUserForm({ userData = {}, mode = 'register' }) {
	const [user, setUser] = useState(cloneDeep(userData))

	const [allUserGroups, setAllUserGroups] = useState()
	const [allPriceLists, setAllPriceLists] = useState()

	useEffect(() => {
		getUserGroups().then((res) => {
			setAllUserGroups(res)
		})
		getPriceLists().then((res) => {
			setAllPriceLists(res)
		})
	}, [])

	function setUserAttribute(key, value) {
		const userCopy = { ...user }
		userCopy[key] = value
		setUser(userCopy)
	}

	function modeToLabel(mode) {
		switch (mode) {
			case 'edit':
				return 'Modifica'
			case 'register':
				return 'Crea'
			default:
				return mode
		}
	}

	const required = {}
	switch (mode) {
		case 'register':
			required.firstname = true
			required.lastname = true
			required.email = true
			break
		case 'edit':
			required.firstname = true
			required.lastname = true
			required.email = true
			break
		default:
	}

	// console.log(allNewPriceLists)

	return (
		<div className="form-page">
			<form
				className="form-form"
				onSubmit={(e) => {
					e.preventDefault()
					// console.log(user)

					let message = ''

					// if (!user.userGroup) {
					// 	user.userGroup = null
					// } else {
					// 	if (!user.userGroup.id || user.userGroup.id === 'noSelect') {
					// 		user.userGroup = null
					// 	} else if (!user.userGroup.authLevel || user.userGroup.authLevel === 'noSelect') {
					// 		user.userGroup.authLevel = 0
					// 	}
					// }

					if (message !== '') {
						alert(message)
						return
					}

					let toastId
					switch (mode) {
						case 'register':
							toastId = toast.loading('Salvando utente...')

							registerWebsiteUser(user)
								.then((res) => {
									// console.log(res)

									toast.update(toastId, {
										render: 'Utente salvato!',
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)

									toast.update(toastId, {
										render: `Errore nel salvare l'utente`,
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
							break
						case 'edit':
							toastId = toast.loading(`Salvando modifiche dell'utente...`)

							editWebsiteUser(user)
								.then((res) => {
									// console.log(res)

									toast.update(toastId, {
										render: `Modifiche all'utente apportate!`,
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)

									toast.update(toastId, {
										render: `Errore nell'apportare le modifiche dell'utente`,
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
							break
						default:
					}
				}}
			>
				<FormEntry
					id={`userFirstname${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUser} />
						</div>
					}
					label="Firstname"
					required={required.firstname}
					value={user.firstname || ''}
					onChange={(e) => setUserAttribute('firstname', e.target.value)}
				/>
				<FormEntry
					id={`userLastname${user._id ? user._id : ''}`}
					icon={
						// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						// 	<path fill="none" d="M0 0h24v24H0z" />
						// 	<path d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
						// </svg>
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUser} />
						</div>
					}
					label="Lastname"
					required={required.lastname}
					value={user.lastname || ''}
					onChange={(e) => setUserAttribute('lastname', e.target.value)}
				/>
				<FormEntry
					id={`userSlug${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUser} />
						</div>
					}
					label="Slug"
					required={required.slug}
					value={user.slug || ''}
					onChange={(e) => setUserAttribute('slug', e.target.value)}
				/>
				<FormEntry
					id={`userEmail${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faAt} />
						</div>
					}
					label="Email"
					required={required.email}
					value={user.email || ''}
					onChange={(e) => setUserAttribute('email', e.target.value)}
				/>
				{/* <FormEntry
					id={`userPassword${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faKey} />
						</div>
					}
					label="Password"
					type="password"
					required={required.password}
					value={user.password || ''}
					pattern={`^[0-9A-Za-z\d@$!%*#?&_\.]{5,}$`}
					title={'Minimun 5 characters, Use only latters, numbers, and symbols @ $ ! % * # ? & _ .'}
					onChange={(e) => setUserAttribute('password', e.target.value)}
				/> */}

				{mode === 'edit' && (
					<>
						<div className="form-entry">
							<label htmlFor={`userUserGroups${user._id ? user._id : ''}`} className="form-label">
								{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z" />
						</svg> */}
								<div style={{ fontSize: '24px', height: '24px' }}>
									<FontAwesomeIcon icon={faUsers} />
								</div>
								<div className="label">UserGroup</div>
							</label>
							<select
								className="form-input"
								id={`userUserGroups${user._id ? user._id : ''}`}
								name="UserGroups"
								value={(user.userGroup && user.userGroup.id) || ''}
								onChange={(e) => {
									const userGroup = user.userGroup || {}
									userGroup.id = e.target.value
									setUserAttribute('userGroup', userGroup)
								}}
							>
								<option value="noSelection">Select One...</option>
								{Array.isArray(allUserGroups) &&
									allUserGroups.map((group) => {
										return (
											<option key={group._id} value={group._id}>
												{group.code}
											</option>
										)
									})}
							</select>
						</div>
						<div className="form-entry">
							<label htmlFor={`userUserGroupAuthLevel${user._id ? user._id : ''}`} className="form-label">
								<div style={{ fontSize: '24px', height: '24px' }}>
									<FontAwesomeIcon icon={faUserShield} />
								</div>
								<div className="label">UserGroupAuthLevel</div>
							</label>
							<select
								className="form-input"
								id={`userUserGroupAuthLevel${user._id ? user._id : ''}`}
								name="UserGroupAuthLevel"
								value={
									(user.userGroup && typeof user.userGroup.authLevel !== 'object' && typeof user.userGroup.authLevel !== 'undefined'
										? user.userGroup.authLevel
										: 'noSelect') || 'noSelect'
								}
								onChange={(e) => {
									// if (e.target.value !== '' && e.target.value !== 'noSelect') {
									const userGroup = user.userGroup || {}
									userGroup.authLevel = e.target.value
									setUserAttribute('userGroup', userGroup)
									// }
								}}
							>
								<option value="noSelect">Select one...</option>
								<option value={0}>Ospite</option>
								<option value={250}>Utente</option>
								<option value={750}>Gestore</option>
								<option value={1000}>Admin</option>
							</select>
						</div>
					</>
				)}
				<div className="form-entry">
					<label htmlFor={`userPriceLists${user._id ? user._id : ''}`} className="form-label">
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faTags} />
						</div>
						<div className="label">Price Lists</div>
					</label>
					<div className="form-array">
						{Array.isArray(user.priceLists) &&
							user.priceLists.map((priceList, i) => {
								return (
									<div
										key={i}
										className="form-array-item"
										onClick={() => {
											const priceLists = user.priceLists
											priceLists.splice(i, 1)
											setUserAttribute('priceLists', priceLists)
										}}
									>
										{priceList}
									</div>
								)
							})}
						<select
							className="form-input"
							style={{ minHeight: '150px' }}
							id={`userPriceLists${user._id ? user._id : ''}`}
							name="PriceLists"
							value={['noSelect']}
							multiple
							onChange={(e) => {
								e.preventDefault()
								// console.log(e)
								if (e.target.value !== 'noSelect' && e.target.value !== '') {
									const priceLists = user.priceLists || []
									priceLists.push(e.target.value)
									setUserAttribute('priceLists', priceLists)
								}
							}}
						>
							<option value="noSelect">Select any...</option>
							{/* {Array.isArray(allPriceLists) &&
								allPriceLists
									.filter((priceList) => !(user.priceLists || []).includes(priceList))
									.map((priceList, i) => {
										return (
											<option
												key={i}
												value={priceList}
												onClick={(e) => {
													e.preventDefault()
												}}
											>
												{priceList}
											</option>
										)
									})}

							<option value="noSelect">New Pricelists...</option> */}
							{Array.isArray(allPriceLists) &&
								allPriceLists
									.filter((priceList) => !(user.priceLists || []).includes(priceList.slug))
									.map((priceList, i) => {
										return (
											<option
												key={i}
												value={priceList.slug}
												onClick={(e) => {
													e.preventDefault()
												}}
											>
												{priceList.name} ({priceList.slug})
											</option>
										)
									})}
						</select>
					</div>
				</div>
				<div className="form-entry">
					<label htmlFor={`userCompany${user._id ? user._id : ''}`} className="form-label">
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faHouse} />
						</div>
						<div className="label">User Company</div>
					</label>
					<select
						className="form-input"
						id={`userCompany${user._id ? user._id : ''}`}
						name="userCompany"
						value={user.userCompany || 'noSelect'}
						onChange={(e) => {
							if (e.target.value !== '' && e.target.value !== 'noSelect') {
								// user.userCompany = e.target.value
								setUserAttribute('userCompany', e.target.value)
							}
						}}
					>
						<option value="noSelect">Select one...</option>
						<option value={'none'}>Nessuna</option>
						<option value={'aeoss'}>Aeoss</option>
						<option value={'vialuce'}>Vialuce</option>
					</select>
				</div>
				{user._id && (
					<div className="form-entry">
						{user._id}
						<br />
						<button
							onClick={() => {
								navigator.clipboard.writeText(user._id)
							}}
						>
							Copia ID
						</button>
					</div>
				)}
				<button type="submit" className="form-submit">
					{modeToLabel(mode)}
				</button>
			</form>
		</div>
	)
}
