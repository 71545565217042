export default function FormEntry({ id, icon, label, value, onChange, style, className, ...rest }) {
	return (
		<div className={className || 'form-entry'} style={style}>
			<label htmlFor={id} className="form-label">
				{icon}
				<div className="label">{label}</div>
			</label>
			<input className="form-input" id={id} value={value} onChange={onChange} {...rest} />
		</div>
	)
}
