import useDocumentStore from '../../lib/stores/document'

import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'

export default function Title({ title, elementIndex, provided }) {
	const setElement = useDocumentStore((state) => state.setElement)

	function handleInput(key, value) {
		if (typeof setElement === 'function') {
			const element = { ...title }
			element[key] = value
			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	return (
		<div className="element">
			<input
				className="element-title element-full"
				value={title.title}
				placeholder="Title..."
				onChange={(e) => {
					e.preventDefault()
					handleInput('title', e.target.value)
				}}
			/>
			{provided && <DragElement provided={provided} />}
			<RemoveElement element={title} elementIndex={elementIndex} />
		</div>
	)
}
