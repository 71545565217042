import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'

// import useDocumentStore from '../../lib/stores/document'

export default function PageBreak({ pageBreak, elementIndex, provided }) {
	// const setElement = useDocumentStore((state) => state.setElement)

	// function handleInput(e) {
	// 	if (typeof setElement === 'function') {
	// 		const element = { ...pageBreak }
	// 		element.size = e.target.value
	// 		setElement(element, elementIndex)
	// 	} else {
	// 		console.error('document Set Element not defined')
	// 	}
	// }

	return (
		<div className="element">
			<h1>Page Breaker</h1>
			{provided && <DragElement provided={provided} />}
			<RemoveElement element={pageBreak} elementIndex={elementIndex} />
		</div>
	)
}
