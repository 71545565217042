import React from 'react'

import retrieveSavedAttributes from '../../lib/retrieveSavedAttribute'
import useIsMobile from '../../lib/utils/useIsMobile'

export default function CustomProduct({ itemInfo, quantity, composition = false }) {
	let savedAttribute = retrieveSavedAttributes()
	const isMobile = useIsMobile()

	let info = itemInfo

	const {
		type,
		strip,
		color,
		profile,
		finishing,
		cover,
		resinInitialColor,
		option,
		powerSupply,
		resin,
		cable,
		cable_1,
		cable_2,
		cableFinishing,
		cablePosition,
		lengths,
		cutAngle,
		cut,
		notes,
		powerInfo,
	} = info || {}

	if (!info) return null

	let isOptionValid = !option || !option.selected.default || !option.selected.name.toLowerCase().includes('comandata')

	let stripName = ''
	let profileName = ''
	let stripCod = ''
	let profileCod = ''
	let isAeoss = false
	let isLedpro = false
	switch (savedAttribute) {
		case 'led_pro':
			isLedpro = true
			if (strip && strip.selected) {
				stripName = typeof strip.selected.name_ledpro === 'string' ? strip.selected.name_ledpro : strip.selected.name
			}
			if (profile && profile.selected) {
				profileName = typeof profile.selected.name_ledpro === 'string' ? profile.selected.name_ledpro : profile.selected.name
			}
			break
		case 'aeoss':
			isAeoss = true
			if (strip && strip.selected) {
				stripName = strip.selected.name
				stripCod = `${strip.selected.cod}.${color.selected.slug}`
			}
			if (profile && profile.selected) {
				profileName = profile.selected.name
				profileCod = `${profile.selected.cod}`
			}
			break
		default:
			isAeoss = true
			if (strip && strip.selected) {
				stripName = strip.selected.name
			}
			if (profile && profile.selected) {
				profileName = profile.selected.name
			}
	}

	switch (type) {
		case 'customStrip':
			return (
				<div>
					{info && info.id && <h3 style={{ margin: '3px 3px 3px 0px' }}>ID: #{info.id}</h3>}
					<h3 style={{ margin: '3px 3px 3px 0px' }}>{quantity ? `${quantity}x ` : ''}Striscia LED a misura:</h3>
					<div style={{ marginLeft: '6px', fontSize: isMobile ? '12px' : '15px' }}>
						<b>Striscia LED:</b>&nbsp;
						{strip && strip.selected && (
							<>
								{stripCod ? `${stripCod} ` : ''}
								{stripName} - {strip.selected.power_m}
								<span>W/m</span> - {strip.selected.volt}
								<span>V</span>
							</>
						)}
						<br />
						<b>Temperatura colore Striscia LED:</b>&nbsp;{color && color.selected && color.selected.name}
						<br />
						<b>Tipologia di cavo iniziale:</b>&nbsp;{cut ? '2x ' : ''}
						{cable_1 && cable_1.selected && cable_1.selected.name}
						<br />
						<b>Tipologia di cavo finale:</b>&nbsp;{cut ? '2x ' : ''}
						{cable_2 && cable_2.selected && cable_2.selected.name}
						<br />
						{Array.isArray(lengths) &&
							lengths.map((length, i) => {
								return (
									<React.Fragment key={i}>
										{/* <b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;{(length.value * (length.multiplier || 1)).toFixed(2)}
										&nbsp;
										{length.unit || 'cm'} */}
										<b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;
										{length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0'
											? length.value.toFixed(0)
											: length.value.toFixed(1)}{' '}
										mm
										<br />
									</React.Fragment>
								)
							})}
						{notes && (
							<>
								<br />
								<div style={{ whiteSpace: 'break-spaces' }}>{notes}</div>
							</>
						)}
					</div>
				</div>
			)
		case 'customProfile':
			return (
				<div>
					{info && info.id && <h3 style={{ margin: '3px 3px 3px 0px' }}>ID: {info.id}</h3>}
					<h3 style={{ margin: '3px 3px 3px 0px' }}>
						{quantity ? `${quantity}x ` : ''}
						{composition ? 'Composizione profili:' : 'Taglio profilo a misura:'}
					</h3>
					<div style={{ marginLeft: '6px', fontSize: isMobile ? '12px' : '15px' }}>
						<b>Profilo:</b>&nbsp;
						{profileCod ? `${profileCod} ` : ''}
						{profileName}
						<br />
						<b>Finitura profilo:</b>&nbsp;{finishing && finishing.selected && finishing.selected.name}
						<br />
						{cover && (
							<>
								<b>Copertura:</b>&nbsp;{cover.selected && cover.selected.name}
								<br />
							</>
						)}
						{option && (isAeoss || isOptionValid) && (
							<>
								<b>Opzione:</b>&nbsp;{option.quantity ? `${option.quantity}x ` : '1x '}
								{option.selected && option.selected.name}
								<br />
							</>
						)}
						{powerSupply && (
							<>
								<b>Alimentatore/i:</b>&nbsp;{powerSupply.quantity ? `${powerSupply.quantity}x ` : '1x '}
								{powerSupply.selected && powerSupply.selected.name}
								<br />
							</>
						)}
						{resin && (isAeoss || resin.selected?.slug !== '0') && (
							<>
								<b>Trattamenti:</b>&nbsp;{resin.selected && resin.selected.name}
								<br />
							</>
						)}
						{/* <b>Tipologia di cavo:</b>&nbsp;{cut ? '2x ' : ''}
						{cable && cable.selected && cable.selected.name}
						<br />
						{cableFinishing && (
							<>
								<b>Colore cavo:</b>&nbsp;{cableFinishing.selected && cableFinishing.selected.name}
								<br />
							</>
						)}
						{cablePosition && (
							<>
								<b>Posizione cavo:</b>&nbsp;{cablePosition.selected && cablePosition.selected.name}
								<br />
							</>
						)} */}
						{Array.isArray(lengths) &&
							lengths.map((length, i) => {
								return (
									<React.Fragment key={i}>
										{/* <b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;{(length.value * (length.multiplier || 1)).toFixed(2)}
										&nbsp;
										{length.unit || 'cm'} */}
										<b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;
										{length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0'
											? length.value.toFixed(0)
											: length.value.toFixed(1)}{' '}
										mm
										<br />
									</React.Fragment>
								)
							})}
						Accessori inclusi
						<br />
					</div>
					{notes && (
						<>
							<br />
							<div style={{ whiteSpace: 'break-spaces' }}>{notes}</div>
						</>
					)}
				</div>
			)
		case 'customLamp':
			return (
				<div>
					{info && info.id && <h3 style={{ margin: '3px 3px 3px 0px' }}>ID: {info.id}</h3>}
					<h3 style={{ margin: '3px 3px 3px 0px' }}>
						{quantity ? `${quantity}x ` : ''}
						{composition ? 'Composizione lampade:' : 'Lampada a misura:'}
					</h3>
					<div style={{ marginLeft: '6px', fontSize: isMobile ? '12px' : '15px' }}>
						<b>Striscia LED:</b>&nbsp;
						{strip && strip.selected && (
							<>
								{stripCod ? `${stripCod} ` : ''}
								{stripName} - {strip.selected.power_m}
								<span>W/m</span> - {strip.selected.volt}
								<span>V</span>
							</>
						)}
						<br />
						<b>Temperatura colore Striscia LED:</b>&nbsp;{color && color.selected && color.selected.name}
						<br />
						{resinInitialColor && resinInitialColor.selected && (
							<>
								<b>Temperatura colore di partenza:</b>&nbsp;
								{resinInitialColor && resinInitialColor.selected && resinInitialColor.selected.name}
								<br />
							</>
						)}
						<b>Profilo:</b>&nbsp;
						{profileCod ? `${profileCod} ` : ''}
						{profileName}
						<br />
						<b>Finitura profilo:</b>&nbsp;{finishing && finishing.selected && finishing.selected.name}
						<br />
						{cover && (
							<>
								<b>Copertura:</b>&nbsp;{cover.selected && cover.selected.name}
								<br />
							</>
						)}
						{option && (isAeoss || isOptionValid) && (
							<>
								<b>Opzione:</b>&nbsp;{option.quantity ? `${option.quantity}x ` : '1x '}
								{option.selected && option.selected.name}
								<br />
							</>
						)}
						{powerSupply && (
							<>
								<b>Alimentatore:</b>&nbsp;{powerSupply.quantity ? `${powerSupply.quantity}x ` : '1x '}
								{powerSupply.selected && powerSupply.selected.name}
								<br />
							</>
						)}
						{resin && (isAeoss || resin.selected?.slug !== '0') && (
							<>
								<b>Trattamenti:</b>&nbsp;{resin.selected && resin.selected.name}
								<br />
							</>
						)}
						<b>Tipologia di cavo:</b>&nbsp;{cut ? '2x ' : ''}
						{cable && cable.selected && cable.selected.name}
						<br />
						{cableFinishing && (
							<>
								<b>Colore cavo:</b>&nbsp;{cableFinishing.selected && cableFinishing.selected.name}
								<br />
							</>
						)}
						{cablePosition && (
							<>
								<b>Posizione cavo:</b>&nbsp;{cablePosition.selected && cablePosition.selected.name}
								<br />
							</>
						)}
						{Array.isArray(lengths) &&
							lengths.map((length, i) => {
								return (
									<React.Fragment key={i}>
										{/* <b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;{(length.value * (length.multiplier || 1)).toFixed(2)}
										&nbsp;
										{length.unit || 'cm'} */}
										<b>Lunghezza{lengths.length > 1 ? ` #${i + 1}` : ''}:</b>&nbsp;
										{length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0'
											? length.value.toFixed(0)
											: length.value.toFixed(1)}{' '}
										mm
										<br />
									</React.Fragment>
								)
							})}
						{(!powerSupply || !powerSupply.selected) && (
							<>
								<br />
								<b>Potenza richiesta:</b>&nbsp;
								<span style={{ whiteSpace: 'break-spaces' }}>{powerInfo.watt}W</span>
								<div style={{ whiteSpace: 'break-spaces' }}>{notes}</div>
							</>
						)}
						{!(!powerSupply || !powerSupply.selected) && notes && (
							<>
								<br />
								<div style={{ whiteSpace: 'break-spaces' }}>{notes}</div>
							</>
						)}
					</div>
				</div>
			)
		default:
			return null
	}
}
