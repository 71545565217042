/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faShield, faUserShield, faUsers, faTags, faToggleOn, faList, faKey, faAt } from '@fortawesome/free-solid-svg-icons'

import ToggleSwitch from '../graphic/ToggleSwitch'
import FormEntry from '../graphic/FormEntry'

import { registerUser, getUserGroups, getPriceLists, getAttributeNames, editUser, getApps } from '../../lib/adminFunctions'

import '../../styles/components/Form.scss'

export default function UserForm({ userData = {}, mode = 'register' }) {
	const [user, setUser] = useState(cloneDeep(userData))

	const [allUserGroups, setAllUserGroups] = useState()
	const [allPriceLists, setAllPriceLists] = useState()
	const [allAttributeNames, setAllAttributeNames] = useState()
	const [allApps, setAllApps] = useState()

	const [newApp, setNewApp] = useState({})
	const [newModifiedPriceList, setNewModifiedPriceList] = useState({})

	useEffect(() => {
		getUserGroups().then((res) => {
			setAllUserGroups(res)
		})
		getPriceLists().then((res) => {
			setAllPriceLists(res)
		})
		getAttributeNames().then((res) => {
			setAllAttributeNames(res)
		})
		getApps().then((res) => {
			setAllApps(res)
		})
	}, [])

	function setUserAttribute(key, value) {
		const userCopy = { ...user }
		userCopy[key] = value
		setUser(userCopy)
	}

	function modeToLabel(mode) {
		switch (mode) {
			case 'edit':
				return 'Modifica'
			case 'register':
				return 'Crea'
			default:
				return mode
		}
	}

	const required = {}
	switch (mode) {
		case 'register':
			required.firstname = true
			required.lastname = true
			required.email = true
			required.password = true
			break
		case 'edit':
			required.firstname = true
			required.lastname = true
			required.email = true
			break
		default:
	}

	// console.log(allNewPriceLists)

	return (
		<div className="form-page">
			<form
				className="form-form"
				onSubmit={(e) => {
					e.preventDefault()
					// console.log(user)

					let message = ''

					if (!user.authLevel || user.authLevel === 'noSelect') {
						message += ' No authLevel'
					}
					if (!user.changableAttribute) {
						user.changableAttribute = false
					}
					if (!user.userGroup) {
						user.userGroup = null
					} else {
						if (!user.userGroup.id || user.userGroup.id === 'noSelect') {
							user.userGroup = null
						} else if (!user.userGroup.authLevel || user.userGroup.authLevel === 'noSelect') {
							user.userGroup.authLevel = 0
						}
					}

					if (message !== '') {
						alert(message)
						return
					}

					let toastId
					switch (mode) {
						case 'register':
							// check password
							if (!user.password.match(/^(?=.*[A-Za-z])[A-Za-z\d@$!%*#?&_\.]{5,}$/)) {
								alert('password not valid')
								return
							}
							toastId = toast.loading('Salvando utente...')

							registerUser(user)
								.then((res) => {
									// console.log(res)

									toast.update(toastId, {
										render: 'Utente salvato!',
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)

									toast.update(toastId, {
										render: `Errore nel salvare l'utente`,
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
							break
						case 'edit':
							if (user.password && !user.password.match(/^(?=.*[A-Za-z])[A-Za-z\d@$!%*#?&_\.]{5,}$/)) {
								alert('password not valid')
								return
							}
							toastId = toast.loading(`Salvando modifiche dell'utente...`)

							editUser(user)
								.then((res) => {
									// console.log(res)

									toast.update(toastId, {
										render: `Modifiche all'utente apportate!`,
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)

									toast.update(toastId, {
										render: `Errore nell'apportare le modifiche dell'utente`,
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
							break
						default:
					}
				}}
			>
				<FormEntry
					id={`userFirstname${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUser} />
						</div>
					}
					label="Firstname"
					required={required.firstname}
					value={user.firstname || ''}
					onChange={(e) => setUserAttribute('firstname', e.target.value)}
				/>
				<FormEntry
					id={`userLastname${user._id ? user._id : ''}`}
					icon={
						// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						// 	<path fill="none" d="M0 0h24v24H0z" />
						// 	<path d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
						// </svg>
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUser} />
						</div>
					}
					label="Lastname"
					required={required.lastname}
					value={user.lastname || ''}
					onChange={(e) => setUserAttribute('lastname', e.target.value)}
				/>
				<FormEntry
					id={`userSlug${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUser} />
						</div>
					}
					label="Slug"
					required={required.slug}
					value={user.slug || ''}
					onChange={(e) => setUserAttribute('slug', e.target.value)}
				/>
				<FormEntry
					id={`userEmail${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faAt} />
						</div>
					}
					label="Email"
					required={required.email}
					value={user.email || ''}
					onChange={(e) => setUserAttribute('email', e.target.value)}
				/>
				<FormEntry
					id={`userPassword${user._id ? user._id : ''}`}
					icon={
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faKey} />
						</div>
					}
					label="Password"
					type="password"
					required={required.password}
					value={user.password || ''}
					pattern={`^[0-9A-Za-z\d@$!%*#?&_\.]{5,}$`}
					title={'Minimun 5 characters, Use only latters, numbers, and symbols @ $ ! % * # ? & _ .'}
					onChange={(e) => setUserAttribute('password', e.target.value)}
				/>

				<div className="form-entry">
					<label htmlFor={`userAuthLevel${user._id ? user._id : ''}`} className="form-label">
						{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM12 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-4.473 5a4.5 4.5 0 0 1 8.946 0H7.527z" />
						</svg> */}
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faShield} />
						</div>
						<div className="label">AuthLevel</div>
					</label>
					<select
						className="form-input"
						id={`userAuthLevel${user._id ? user._id : ''}`}
						name="AuthLevel"
						value={typeof user.authLevel !== 'object' && typeof user.authLevel !== 'undefined' ? user.authLevel : 'noSelect'}
						onChange={(e) => {
							// if (e.target.value !== '' && e.target.value !== 'noSelect') {
							setUserAttribute('authLevel', e.target.value)
							// }
						}}
					>
						<option value="noSelect">Select one...</option>
						<option value={0}>Ospite</option>
						<option value={250}>Utente</option>
						<option value={750}>Gestore</option>
						<option value={1000}>Admin</option>
					</select>
				</div>
				<div className="form-entry">
					<label className="form-label">
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faToggleOn} />
						</div>
						<div className="label">ChangableAttribute</div>
					</label>
					<ToggleSwitch
						value={user.changableAttribute || false}
						setValue={(e) => setUserAttribute('changableAttribute', !user.changableAttribute)}
						center={false}
					/>
				</div>
				<div className="form-entry">
					<label htmlFor={`userUserGroups${user._id ? user._id : ''}`} className="form-label">
						{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z" />
						</svg> */}
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUsers} />
						</div>
						<div className="label">UserGroup</div>
					</label>
					<select
						className="form-input"
						id={`userUserGroups${user._id ? user._id : ''}`}
						name="UserGroups"
						value={(user.userGroup && user.userGroup.id) || ''}
						onChange={(e) => {
							const userGroup = user.userGroup || {}
							userGroup.id = e.target.value
							setUserAttribute('userGroup', userGroup)
						}}
					>
						<option value="noSelection">Select One...</option>
						{Array.isArray(allUserGroups) &&
							allUserGroups.map((group) => {
								return (
									<option key={group._id} value={group._id}>
										{group.code}
									</option>
								)
							})}
					</select>
				</div>
				<div className="form-entry">
					<label htmlFor={`userUserGroupAuthLevel${user._id ? user._id : ''}`} className="form-label">
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faUserShield} />
						</div>
						<div className="label">UserGroupAuthLevel</div>
					</label>
					<select
						className="form-input"
						id={`userUserGroupAuthLevel${user._id ? user._id : ''}`}
						name="UserGroupAuthLevel"
						value={
							(user.userGroup && typeof user.userGroup.authLevel !== 'object' && typeof user.userGroup.authLevel !== 'undefined'
								? user.userGroup.authLevel
								: 'noSelect') || 'noSelect'
						}
						onChange={(e) => {
							// if (e.target.value !== '' && e.target.value !== 'noSelect') {
							const userGroup = user.userGroup || {}
							userGroup.authLevel = e.target.value
							setUserAttribute('userGroup', userGroup)
							// }
						}}
					>
						<option value="noSelect">Select one...</option>
						<option value={0}>Ospite</option>
						<option value={250}>Utente</option>
						<option value={750}>Gestore</option>
						<option value={1000}>Admin</option>
					</select>
				</div>
				<div className="form-entry">
					<label htmlFor={`userAttributeNames${user._id ? user._id : ''}`} className="form-label">
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faList} />
						</div>
						<div className="label">AttributeNames</div>
					</label>
					<div className="form-array">
						{Array.isArray(user.attributeNames) &&
							user.attributeNames.map((attribute, i) => {
								return (
									<div
										key={i}
										className="form-array-item"
										onClick={() => {
											const attributes = user.attributeNames
											attributes.splice(i, 1)
											setUserAttribute('attributeNames', attributes)
											// user.attributeNames.splice(i, 1)
										}}
									>
										{attribute}
									</div>
								)
							})}
						<select
							className="form-input"
							id={`userAttributeNames${user._id ? user._id : ''}`}
							name="AttributeNames"
							value={['noSelect']}
							multiple
							onChange={(e) => {
								e.preventDefault()
								// console.log(e)
								if (e.target.value !== 'noSelect' && e.target.value !== '') {
									const attributeNames = user.attributeNames || []
									attributeNames.push(e.target.value)
									setUserAttribute('attributeNames', attributeNames)
								}
							}}
						>
							<option value="noSelect">Select any...</option>
							{Array.isArray(allAttributeNames) &&
								allAttributeNames
									.filter((attribute) => !(user.attributeNames || []).includes(attribute))
									.map((attribute, i) => {
										return (
											<option key={i} value={attribute}>
												{attribute}
											</option>
										)
									})}
						</select>
					</div>
				</div>
				<div className="form-entry">
					<label htmlFor={`userPriceLists${user._id ? user._id : ''}`} className="form-label">
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faTags} />
						</div>
						<div className="label">Price Lists</div>
					</label>
					<div className="form-array">
						{Array.isArray(user.priceLists) &&
							user.priceLists.map((priceList, i) => {
								return (
									<div
										key={i}
										className="form-array-item"
										onClick={() => {
											const priceLists = user.priceLists
											priceLists.splice(i, 1)
											setUserAttribute('priceLists', priceLists)
										}}
									>
										{priceList}
									</div>
								)
							})}
						<select
							className="form-input"
							style={{ minHeight: '150px' }}
							id={`userPriceLists${user._id ? user._id : ''}`}
							name="PriceLists"
							value={['noSelect']}
							multiple
							onChange={(e) => {
								e.preventDefault()
								// console.log(e)
								if (e.target.value !== 'noSelect' && e.target.value !== '') {
									const priceLists = user.priceLists || []
									priceLists.push(e.target.value)
									setUserAttribute('priceLists', priceLists)
								}
							}}
						>
							<option value="noSelect">Select any...</option>
							{/* {Array.isArray(allPriceLists) &&
								allPriceLists
									.filter((priceList) => !(user.priceLists || []).includes(priceList))
									.map((priceList, i) => {
										return (
											<option
												key={i}
												value={priceList}
												onClick={(e) => {
													e.preventDefault()
												}}
											>
												{priceList}
											</option>
										)
									})}

							<option value="noSelect">New Pricelists...</option> */}
							{Array.isArray(allPriceLists) &&
								allPriceLists
									.filter((priceList) => !(user.priceLists || []).includes(priceList.slug))
									.map((priceList, i) => {
										return (
											<option
												key={i}
												value={priceList.slug}
												onClick={(e) => {
													e.preventDefault()
												}}
											>
												{priceList.name} ({priceList.slug})
											</option>
										)
									})}
						</select>
					</div>
				</div>
				<div className="form-entry" style={{ width: '600px' }}>
					{/* {`List
					name auth

					Add
					name auth
					add`} */}
					<div>Lista Apps</div>
					<div className="apps-list">
						{Array.isArray(user.apps) &&
							user.apps.map((app, i) => {
								return (
									<div className="app-entry" key={i}>
										<div>{app.name}</div>
										<div>
											<input
												value={app.appAuthLevel || ''}
												onChange={(e) => {
													if (!isNaN(parseInt(e.target.value))) {
														let userApps = [...user.apps]

														userApps[i].appAuthLevel = parseInt(e.target.value)

														setUserAttribute('apps', userApps)
													}
												}}
											/>
										</div>
										{/* <div>{app.appAuthLevel}</div> */}
										<div className="remove-app" onClick={(e) => {}}>
											Rimuovi
										</div>
									</div>
								)
							})}
					</div>
					<div>Aggiungi App</div>
					<div className="new-app">
						<select
							className="form-input"
							onChange={(e) => {
								let appToAdd = { ...newApp }
								appToAdd.name = e.target.value
								setNewApp(appToAdd)
							}}
						>
							<option value={null}>Selezionare un'app</option>
							{Array.isArray(allApps) &&
								allApps
									.filter((app) => !Array.isArray(user.apps) || !user.apps.find((userApp) => userApp.name === app.name))
									.map((app) => {
										return <option value={app.name}>{app.name}</option>
									})}
						</select>
						<div>
							<input
								value={newApp.appAuthLevel || ''}
								onChange={(e) => {
									if (!isNaN(parseInt(e.target.value))) {
										let appToAdd = { ...newApp }
										appToAdd.appAuthLevel = parseInt(e.target.value)
										setNewApp(appToAdd)
									}
								}}
							/>
						</div>
						<div
							className="add-app"
							onClick={(e) => {
								let userApps = []
								if (Array.isArray(user.apps)) userApps = [...user.apps]

								userApps.push({ ...newApp })

								setNewApp({})
								setUserAttribute('apps', userApps)
							}}
						>
							Aggiungi
						</div>
					</div>
				</div>
				<div className="form-entry">
					<label htmlFor={`userAuthType${user._id ? user._id : ''}`} className="form-label">
						{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM12 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-4.473 5a4.5 4.5 0 0 1 8.946 0H7.527z" />
						</svg> */}
						<div style={{ fontSize: '24px', height: '24px' }}>
							<FontAwesomeIcon icon={faShield} />
						</div>
						<div className="label">Tipologia Utente</div>
					</label>
					<select
						className="form-input"
						id={`userAuthType${user._id ? user._id : ''}`}
						name="AuthLevel"
						value={typeof user.userType ? user.userType : 'noSelect'}
						onChange={(e) => {
							// if (e.target.value !== '' && e.target.value !== 'noSelect') {
							setUserAttribute('userType', e.target.value)
							// }
						}}
					>
						<option value="noSelect">Select one...</option>
						<option value={1}>Interno</option>
						<option value={2}>Esterno</option>
					</select>
				</div>
				<button type="submit" className="form-submit">
					{modeToLabel(mode)}
				</button>
			</form>
		</div>
	)
}
