import axios from 'axios'
import { useEffect } from 'react'

import useDocumentStore from '../../lib/stores/document'
import useUserStore from '../../lib/stores/user'

import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'
import UploadsList from '../graphic/UploadsList'

import useUploadsStore from '../../lib/stores/uploads'
import useModalStore from '../../lib/stores/modal'

import '../../styles/components/ImageUpload.scss'

export default function ImageUpload({ image, elementIndex, provided }) {
	const setElement = useDocumentStore((state) => state.setElement)
	const { token } = useUserStore((state) => ({ token: state.token }))
	const { uploads, setUploadData } = useUploadsStore((state) => ({ uploads: state.uploads, setUploadData: state.setUploadData }))
	const { addModal, removeModal } = useModalStore((state) => ({ addModal: state.addModal, removeModal: state.removeModal }))

	// function handleInput(key, value) {
	// 	if (typeof setElement === 'function') {
	// 		const element = { ...title }
	// 		element[key] = value
	// 		setElement(element, elementIndex)
	// 	} else {
	// 		console.error('document Set Element not defined')
	// 	}
	// }

	// Retrieve fileData
	useEffect(() => {
		if (image) {
			axios
				.get(`${process.env.REACT_APP_SERVER_URL}/api/uploads/${image.fileId}/${image.name}`, {
					headers: { authorization: token },
					responseType: 'blob',
				})
				.then((fileRes) => {
					// console.log(fileRes, typeof fileRes.data)
					if (fileRes.data) {
						const reader = new FileReader()
						reader.readAsDataURL(fileRes.data)
						reader.onloadend = () => {
							const base64data = reader.result

							// setFileData(base64data)
							setUploadData(image.fileId, base64data)
						}
					}
				})
				.catch((e) => {
					// console.error(e)
				})
		}
		// eslint-disable-next-line
	}, [])

	return (
		<div className="element image-upload">
			<label className="file-upload">
				<div className="file-upload-zone" id="fileUploadFiles">
					<div className="file-upload-names">
						{image.fileId ? (
							<img style={{ maxWidth: '100%' }} src={`${uploads[image.fileId]}`} alt="uploaded_image" />
						) : (
							<span>Nessuna immagine caricata</span>
						)}
					</div>
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z" />
						</svg>
					</div>
					<span>Fai clic o trascina l'immagine su quest'area per caricarla</span>
					<input
						type="file"
						className="form-file"
						name="files"
						// id="files"
						onChange={(e) => {
							// console.log(e, Array.isArray(e.target.files), e.target.files)
							if (e.target.files.length > 0) {
								let file = e.target.files[0]

								// console.log(file)
								if (file && (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
									if (file.size >= 1024 * 1024 * 3) {
										alert(`l'immagine è troppo pesante (3Mb)`)
									} else {
										let _URL = window.URL || window.webkitURL

										let img = new Image()
										img.onload = function () {
											// alert(this.width + " " + this.height);
											let bodyFormData = new FormData()
											bodyFormData.append('name', file.name)
											bodyFormData.append('type', file.type)
											bodyFormData.append('size', file.size)
											bodyFormData.append('width', this.width)
											bodyFormData.append('height', this.height)
											bodyFormData.append('file', file)

											axios
												.post(`${process.env.REACT_APP_SERVER_URL}/api/uploads`, bodyFormData, {
													headers: { 'Content-Type': 'multipart/form-data', authorization: token },
												})
												.then((res) => {
													// console.log(res)
													//
													if (res.data) {
														const element = { ...image }
														element.fileId = res.data._id
														element.name = res.data.name
														element.size = res.data.size
														element.width = res.data.width
														element.height = res.data.height
														element.fileType = res.data.type
														setElement(element, elementIndex)

														axios
															.get(`${process.env.REACT_APP_SERVER_URL}/api/uploads/${res.data._id}/${res.data.name}`, {
																headers: { authorization: token },
																responseType: 'blob',
															})
															.then((fileRes) => {
																// console.log(fileRes, typeof fileRes.data)
																if (fileRes.data) {
																	const reader = new FileReader()
																	reader.readAsDataURL(fileRes.data)
																	reader.onloadend = () => {
																		const base64data = reader.result

																		// setFileData(base64data)
																		setUploadData(res.data._id, base64data)
																	}
																}
															})
															.catch((e) => {
																// console.error(e)
															})
													}
												})
												.catch((e) => {
													console.error(e)
												})
										}
										img.onerror = function () {
											alert('not a valid file: ' + file.type)
										}
										img.src = _URL.createObjectURL(file)

										e.target.value = ''
									}
								} else {
									e.target.value = ''
								}
							}
						}}
					/>
				</div>
			</label>
			{/* <UploadsList /> */}
			<div
				className="uploads-list-button"
				onClick={() => {
					if (typeof addModal === 'function') {
						addModal({
							modal: (
								<div style={{ width: '85vw', height: '85vh', overflow: 'auto' }}>
									{
										<UploadsList
											onUploadClick={(upload, uploadData) => {
												//
												const element = { ...image }
												element.fileId = upload._id
												element.name = upload.name
												element.size = upload.size
												element.width = upload.width
												element.height = upload.height
												element.fileType = upload.type
												setElement(element, elementIndex)
												if (!uploadData) {
													axios
														.get(`${process.env.REACT_APP_SERVER_URL}/api/uploads/${upload._id}/${upload.name}`, {
															headers: { authorization: token },
															responseType: 'blob',
														})
														.then((fileRes) => {
															// console.log(fileRes, typeof fileRes.data)
															if (fileRes.data) {
																const reader = new FileReader()
																reader.readAsDataURL(fileRes.data)
																reader.onloadend = () => {
																	const base64data = reader.result

																	// setFileData(base64data)
																	setUploadData(upload._id, base64data)
																}
															}
														})
														.catch((e) => {
															// console.error(e)
														})
												}

												removeModal('UploadListModal')
											}}
										/>
									}
								</div>
							),
							id: 'UploadListModal',
							background: true,
							replaceModal: true,
							disposableModal: true,
						})
					} else {
						console.log('addModal not funciton?', addModal)
					}
				}}
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '5px' }}>
					<path d="M11 4H21V6H11V4ZM11 8H17V10H11V8ZM11 14H21V16H11V14ZM11 18H17V20H11V18ZM3 4H9V10H3V4ZM5 6V8H7V6H5ZM3 14H9V20H3V14ZM5 16V18H7V16H5Z"></path>
				</svg>
				Mostra Lista
			</div>
			{provided && <DragElement provided={provided} />}
			<RemoveElement element={image} elementIndex={elementIndex} />
		</div>
	)
}
