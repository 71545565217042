import create from 'zustand'
import { persist } from 'zustand/middleware'

const initialConfigurationStore = {
	configurations: {
		exportCod: false,
		exportIva: false,
		allAttributes: false,
		showExtra: false,
		hideAlert: false,
	},
}

// Store for user Document, possible TODO: load document info from server??
const useConfigurationStore = create(
	persist(
		(set, get) => ({
			...initialConfigurationStore,
			clearStore: () => set({ ...initialConfigurationStore }),
			setConfigurations: (configurations) => set({ configurations: configurations }),
			setConfiguration: (name, configuration) => {
				const configurations = get().configurations

				configurations[name] = configuration
				set({ configurations: configurations })
			},
			toggleConfiguration: (name) => {
				const configurations = get().configurations

				configurations[name] = !configurations[name]
				set({ configurations: configurations })
			},
		}),
		{
			name: 'configuration-storage', // unique name
			// storage: typeof window !== 'undefined' ? window.localStorage : dummyStorageApi,
		}
	)
)

export default useConfigurationStore
