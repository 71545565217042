import { multiply, ceil, min, divide } from 'mathjs'

function findPowerSupply(dataList, power) {
	let selectIndex = -1
	let quantity = 0
	let hasVolt = false

	if (!power || power.volt === undefined || power.volt === null || !power.watt) {
		return { selectIndex: selectIndex, quantity: quantity }
	}

	while (selectIndex === -1) {
		quantity++
		for (let i = 0; i < dataList.length; i++) {
			if (dataList[i].volt === power.volt) {
				hasVolt = true
			}
			if (dataList[i].max_power * quantity >= power.watt && dataList[i].volt === power.volt) {
				selectIndex = i
				break
			}
		}

		if (!hasVolt) {
			break
		}
	}

	return { selectIndex: selectIndex, quantity: quantity }
}

function calculatePowerSupplyWithData({ steps, data, newPowerInfo, setSteps }) {
	let newSteps = [...steps]
	let stepIndex = newSteps.findIndex((step) => step.slug === 'power_supplies')

	// check power supplies
	if (stepIndex !== -1 && newSteps[stepIndex].active) {
		if (stepIndex !== -1) {
			let dataList = data && data.products && data.products.power_supplies

			// filter power supplies with usable un lamp
			dataList = dataList.filter((ps) => ps.useInLamps)

			if (Array.isArray(dataList)) {
				dataList.sort((a, b) => {
					return a.max_power - b.max_power
				})

				let { selectIndex, quantity } = findPowerSupply(dataList, newPowerInfo)

				if (selectIndex !== -1) {
					newSteps[stepIndex].selected = dataList[selectIndex]
					newSteps[stepIndex].quantity = quantity
					setSteps(newSteps)
				} else {
					delete newSteps[stepIndex].selected
					newSteps[stepIndex].quantity = 0
					setSteps(newSteps)
				}
			}
		}

		let optionsIndex = newSteps[stepIndex].requiredIndex
		if (optionsIndex || optionsIndex === 0) {
			newSteps[optionsIndex].quantity = newSteps[stepIndex].quantity
		}
	}
}

export function calculatePowerSupply({ steps, data, newPowerInfo, setSteps }) {
	if (!data) {
		return fetch('/data/data.json')
			.then((res) => res.json())
			.then((data) => {
				return calculatePowerSupplyWithData({ steps, data, newPowerInfo, setSteps })
			})
	} else {
		return calculatePowerSupplyWithData({ steps, data, newPowerInfo, setSteps })
	}
}

export default function calculatePowerInfo({ steps, lengths, powerInfo = {} }) {
	let newPowerInfo = { ...powerInfo }

	// calculate power consumption
	let volt = 0
	let wattM = 0
	let wattFix = 0
	let watt = 0
	if (Array.isArray(steps)) {
		steps.forEach((step) => {
			// calculate led strip power consumption
			if (step.slug === 'led_strips') {
				if (step.selected) {
					volt = step.selected.volt
					wattFix += step.selected.power || 0.0
					wattM += step.selected.power_m || 0.0

					lengths.forEach((length) => {
						watt += ceil(
							(step.selected.power_m || 0.0) *
								min(
									// length rounded to ceil of 0.5
									// chain(length.calculatedMax ? length.calculatedMax : length.value)
									// 	.divide(500)
									// 	.ceil()
									// 	.divide(2)
									// 	.done(),

									divide(length.calculatedMax ? length.calculatedMax : length.value, 1000),
									6
								),
							1
						)
						watt += step.selected.power || 0.0
					})
				}

				// check profiles for multiplier (profiles with 2 led strips)
			} else if (step.slug === 'profiles') {
				if (step.selected && step.selected.multiplier) {
					wattFix += step.selected.power || 0.0

					if (Array.isArray(step.multiplierIndexes)) {
						step.multiplierIndexes.forEach((index) => {
							if (steps[index] && steps[index].selected) {
								wattM += multiply(steps[index].selected.power_m || 0.0, step.selected.multiplier - 1)
								lengths.forEach((length) => {
									watt += ceil(
										multiply(
											(steps[index].selected.power_m || 0.0) *
												min(
													// length rounded to ceil of 0.5
													// chain(length.calculatedMax ? length.calculatedMax : length.value)
													// 	.divide(500)
													// 	.ceil()
													// 	.divide(2)
													// 	.done(),

													divide(length.calculatedMax ? length.calculatedMax : length.value, 1000),
													6
												),
											step.selected.multiplier - 1
										),
										1
									)
									watt += step.selected.power || 0.0
								})
							}
						})
					}
				}
			} else if (step.slug !== 'power_supplies') {
				if (step.selected) {
					wattFix += step.selected.power || 0.0
					wattM += step.selected.power_m || 0.0

					lengths.forEach((length) => {
						watt += ceil(
							(step.selected.power_m || 0.0) *
								min(
									// length rounded to ceil of 0.5
									// chain(length.calculatedMax ? length.calculatedMax : length.value)
									// 	.divide(500)
									// 	.ceil()
									// 	.divide(2)
									// 	.done(),

									divide(length.calculatedMax ? length.calculatedMax : length.value, 1000),
									6
								),
							1
						)
						watt += step.selected.power || 0.0
					})
				}
			}
		})
		newPowerInfo.volt = volt
		newPowerInfo.wattM = wattM
		newPowerInfo.wattFix = wattFix
		newPowerInfo.watt = watt

		// let newSteps = [...steps]
		// let stepIndex = newSteps.findIndex((step) => step.slug === 'power_supplies')

		// // check power supplies
		// if (stepIndex !== -1 && newSteps[stepIndex].active) {
		// 	if (stepIndex !== -1) {
		// 		let dataList = data && data.products && data.products.power_supplies

		// 		// filter power supplies with usable un lamp
		// 		dataList = dataList.filter((ps) => ps.useInLamps)

		// 		if (Array.isArray(dataList)) {
		// 			dataList.sort((a, b) => {
		// 				return a.max_power - b.max_power
		// 			})

		// 			let { selectIndex, quantity } = findPowerSupply(dataList, newPowerInfo)

		// 			if (selectIndex !== -1) {
		// 				newSteps[stepIndex].selected = dataList[selectIndex]
		// 				newSteps[stepIndex].quantity = quantity
		// 				setSteps(newSteps)
		// 			} else {
		// 				delete newSteps[stepIndex].selected
		// 				newSteps[stepIndex].quantity = 0
		// 				setSteps(newSteps)
		// 			}
		// 		}
		// 	}

		// 	let optionsIndex = newSteps[stepIndex].requiredIndex
		// 	if (optionsIndex || optionsIndex === 0) {
		// 		newSteps[optionsIndex].quantity = newSteps[stepIndex].quantity
		// 	}
		// }

		return newPowerInfo
		// setPowerInfo(newPowerInfo)
	}
}
