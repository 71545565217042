import { v4 as uuidv4 } from 'uuid'

export default function elements(set, get) {
	return {
		setElements: (elements) => {
			let document = { ...get().document }

			document.elements = elements
			set({ document: document })
		},
		addZone: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'zone',
				title: '',
				id: uuidv4(),
				items: [{ id: uuidv4(), quantity: 1, name: '', info: '', price: 0.0, length: 1, discount: '' }],
			})
			set({ document: document })
		},
		addCutList: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'cutList',
				title: '',
				id: uuidv4(),
				items: [{ id: uuidv4(), quantity: 1, name: '', length: 1 }],
			})
			set({ document: document })
		},
		addDrawSpace: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'drawSpace',
				title: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addPageBreak: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'pageBreak',
				title: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addCutDrawing: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			const ids = [uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4()]

			document.elements.push({
				type: 'cutDrawing',
				title: '',
				notes: '',
				drawType: 'single',
				labelPosition: 'external',
				dimmerIndex: -1,
				inverse: false,
				// defaults: {
				// 	single: {
				// 		dimmerIndex: -1,
				// 		inverse: false,
				// 		cables: [
				// 			{
				// 				type: 'cable',
				// 				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				// 				statusIndex: 0,
				// 				graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 270 },
				// 			},
				// 			{
				// 				type: 'cable',
				// 				status: ['none', 'cable'],
				// 				statusIndex: 0,
				// 				graphicInfos: { x: 100, y: 0, type: 'dot', labelAngle: 45 },
				// 			},
				// 			{
				// 				type: 'cable',
				// 				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				// 				statusIndex: 0,
				// 				graphicInfos: { x: 100, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 180 },
				// 			},
				// 		],
				// 		profiles: [
				// 			{
				// 				type: 'profile',
				// 				length: 1600,
				// 				graphicInfos: { x: 0, y: 0, type: 'line', length: 100, angle: 0, orientation: 'horizontal' },
				// 			},
				// 			{
				// 				type: 'profile',
				// 				length: 1000,
				// 				graphicInfos: { x: 100, y: 0, type: 'line', length: (1000 / 1600) * 100, angle: 270, orientation: 'vertical' },
				// 			},
				// 		],
				// 	},

				// 	double: {
				// 		dimmerIndex: -1,
				// 		inverse: false,
				// 		cables: [
				// 			{
				// 				type: 'cable',
				// 				status: ['none', 'cable'],
				// 				statusIndex: 0,
				// 				graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 315 },
				// 			},
				// 			{
				// 				type: 'cable',
				// 				status: ['none', 'cable'],
				// 				statusIndex: 0,
				// 				graphicInfos: { x: 100, y: 0, type: 'dot', labelAngle: 45 },
				// 			},
				// 			{
				// 				type: 'cable',
				// 				status: ['none', 'cable'],
				// 				statusIndex: 0,
				// 				graphicInfos: { x: 100, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 135 },
				// 			},
				// 			{
				// 				type: 'cable',
				// 				status: ['none', 'cable'],
				// 				statusIndex: 0,
				// 				graphicInfos: { x: 0, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 225 },
				// 			},
				// 		],
				// 		profiles: [
				// 			{
				// 				type: 'profile',
				// 				length: 1600,
				// 				graphicInfos: { x: 0, y: 0, type: 'line', length: 100, angle: 0, orientation: 'horizontal' },
				// 			},
				// 			{
				// 				type: 'profile',
				// 				length: 1000,
				// 				graphicInfos: { x: 100, y: 0, type: 'line', length: (1000 / 1600) * 100, angle: 270, orientation: 'vertical' },
				// 			},
				// 			{
				// 				type: 'profile',
				// 				length: 1600,
				// 				graphicInfos: {
				// 					x: 0,
				// 					y: (1000 / 1600) * 100,
				// 					type: 'line',
				// 					length: 100,
				// 					angle: 0,
				// 					orientation: 'horizontal',
				// 					inverseLabel: true,
				// 				},
				// 			},
				// 			{
				// 				type: 'profile',
				// 				length: 1000,
				// 				graphicInfos: {
				// 					x: 0,
				// 					y: 0,
				// 					type: 'line',
				// 					length: (1000 / 1600) * 100,
				// 					angle: 270,
				// 					orientation: 'vertical',
				// 					inverseLabel: true,
				// 				},
				// 			},
				// 		],
				// 	},
				// },

				cables: [
					{
						type: 'cable',
						status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
						statusIndex: 0,
						graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 270 },
					},
					{
						type: 'cable',
						status: ['none', 'cable'],
						statusIndex: 0,
						graphicInfos: { x: 100, y: 0, type: 'dot', labelAngle: 45 },
					},
					{
						type: 'cable',
						status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
						statusIndex: 0,
						graphicInfos: { x: 100, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 180 },
					},
				],
				profiles: [
					{
						type: 'profile',
						length: 1600,
						graphicInfos: { x: 0, y: 0, type: 'line', length: 100, angle: 0, orientation: 'horizontal' },
					},
					{
						type: 'profile',
						length: 1000,
						graphicInfos: { x: 100, y: 0, type: 'line', length: (1000 / 1600) * 100, angle: 270, orientation: 'vertical' },
					},
				],
				startId: ids[0],
				id: uuidv4(),
			})
			set({ document: document })
		},
		addTitle: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'title',
				title: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addImageUpload: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'imageUpload',
				title: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addSubtitle: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'subtitle',
				subtitle: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addDescription: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'description',
				description: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addHiddenNote: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'hiddenNote',
				note: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addSeparator: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'separator',
				separator: '',
				id: uuidv4(),
			})
			set({ document: document })
		},
		addShipping: () => {
			let document = { ...get().document }
			if (!document.elements) {
				document.elements = []
			}
			document.elements.push({
				type: 'shipping',
				shipping: {},
				id: uuidv4(),
			})
			set({ document: document })
		},
		setElement: (element, elementIndex) => {
			let document = { ...get().document }
			if (!Array.isArray(document.elements)) {
				console.warn('Setting element without elements in document')
				return
			}

			// find document using provided index or id
			let index
			if (typeof elementIndex === 'number' && document.elements[elementIndex]) {
				index = elementIndex
			} else {
				index = document.elements.findIndex((item) => item.id === element.id)
			}

			if (index !== -1) {
				let docElements = [...document.elements]
				docElements[index] = element
				document.elements = docElements
				set({ document: document, forcePriceUpdate: Math.random() })
			}
		},
		removeElement: (element, elementIndex) => {
			let document = { ...get().document }
			if (!Array.isArray(document.elements)) {
				console.warn('Setting element without elements in document')
				return
			}

			let r = window.confirm('Vuoi cancellare questo elemento?')

			if (r) {
				// find document using provided index or id
				let index
				if (typeof elementIndex === 'number' && elementIndex >= 0 && document.elements[elementIndex]) {
					index = elementIndex
				} else {
					index = document.elements.findIndex((item) => item.id === element.id)
				}

				if (index !== -1) {
					document.elements.splice(index, 1)
					set({ document: document, forcePriceUpdate: Math.random() })
				}
			}
		},
		copyElement: (element, elementIndex) => {
			let document = { ...get().document }
			if (!Array.isArray(document.elements)) {
				console.warn('Setting element without elements in document')
				return
			}

			// find document using provided index or id
			let index
			if (typeof elementIndex === 'number' && elementIndex >= 0 && document.elements[elementIndex]) {
				index = elementIndex
			} else {
				index = document.elements.findIndex((item) => item.id === element.id)
			}

			const copy = JSON.parse(JSON.stringify(element))
			copy.id = uuidv4()

			// update id for single items
			if (copy.type === 'zone') {
				if (Array.isArray(copy.items)) {
					copy.items.forEach((item, i) => {
						copy.items[i].id = uuidv4()
					})
				}
			}

			if (index !== -1) {
				document.elements.splice(index + 1, 0, copy)
				set({ document: document, forcePriceUpdate: Math.random() })
			}
		},
	}
}
