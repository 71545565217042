import { useState, useRef } from 'react'

import ContentEditable from '../ContentEditable'
import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'

import isChildOf from '../../lib/isChildOf'
import useDocumentStore from '../../lib/stores/document'

export default function Shipping({ shipping, elementIndex, provided }) {
	const setElement = useDocumentStore((state) => state.setElement)
	const [isFocused, setIsFocused] = useState(false)
	const searchInputRef = useRef()

	const spedizioni = [
		{ price: 0, name: 'Spedizione 1-2 Giorni' },
		{ price: 0, name: 'Spedizione 1-2 Giorni Assicurata' },
		{ price: 0, name: 'Spedizione Gratuita' },
		{ price: 0, name: 'Spedizione Europa' },
		{ price: 0, name: 'Spedizione Extra-EU' },
		{ price: 0, name: 'Ritiro in sede' },
	]

	function handleInput(key, value) {
		if (typeof setElement === 'function') {
			const element = { ...shipping }
			if (!element.shipping) {
				element.shipping = {}
			}
			if (Array.isArray(key)) {
				for (let i = 0; i < key.length; i++) {
					element.shipping[key[i]] = value[i]
				}
			} else {
				element.shipping[key] = value
			}

			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	function selectItem(shippingItem) {
		const element = { ...shipping }
		if (!element.shipping) {
			element.shipping = {}
		}

		element.shipping.name = shippingItem && shippingItem.name
		element.shipping.price = shippingItem && shippingItem.price

		console.log(element.shipping.price)

		setElement(element, elementIndex)
	}

	function handleKeyDownMacros(event, type, name) {
		switch (event.key) {
			case 'Escape':
				event.preventDefault()
				if (type === 'list') {
					if (event.target.parentNode.previousElementSibling) {
						event.target.parentNode.previousElementSibling.focus()
					}
				}
				setIsFocused(false)
				break
			case 'Enter':
				if (name) {
					event.preventDefault()
					selectItem(name)
					setIsFocused(false)
					if (searchInputRef.current) searchInputRef.current.focus()
				}
				break
			case 'ArrowUp':
				if (type === 'list') {
					if (event.target.previousElementSibling && typeof event.target.previousElementSibling.focus === 'function') {
						event.preventDefault()
						event.target.previousElementSibling.focus()
					}
				}

				break
			case 'ArrowDown':
				if (type === 'list') {
					if (event.target.nextElementSibling && typeof event.target.nextElementSibling.focus === 'function') {
						event.preventDefault()
						event.target.nextElementSibling.focus()
					}
				} else if (type === 'input') {
					if (
						event.target.nextElementSibling &&
						event.target.nextElementSibling.firstChild &&
						typeof event.target.nextElementSibling.firstChild.focus === 'function'
					) {
						event.preventDefault()
						event.target.nextElementSibling.firstChild.focus()
					}
				}
				// if (selectedInput !== index) {
				// 	setSelectedInput(index)
				// }
				break
			default:
		}
	}

	return (
		<div className="element">
			<h2 className="shipping-title">Spedizione</h2>
			<div className="shipping-content shadow">
				<div className="shipping-data-row">
					<div className="shipping-entry shipping-name">
						<label htmlFor={`shipping-name#${shipping.id}`} className="shipping-entry-label">
							Nome
						</label>
						<input
							id={`shipping-name#${shipping.id}`}
							name={`shipping-name#${shipping.id}`}
							className="shipping-entry-input"
							value={(shipping.shipping && shipping.shipping.name) || ''}
							onBlur={(e) => {
								if (!isChildOf(e.target.nextElementSibling, e.relatedTarget)) {
									setIsFocused(false)
								}
							}}
							onFocus={(e) => {
								setIsFocused(true)
							}}
							onKeyDown={(e) => handleKeyDownMacros(e, 'input')}
							onChange={(e) => {
								setIsFocused(true)
								handleInput('name', e.target.value)
							}}
						/>
						{isFocused && Array.isArray(spedizioni) && spedizioni.length > 0 && (
							<ul className="shipping-search shadow-close">
								{spedizioni.map((shippingItem, j) => {
									return (
										<li
											key={j}
											className="shipping-search-item"
											tabIndex="-1"
											// style={{ width: '100%' }}
											onBlur={(e) => {
												// console.dir(e.relatedTarget)
												if (
													!isChildOf(e.target.parentNode, e.relatedTarget) &&
													e.target.parentNode.previousElementSibling !== e.relatedTarget
												) {
													setIsFocused(false)
												}
											}}
											onKeyDown={(e) => handleKeyDownMacros(e, 'list', shippingItem)}
											onClick={(e) => {
												selectItem(shippingItem)
												setIsFocused(false)
											}}
										>
											{shippingItem && shippingItem.name}
										</li>
									)
								})}
							</ul>
						)}
					</div>
					<div className="shipping-entry shipping-price">
						<label htmlFor={`shipping-price#${shipping.id}`} className="shipping-entry-label">
							Prezzo
						</label>
						<input
							id={`shipping-price#${shipping.id}`}
							className="shipping-entry-input"
							value={
								shipping.shipping
									? typeof shipping.shipping.price === 'number' || typeof shipping.shipping.price === 'string'
										? shipping.shipping.price
										: ''
									: ''
							}
							onChange={(e) => {
								if (e.target.value.match(/^[+-]?[0-9]*[.,]?[0-9]*$/gimu)) {
									handleInput('price', e.target.value)
								}
							}}
						/>
					</div>
					<div className="shipping-entry shipping-notes">
						<label htmlFor={`shipping-notes#${shipping.id}`} className="shipping-entry-label">
							Note
						</label>
						<ContentEditable
							className="shipping-entry-input shipping-entry-notes"
							value={(shipping.shipping && shipping.shipping.notes) || ''}
							placeholder="Note..."
							onInput={(e) => {
								handleInput(['notes', 'notesPlainText'], [e.target.value, e.target.htmlAsText])
							}}
						/>
						{/* <input id={`shipping-notes#${shipping.id}`} className="shipping-entry-input" value={shipping.notes || ''} /> */}
					</div>

					<RemoveElement element={shipping} elementIndex={elementIndex} />
					{provided && <DragElement provided={provided} />}
				</div>
			</div>
		</div>
	)
}
