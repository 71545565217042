import { useEffect, useState } from 'react'
import Select from 'react-select'

// import Select from '../graphic/Select'

import { getUserGroups } from '../../lib/adminFunctions'
import useUserStore from '../../lib/stores/user'

export default function SelectUserGroup({ value, setValue = () => {}, toggleOwn = false }) {
	const [userGroups, setUserGroups] = useState()
	const userStore = useUserStore()

	useEffect(() => {
		//
		getUserGroups().then((res) => setUserGroups(res))
	}, [])

	useEffect(() => {
		if (toggleOwn && !value && Array.isArray(userGroups) && userStore && userStore.user && userStore.user.userGroup && userStore.user.userGroup.id) {
			const defaultValueIndex = userGroups.findIndex((userGroup) => userGroup._id === userStore.user.userGroup.id)
			let defaultValue

			if (defaultValueIndex !== -1) {
				defaultValue = { value: userGroups[defaultValueIndex]._id, label: `${userGroups[defaultValueIndex].code} (proprio)` }
			}

			if (defaultValue) {
				setValue(defaultValue, defaultValueIndex)
			}
		}
		// eslint-disable-next-line
	}, [value, userGroups, setValue, toggleOwn])

	return (
		<div className="center-it-div" style={{ marginLeft: '5px' }}>
			<div style={{ width: '350px', textAlign: 'start' }}>
				<Select
					selected={value}
					options={
						Array.isArray(userGroups) &&
						userGroups
							.sort((a, b) => a.code.localeCompare(b.code))
							.map((userGroup) => {
								if (toggleOwn) {
									if (userStore && userStore.user && userStore.user.userGroup && userStore.user.userGroup.id === userGroup._id) {
										return { value: userGroup._id, label: `${userGroup.code} (proprio)` }
									}
								}
								return { value: userGroup._id, label: userGroup.code }
							})
					}
					onChange={(option, i, event) => {
						if (toggleOwn && option.value === value && userStore && userStore.user && userStore.user.userGroup && userStore.user.userGroup.id) {
							const defaultValueIndex = userGroups.findIndex((userGroup) => userGroup._id === userStore.user.userGroup.id)
							let defaultValue

							if (defaultValueIndex !== -1) {
								defaultValue = { value: userGroups[defaultValueIndex]._id, label: `${userGroups[defaultValueIndex].code} (proprio)` }
							}

							if (defaultValue) {
								setValue(defaultValue, defaultValueIndex)
							} else {
								setValue(option, i)
							}
						} else {
							setValue(option, i)
						}
					}}
				/>
			</div>
		</div>
	)
}
