import { useEffect, useState } from 'react'

// import ToggleSwitch from '../graphic/ToggleSwitch'
import ContentEditable from '../ContentEditable'

// import useConfigurationStore from '../../lib/stores/configurations'
import useUserStore from '../../lib/stores/user'
// import useDocumentStore from '../../lib/stores/document'

import '../../styles/components/SettingsPanel.scss'

export default function ConfigurationPage() {
	const { getUserGroup, userGroup, uploadDisclaimer } = useUserStore((state) => ({
		user: state.user,
		getUserGroup: state.getUserGroup,
		userGroup: state.userGroup,
		uploadDisclaimer: state.uploadDisclaimer,
	}))
	// const { configurations, setConfiguration, toggleConfiguration } = useConfigurationStore((state) => ({
	// 	configurations: state.configurations,
	// 	setConfiguration: state.setConfiguration,
	// 	toggleConfiguration: state.toggleConfiguration,
	// }))
	// const { setAttributeNames } = useDocumentStore((state) => ({ setAttributeNames: state.setAttributeNames }))

	const [disclaimer, setDisclaimer] = useState('')
	const [updateDisclaimer, setUpdateDisclaimer] = useState(Math.random())

	useEffect(() => {
		getUserGroup()
	}, [getUserGroup])

	useEffect(() => {
		if (userGroup && userGroup.disclaimer) {
			setDisclaimer(userGroup.disclaimer)
			setUpdateDisclaimer(Math.random())
		}
	}, [userGroup])

	return (
		<div className="config-page">
			{/* <div className="config-sidebar">
				<div className="setting-panel">
					<div className="setting-section">
						<h1 className="setting-section-title">Attributi in cui cercare</h1>

						{user && user.changableAttribute && (
							<>
								<div className="setting-option clickable">
									<label className="clickable">Tutti:</label>
									<ToggleSwitch
										value={configurations.allAttributes}
										setValue={(e) => {
											toggleConfiguration('allAttributes')
										}}
									/>
								</div>
								<div className="setting-option">
									<div className="icon-text">
										<div>
											<label>Select attributes</label>
										</div>
										<div className="icon-text-text">
											<select
												onChange={(e) => {
													// console.log(e)

													setConfiguration('attributes', e.target.value)
													setAttributeNames(e.target.value)
												}}
												value={configurations.attributes}
												// value="fghfhghfg"
											>
												{user.changableAttribute &&
													Array.isArray(user.attributeNames) &&
													user.attributeNames.map((attribute, i) => {
														return (
															<option key={i} value={attribute}>
																{attribute}
															</option>
														)
													})}
											</select>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div> */}

			<div className="config-main">
				<div className="config-disclaimer">
					<label className="config-disclaimer-label">Disclaimer</label>

					<ContentEditable
						className="config-disclaimer-text"
						value={disclaimer}
						onInput={(e) => {
							setDisclaimer(e.target.htmlAsText)
							// handleInput('plainText', e.target.htmlAsText)
						}}
						placeholder="Disclaimer..."
						updateValue={updateDisclaimer}
					/>
					<div
						className="config-disclaimer-save shadow-close"
						onClick={() => {
							uploadDisclaimer(disclaimer)
						}}
					>
						Salva
					</div>
				</div>
			</div>
		</div>
	)
}
