import { useState, useRef } from 'react'
import { toast } from 'react-toastify'

import useDocumentStore from '../lib/stores/document'
import useProductsStore from '../lib/stores/products'
import useModalStore from '../lib/stores/modal'
import useUserStore from '../lib/stores/user'
import useQuotesStore from '../lib/stores/quotes'
import useVersionStore from '../lib/stores/version'
import useConfigurationStore from '../lib/stores/configurations'
import { upsertQuote as adminUpsertQuote } from '../lib/adminFunctions'
import checkTokenError from '../lib/checkTokenError'

import ToggleSwitch from './graphic/ToggleSwitch'
import Collapsable from './graphic/Collapsable'

import '../styles/layout/Sidebar.scss'

export default function Sidebar() {
	const [open, setOpen] = useState(false)
	const [find, setFind] = useState('')
	const [replace, setReplace] = useState('')
	const sidebarRef = useRef()
	const componentsRef = useRef()
	const priceListsRef = useRef()
	const attributesRef = useRef()
	const exportConfigsRef = useRef()
	const exportsRef = useRef()
	// const downloadsRef = useRef()
	const configsRef = useRef()
	const utilityRef = useRef()

	// const [displayInfo, setDisplayInfo] = useState({})

	const {
		document,
		addZone,
		addTitle,
		addSubtitle,
		addDescription,
		addHiddenNote,
		addSeparator,
		addShipping,
		addCutList,
		addImageUpload,
		// addDrawSpace,
		addPageBreak,
		addCutDrawing,
		exportText,
		exportMarkdown,
		exportPDFMake,
		exportMaterialList,
		exportMaterialListUnique,
		downloadDocument,
		importDocument,
		hasVersion,
		setElements,
		setAttributeNames,
	} = useDocumentStore((state) => ({
		document: state.document,
		addZone: state.addZone,
		addTitle: state.addTitle,
		addSubtitle: state.addSubtitle,
		addDescription: state.addDescription,
		addHiddenNote: state.addHiddenNote,
		addSeparator: state.addSeparator,
		exportText: state.exportText,
		exportMarkdown: state.exportMarkdown,
		exportPDFMake: state.exportPDFMake,
		exportMaterialList: state.exportMaterialList,
		exportMaterialListUnique: state.exportMaterialListUnique,
		downloadDocument: state.downloadDocument,
		importDocument: state.importDocument,
		addShipping: state.addShipping,
		addCutList: state.addCutList,
		addImageUpload: state.addImageUpload,
		addDrawSpace: state.addDrawSpace,
		addCutDrawing: state.addCutDrawing,
		addPageBreak: state.addPageBreak,
		hasVersion: state.hasVersion,
		setElements: state.setElements,
		setAttributeNames: state.setAttributeNames,
	}))
	const { uploadQuote, replaceQuote } = useQuotesStore((state) => ({
		uploadQuote: state.uploadQuote,
		replaceQuote: state.replaceQuote,
	}))
	const { updateVersion } = useVersionStore((state) => ({
		updateVersion: state.updateVersion,
	}))
	const { priceListsNames, selectedPriceList, setSelectedPriceList } = useProductsStore((state) => ({
		priceListsNames: state.priceListsNames,
		selectedPriceList: state.selectedPriceList,
		setSelectedPriceList: state.setSelectedPriceList,
	}))
	const { configurations, setConfiguration, toggleConfiguration } = useConfigurationStore((state) => ({
		configurations: state.configurations,
		setConfiguration: state.setConfiguration,
		toggleConfiguration: state.toggleConfiguration,
	}))
	const user = useUserStore((state) => state.user)
	const addModal = useModalStore((state) => state.addModal)

	function saveQuote() {
		const id = toast.loading(`Sostituendo ${hasVersion ? 'versione' : 'preventivo'}`)

		// console.log(hasVersion, user, document.userGroupId, document.id)
		if (hasVersion) {
			return updateVersion()
				.then((res) => {
					// console.log(res)

					toast.update(id, {
						render: 'Versione salvata!',
						type: 'success',
						autoClose: 5000,
						isLoading: false,
						closeOnClick: true,
						pauseOnHover: true,
					})
				})
				.catch((e) => {
					console.error(e)

					let message = checkTokenError(e)
					if (!message) message = 'Errore salvando versione'

					toast.update(id, {
						render: message,
						type: 'error',
						autoClose: 5000,
						isLoading: false,
						closeOnClick: true,
						pauseOnHover: true,
					})
				})
		} else if (user && user.authLevel >= 1000 && document.userGroupId) {
			return adminUpsertQuote(document.id ? document.id : null)
				.then((res) => {
					// console.log(res)

					toast.update(id, {
						render: 'Preventivo salvato!',
						type: 'success',
						autoClose: 5000,
						isLoading: false,
						closeOnClick: true,
						pauseOnHover: true,
					})
				})
				.catch((e) => {
					console.error(e)

					let message = checkTokenError(e)
					if (!message) message = 'Errore sostituendo preventivo'

					toast.update(id, {
						render: message,
						type: 'error',
						autoClose: 5000,
						isLoading: false,
						closeOnClick: true,
						pauseOnHover: true,
					})
				})
		} else {
			if (document.id) {
				return replaceQuote(document.id, document.quoteIdentifier ? false : true)
					.then((res) => {
						// console.log(res)

						toast.update(id, {
							render: 'Preventivo sostituito!',
							type: 'success',
							autoClose: 5000,
							isLoading: false,
							closeOnClick: true,
							pauseOnHover: true,
						})
					})
					.catch((e) => {
						console.error(e)

						toast.update(id, {
							render: 'Errore sostituendo preventivo',
							type: 'error',
							autoClose: 5000,
							isLoading: false,
							closeOnClick: true,
							pauseOnHover: true,
						})
					})
			} else {
				return uploadQuote()
					.then((res) => {
						// console.log(res)

						toast.update(id, {
							render: 'Preventivo salvato!',
							type: 'success',
							autoClose: 5000,
							isLoading: false,
							closeOnClick: true,
							pauseOnHover: true,
						})
					})
					.catch((e) => {
						console.error(e)

						let message = checkTokenError(e)
						if (!message) message = 'Errore sostituendo preventivo'

						toast.update(id, {
							render: message,
							type: 'error',
							autoClose: 5000,
							isLoading: false,
							closeOnClick: true,
							pauseOnHover: true,
						})
					})
			}
		}
	}

	function findAndReplace(find, replace) {
		let newDocument = { ...document }

		// console.log(document)
		if (Array.isArray(newDocument && newDocument.elements)) {
			let newElements = newDocument.elements.map((element) => {
				let newElement = { ...element }
				if (newElement.type === 'zone') {
					//
					if (Array.isArray(newElement.items)) {
						newElement.items = newElement.items.map((item) => {
							let newItem = { ...item }

							if (!newItem.product && !newItem.productInfo) {
								let name = newItem.name
								let info = newItem.infoPlainText

								if (typeof info === 'string') {
									// info.replace(/\n/gimu, ' ')
									let regexp = new RegExp(find, 'g')
									newItem.infoPlainText = info.replace(regexp, replace)
									newItem.info = newItem.infoPlainText
									newItem.info = newItem.info.replace(/\n/gimu, '<br />')
								}
								if (typeof name === 'string') {
									let regexp = new RegExp(find, 'g')
									newItem.name = name.replace(regexp, replace)
								}

								if (newItem.name !== item.name || newItem.infoPlainText !== item.infoPlainText) {
									let date = new Date()
									newItem.dataModifiedByFunction = date.toISOString()
								}
							}

							return newItem
						})
					}
				}

				return newElement
			})

			toast.info('Sostituzione avvenuta')

			setElements(newElements)
		}
	}

	function scrollParentToChild(parent, child) {
		// Where is the parent on page
		var parentRect = parent.getBoundingClientRect()
		// What can you see?

		// var parentViewableArea = {
		// 	height: parent.clientHeight,
		// 	width: parent.clientWidth,
		// }

		// Where is the child
		var childRect = child.getBoundingClientRect()
		// Is the child viewable?

		// var isViewable = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parentViewableArea.height

		// if you can't see the child try to scroll parent
		// if (!isViewable) {
		// Should we scroll using top or bottom? Find the smaller ABS adjustment
		const scrollTop = childRect.top - parentRect.top
		const scrollBot = childRect.bottom - parentRect.bottom
		if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
			// we're near the top of the list
			parent.scrollTop += scrollTop
		} else {
			// we're near the bottom of the list
			parent.scrollTop += scrollBot
		}
		// }
	}

	return (
		<>
			<button className={`sidebar-button-mobile ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
					<path d="M21 17.9996V19.9996H3V17.9996H21ZM17.0503 3.5498L22 8.49955L17.0503 13.4493V3.5498ZM12 10.9996V12.9996H3V10.9996H12ZM12 3.99955V5.99955H3V3.99955H12Z"></path>
				</svg>
			</button>
			<aside ref={sidebarRef} className={`sidebar ${open ? 'open' : ''}`}>
				{/* {displayInfo.components && (displayInfo.components.fixed || displayInfo.components.show) && ( */}
				<div ref={componentsRef} className="sidebar-section" id="componenti">
					<h1 className="sidebar-section-title">Componenti</h1>
					<div
						className="sidebar-option option-full shadow-close"
						onClick={
							typeof addZone === 'function'
								? () => {
										addZone()
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-6h2v6z" />
								</svg>
							</div>
							<div className="icon-text-text">Aggiungi Zona</div>
						</div>
					</div>
					<div style={{ width: '100%' }}>
						<Collapsable style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 0px 20px 0px' }}>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addTitle === 'function'
										? () => {
												addTitle()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M13 6v15h-2V6H5V4h14v2z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Titolo</div>
								</div>
							</div>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addSubtitle === 'function'
										? () => {
												addSubtitle()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M10 6v15H8V6H2V4h14v2h-6zm8 8v7h-2v-7h-3v-2h8v2h-3z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Sottotitolo</div>
								</div>
							</div>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addDescription === 'function'
										? () => {
												addDescription()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Descrizione</div>
								</div>
							</div>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addSeparator === 'function'
										? () => {
												addSeparator()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M2 11h2v2H2v-2zm4 0h12v2H6v-2zm14 0h2v2h-2v-2z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Divisore</div>
								</div>
							</div>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addHiddenNote === 'function'
										? () => {
												addHiddenNote()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM12 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-4.473 5a4.5 4.5 0 0 1 8.946 0H7.527z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Note Private</div>
								</div>
							</div>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addCutList === 'function'
										? () => {
												addCutList()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M10 6c0 .732-.197 1.419-.54 2.01L12 10.585l6.728-6.728a2 2 0 0 1 2.828 0l-12.11 12.11a4 4 0 1 1-1.414-1.414L10.586 12 8.032 9.446A4 4 0 1 1 10 6zM8 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0zm13.556 14.142a2 2 0 0 1-2.828 0l-5.317-5.316 1.415-1.415 6.73 6.731zM16 11h2v2h-2v-2zm4 0h2v2h-2v-2zM6 11h2v2H6v-2zm-4 0h2v2H2v-2zm4 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Lista di Taglio</div>
								</div>
							</div>
							{/* <div
								className="sidebar-option shadow-close"
								onClick={
									typeof addDrawSpace === 'function'
										? () => {
												addDrawSpace()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M7.83 20A3.001 3.001 0 1 1 4 16.17V7.83A3.001 3.001 0 1 1 7.83 4h8.34A3.001 3.001 0 1 1 20 7.83v8.34A3.001 3.001 0 1 1 16.17 20H7.83zm0-2h8.34A3.008 3.008 0 0 1 18 16.17V7.83A3.008 3.008 0 0 1 16.17 6H7.83A3.008 3.008 0 0 1 6 7.83v8.34A3.008 3.008 0 0 1 7.83 18zM5 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm14 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM5 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
										</svg>
									</div>
									<div className="icon-text-text">Add Draw Space</div>
								</div>
							</div> */}
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addPageBreak === 'function'
										? () => {
												addPageBreak()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M17 21v-4H7v4H5v-5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v5h-2zM7 3v4h10V3h2v5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3h2zM2 9l4 3-4 3V9zm20 0v6l-4-3 4-3z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Interruzione di Pagina (PDF)</div>
								</div>
							</div>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addCutDrawing === 'function'
										? () => {
												addCutDrawing()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path fill="none" d="M0 0H24V24H0z" />
											<path d="M21 19v2h-2v-2h2zm-4 0v2h-2v-2h2zm-4 0v2h-2v-2h2zm-4 0v2H7v-2h2zm-4 0v2H3v-2h2zm16-4v2h-2v-2h2zM5 15v2H3v-2h2zm0-4v2H3v-2h2zm11-8c2.687 0 4.882 2.124 4.995 4.783L21 8v5h-2V8c0-1.591-1.255-2.903-2.824-2.995L16 5h-5V3h5zM5 7v2H3V7h2zm0-4v2H3V3h2zm4 0v2H7V3h2z" />
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Disegno di Taglio</div>
								</div>
							</div>
							<div
								className="sidebar-option shadow-close"
								onClick={
									typeof addImageUpload === 'function'
										? () => {
												addImageUpload()
										  }
										: () => {
												alert('loading')
										  }
								}
							>
								<div className="icon-text">
									<div className="icon-text-icon">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
											<path d="M2.9918 21C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918ZM20 15V5H4V19L14 9L20 15ZM20 17.8284L14 11.8284L6.82843 19H20V17.8284ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path>
										</svg>
									</div>
									<div className="icon-text-text">Aggiungi Immagine</div>
								</div>
							</div>
						</Collapsable>
					</div>
					<div
						className="sidebar-option option-full shadow-close"
						onClick={
							typeof addShipping === 'function'
								? () => {
										addShipping()
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M8.965 18a3.5 3.5 0 0 1-6.93 0H1V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3l3 4.056V18h-2.035a3.5 3.5 0 0 1-6.93 0h-5.07zM15 7H3v8.05a3.5 3.5 0 0 1 5.663.95h5.674c.168-.353.393-.674.663-.95V7zm2 6h4v-.285L18.992 10H17v3zm.5 6a1.5 1.5 0 1 0 0-3.001 1.5 1.5 0 0 0 0 3.001zM7 17.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
								</svg>
							</div>
							<div className="icon-text-text">Aggiungi Spedizione</div>
						</div>
					</div>
				</div>
				{/* )} */}
				{/* {displayInfo.priceLists && (displayInfo.priceLists.fixed || displayInfo.priceLists.show) && ( */}
				<div ref={priceListsRef} className="sidebar-section" id="listini">
					<h1 className="sidebar-section-title">Listini</h1>
					{/* user user PriceLists, if for some reason there is none, use saved products priceLists */}
					{(Array.isArray(priceListsNames) ? priceListsNames : [])
						.sort((a, b) => typeof a.name === 'string' && typeof b.name === 'string' && a.name.localeCompare(b.name))
						.map((priceList, i) => {
							return (
								<div
									key={i}
									className={`sidebar-option shadow-close ${
										(selectedPriceList && selectedPriceList.name) === priceList.name ? 'active' : ''
									}`}
									onClick={() => {
										if (typeof setSelectedPriceList === 'function') {
											setSelectedPriceList(priceList)
										} else {
											alert('loading')
										}
									}}
								>
									{priceList.name}
								</div>
							)
						})}
				</div>
				{user && user.changableAttribute && (
					<div ref={attributesRef} className="sidebar-section" id="attributes">
						<h1 className="sidebar-section-title">Attributi</h1>
						<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('allAttributes')}>
							<label className="clickable">Tutti:</label>
							<ToggleSwitch
								value={configurations.allAttributes}
								setValue={(e) => {
									// toggleConfiguration('allAttributes')
								}}
							/>
						</div>
						<div className={`sidebar-option shadow-close`}>
							<div className="icon-text">
								<div>
									<label>Select attributes</label>
								</div>
								<div className="icon-text-text">
									<select
										style={{ marginTop: '5px', padding: '5px', fontSize: '18px' }}
										onChange={(e) => {
											setConfiguration('attributeNames', e.target.value)
											setAttributeNames(e.target.value)
										}}
										value={configurations.attributeNames}
										// value="fghfhghfg"
									>
										{user.changableAttribute &&
											Array.isArray(user.attributeNames) &&
											user.attributeNames.map((attribute, i) => {
												return (
													<option style={{ marginTop: '5px', padding: '5px' }} key={i} value={attribute}>
														{attribute}
													</option>
												)
											})}
									</select>
								</div>
							</div>
						</div>
					</div>
				)}
				{/* )} */}

				{/* 
				<div
					className="sidebar-option shadow-close"
					onClick={
						typeof exportMarkdown === 'function'
							? exportMarkdown
							: () => {
									alert('loading')
							  }
					}
				>
					<div className="icon-text">
						<div className="icon-text-icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h16V5H4zm3 10.5H5v-7h2l2 2 2-2h2v7h-2v-4l-2 2-2-2v4zm11-3h2l-3 3-3-3h2v-4h2v4z" />
							</svg>
						</div>
						<div className="icon-text-text">Esporta Markdown</div>
					</div>
				</div>
				 */}

				{/* {displayInfo.exportConfigs && (displayInfo.exportConfigs.fixed || displayInfo.exportConfigs.show) && ( */}
				<div ref={exportConfigsRef} className="sidebar-section" id="export-configs">
					<h1 className="sidebar-section-title">Configurazioni esportazione</h1>

					<div
						className="sidebar-option shadow-close"
						onClick={() => {
							if (configurations.exportSKU && !configurations.exportCod) toggleConfiguration('exportSKU')
							toggleConfiguration('exportCod')
						}}
					>
						<label className="clickable">COD</label>
						<ToggleSwitch
							value={configurations.exportCod}
							setValue={(e) => {
								// toggleConfiguration('exportCod')
							}}
						/>
					</div>

					<div
						className="sidebar-option shadow-close"
						onClick={() => {
							if (configurations.exportCod && !configurations.exportSKU) toggleConfiguration('exportCod')
							toggleConfiguration('exportSKU')
						}}
					>
						<label className="clickable">SKU</label>
						<ToggleSwitch
							value={configurations.exportSKU}
							setValue={(e) => {
								// toggleConfiguration('exportCod')
							}}
						/>
					</div>

					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportRemovePrice')}>
						<label className="clickable">Rimuovi Prezzi</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportRemovePrice}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>

					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportIva')}>
						<label className="clickable">Iva</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportIva}
								setValue={(e) => {
									// toggleConfiguration('exportIva')
								}}
							/>
						</div>
					</div>

					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportDiscountPrices')}>
						<label className="clickable">Esporta sconto</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportDiscountPrices}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>

					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportRemoveID')}>
						<label className="clickable">Rimuovi ID</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportRemoveID}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>

					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportRemoveDate')}>
						<label className="clickable">Rimuovi Data</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportRemoveDate}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportHeader')}>
						<label className="clickable">Intestazione</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportHeader}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportTotalPrice')}>
						<label className="clickable">Totale</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportTotalPrice}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportName')}>
						<label className="clickable">Nome</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportName}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportEmail')}>
						<label className="clickable">Email</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportEmail}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('exportBusiness')}>
						<label className="clickable">Azienda</label>
						<div style={{ margin: '0 auto' }}>
							<ToggleSwitch
								value={configurations.exportBusiness}
								setValue={(e) => {
									// toggleConfiguration('exportRemovePrice')
								}}
							/>
						</div>
					</div>
				</div>
				{/* )} */}
				{/* {displayInfo.exports && (displayInfo.exports.fixed || displayInfo.exports.show) && ( */}
				<div ref={exportsRef} className="sidebar-section" id="exports">
					<h1 className="sidebar-section-title">Esportazioni</h1>
					<div
						className="sidebar-option shadow-close"
						onClick={
							typeof exportText === 'function'
								? async (e) => {
										saveQuote()
										const content = await exportText(e)
										addModal({ modal: content, id: 'textDisplayHtml', background: true })
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M21 8v12.993A1 1 0 0 1 20.007 22H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8zm-2 1h-5V4H5v16h14V9zM8 7h3v2H8V7zm0 4h8v2H8v-2zm0 4h8v2H8v-2z" />
								</svg>
							</div>
							<div className="icon-text-text">Esporta Testo</div>
						</div>
					</div>
					<div
						className="sidebar-option shadow-close"
						onClick={
							typeof exportMarkdown === 'function'
								? (e) => {
										saveQuote()
										exportMarkdown(e)
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h16V5H4zm3 10.5H5v-7h2l2 2 2-2h2v7h-2v-4l-2 2-2-2v4zm11-3h2l-3 3-3-3h2v-4h2v4z" />
								</svg>
							</div>
							<div className="icon-text-text">Esporta Markdown</div>
						</div>
					</div>
					<div
						className="sidebar-option shadow-close"
						onClick={
							typeof exportPDFMake === 'function'
								? async () => {
										saveQuote().then(() => {
											exportPDFMake()
										})
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 16H8V8h4a4 4 0 1 1 0 8zm-2-6v4h2a2 2 0 1 0 0-4h-2zm5-6H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z" />
								</svg>
							</div>
							<div className="icon-text-text">Esporta PDF</div>
						</div>
					</div>
					<div
						className="sidebar-option shadow-close"
						onClick={
							typeof exportMaterialList === 'function'
								? () => {
										saveQuote()
										exportMaterialList()
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 16H8V8h4a4 4 0 1 1 0 8zm-2-6v4h2a2 2 0 1 0 0-4h-2zm5-6H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z" />
								</svg>
							</div>
							<div className="icon-text-text">Esporta Lista materiali</div>
						</div>
					</div>

					<div
						className="sidebar-option shadow-close"
						onClick={
							typeof exportPDFMake === 'function'
								? () => {
										saveQuote()
										exportPDFMake({ drawOnly: true })
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path d="M2.9918 21C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918ZM20 15V5H4V19L14 9L20 15ZM20 17.8284L14 11.8284L6.82843 19H20V17.8284ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path>
								</svg>
							</div>
							<div className="icon-text-text">Esporta Disegni</div>
						</div>
					</div>
					<div
						className="sidebar-option shadow-close"
						onClick={
							typeof exportMaterialListUnique === 'function'
								? () => {
										saveQuote()
										exportMaterialListUnique()
								  }
								: () => {
										alert('loading')
								  }
						}
					>
						<div className="icon-text">
							<div className="icon-text-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 16H8V8h4a4 4 0 1 1 0 8zm-2-6v4h2a2 2 0 1 0 0-4h-2zm5-6H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z" />
								</svg>
							</div>
							<div className="icon-text-text">
								Esporta Lista materiali <b>COD</b>
							</div>
						</div>
					</div>
					<div className="sidebar-section">
						<h1 className="sidebar-section-title">Download / Upload</h1>
						<div
							className="sidebar-option shadow-close"
							onClick={
								typeof downloadDocument === 'function'
									? downloadDocument
									: () => {
											alert('loading')
									  }
							}
						>
							<div className="icon-text">
								<div className="icon-text-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
									</svg>
								</div>
								<div className="icon-text-text">Esporta Preventivo</div>
							</div>
						</div>
						<label htmlFor="uploadDocumentFile" className="sidebar-option shadow-close">
							<div className="icon-text">
								<div className="icon-text-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z" />
									</svg>
								</div>
								<div className="icon-text-text">Importa Preventivo</div>
							</div>
						</label>
						<input
							className="input-file-hidden"
							id="uploadDocumentFile"
							type="file"
							onChange={
								typeof importDocument === 'function'
									? (e) => {
											// console.log(e)
											importDocument(e.target.files[0])
											e.target.value = ''
									  }
									: () => alert('loading')
							}
						/>
					</div>
				</div>
				{/* )} */}
				{/* {displayInfo.configs && (displayInfo.configs.fixed || displayInfo.configs.show) && ( */}
				<div ref={configsRef} className="sidebar-section" id="configs">
					<h1 className="sidebar-section-title">Configurazioni Generali</h1>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('showExtra')}>
						<label className="clickable">Mostra extra (cod + sku)</label>
						<ToggleSwitch
							value={configurations.showExtra}
							setValue={(e) => {
								// toggleConfiguration('exportCod')
							}}
						/>
					</div>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('hideAlert')}>
						<label className="clickable">Nascondi alert prodotti</label>
						<ToggleSwitch
							value={configurations.hideAlert}
							setValue={(e) => {
								// toggleConfiguration('exportCod')
							}}
						/>
					</div>
					{user && user.userType !== 2 && (
						<>
							<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('useOldConfigurator')}>
								<label className="clickable">Usa vecchi dati configuratore</label>
								<ToggleSwitch
									value={configurations.useOldConfigurator}
									setValue={(e) => {
										// toggleConfiguration('exportCod')
									}}
								/>
							</div>
						</>
					)}
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('hideHeaderExtraInfo')}>
						<label className="clickable">Nascondi Azienda, telefono, riferimento</label>
						<ToggleSwitch
							value={configurations.hideHeaderExtraInfo}
							setValue={(e) => {
								// toggleConfiguration('exportCod')
							}}
						/>
					</div>
					<div className="sidebar-option shadow-close" onClick={() => toggleConfiguration('disableDnD')}>
						<label className="clickable">Disabilita Drag and Drop</label>
						<ToggleSwitch
							value={configurations.disableDnD}
							setValue={(e) => {
								// toggleConfiguration('exportCod')
							}}
						/>
					</div>
				</div>
				{/* )} */}
				{/* {displayInfo.utility && (displayInfo.utility.fixed || displayInfo.utility.show) && ( */}
				<div ref={utilityRef} className="sidebar-section" id="utilita">
					<h1 className="sidebar-section-title">Utilità</h1>
					<div className="sidebar-option option-full disable-hover">
						{/* <label className="">Trova e sostituisce</label> */}
						<div className="vertical-input">
							<div className="input-label">Trova</div>
							<input
								value={find}
								placeholder="Trova..."
								onChange={(e) => {
									setFind(e.target.value)
								}}
							/>
							<div className="input-label">Sostituisci</div>
							<input
								value={replace}
								placeholder="Sostituisci..."
								onChange={(e) => {
									setReplace(e.target.value)
								}}
							/>
							<button className="input-button shadow-close" onClick={() => findAndReplace(find, replace)}>
								Sostituisci
							</button>
						</div>
					</div>
				</div>
				{/* )} */}
			</aside>
			<div className="sidebar-nav">
				<div className="sidebar-nav-sticky">
					{/* <a */}
					<span
						href="#componenti"
						onClick={(e) => {
							if (componentsRef && componentsRef.current && sidebarRef && sidebarRef.current) {
								// configsRef.current.scrollIntoView()
								scrollParentToChild(sidebarRef && sidebarRef.current, componentsRef && componentsRef.current)
							}
						}}
					>
						<div
							className="sidebar-link shadow-close"
							// className={`sidebar-link${
							// 	displayInfo.components && (displayInfo.components.fixed || displayInfo.components.show) ? ' active' : ''
							// } shadow-close`}
							// onClick={() => {
							// 	let newDisplayInfo = { ...displayInfo }
							// 	if (!newDisplayInfo.components) newDisplayInfo.components = {}
							// 	newDisplayInfo.components.show = !newDisplayInfo.components.show
							// 	setDisplayInfo(newDisplayInfo)
							// }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M3 3h8v8H3V3zm0 10h8v8H3v-8zM13 3h8v8h-8V3zm0 10h8v8h-8v-8zm2-8v4h4V5h-4zm0 10v4h4v-4h-4zM5 5v4h4V5H5zm0 10v4h4v-4H5z" />
							</svg>
						</div>
						{/* </a> */}
					</span>
					{/* <a */}
					<span
						// href="#listini"
						onClick={(e) => {
							if (priceListsRef && priceListsRef.current && sidebarRef && sidebarRef.current) {
								// configsRef.current.scrollIntoView()
								scrollParentToChild(sidebarRef && sidebarRef.current, priceListsRef && priceListsRef.current)
							}
						}}
					>
						<div
							className="sidebar-link shadow-close"
							// className={`sidebar-link${
							// 	displayInfo.priceLists && (displayInfo.priceLists.fixed || displayInfo.priceLists.show) ? ' active' : ''
							// } shadow-close`}
							// onClick={() => {
							// 	let newDisplayInfo = { ...displayInfo }
							// 	if (!newDisplayInfo.priceLists) newDisplayInfo.priceLists = {}
							// 	newDisplayInfo.priceLists.show = !newDisplayInfo.priceLists.show
							// 	setDisplayInfo(newDisplayInfo)
							// }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm.707 2.122L3.828 12l8.486 8.485 7.778-7.778-1.06-7.425-7.425-1.06zm2.12 6.364a2 2 0 1 1 2.83-2.829 2 2 0 0 1-2.83 2.829z" />
							</svg>
						</div>
						{/* </a> */}
					</span>
					{user && user.changableAttribute && (
						// <a
						<span
							// href="#attributes"
							onClick={(e) => {
								if (attributesRef && attributesRef.current && sidebarRef && sidebarRef.current) {
									// configsRef.current.scrollIntoView()
									scrollParentToChild(sidebarRef && sidebarRef.current, attributesRef && attributesRef.current)
								}
							}}
						>
							<div
								className="sidebar-link shadow-close"
								// className={`sidebar-link${
								// 	displayInfo.attributes && (displayInfo.attributes.fixed || displayInfo.attributes.show) ? ' active' : ''
								// } shadow-close`}
								// onClick={() => {
								// 	let newDisplayInfo = { ...displayInfo }
								// 	if (!newDisplayInfo.attributes) newDisplayInfo.attributes = {}
								// 	newDisplayInfo.attributes.show = !newDisplayInfo.attributes.show
								// 	setDisplayInfo(newDisplayInfo)
								// }}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M3 2h16.005C20.107 2 21 2.898 21 3.99v16.02c0 1.099-.893 1.99-1.995 1.99H3V2zm4 2H5v16h2V4zm2 16h10V4H9v16zm2-4a3 3 0 0 1 6 0h-6zm3-4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm8-6h2v4h-2V6zm0 6h2v4h-2v-4z" />
								</svg>
							</div>
							{/* </a> */}
						</span>
					)}
					{/* <a */}
					<span
						// href="#export-configs"
						onClick={(e) => {
							if (exportConfigsRef && exportConfigsRef.current && sidebarRef && sidebarRef.current) {
								// configsRef.current.scrollIntoView()
								scrollParentToChild(sidebarRef && sidebarRef.current, exportConfigsRef && exportConfigsRef.current)
							}
						}}
					>
						<div
							className="sidebar-link shadow-close"
							// className={`sidebar-link${
							// 	displayInfo.exportConfigs && (displayInfo.exportConfigs.fixed || displayInfo.exportConfigs.show) ? ' active' : ''
							// } shadow-close`}
							// onClick={() => {
							// 	let newDisplayInfo = { ...displayInfo }
							// 	if (!newDisplayInfo.exportConfigs) newDisplayInfo.exportConfigs = {}
							// 	newDisplayInfo.exportConfigs.show = !newDisplayInfo.exportConfigs.show
							// 	setDisplayInfo(newDisplayInfo)
							// }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M2 18h7v2H2v-2zm0-7h9v2H2v-2zm0-7h20v2H2V4zm18.674 9.025l1.156-.391 1 1.732-.916.805a4.017 4.017 0 0 1 0 1.658l.916.805-1 1.732-1.156-.391c-.41.37-.898.655-1.435.83L19 21h-2l-.24-1.196a3.996 3.996 0 0 1-1.434-.83l-1.156.392-1-1.732.916-.805a4.017 4.017 0 0 1 0-1.658l-.916-.805 1-1.732 1.156.391c.41-.37.898-.655 1.435-.83L17 11h2l.24 1.196c.536.174 1.024.46 1.434.83zM18 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
							</svg>
						</div>
						{/* </a> */}
					</span>
					{/* <a */}
					<span
						// href="#exports"
						onClick={(e) => {
							if (exportsRef && exportsRef.current && sidebarRef && sidebarRef.current) {
								// configsRef.current.scrollIntoView()
								scrollParentToChild(sidebarRef && sidebarRef.current, exportsRef && exportsRef.current)
							}
						}}
					>
						<div
							className="sidebar-link shadow-close"
							// className={`sidebar-link${
							// 	displayInfo.exports && (displayInfo.exports.fixed || displayInfo.exports.show) ? ' active' : ''
							// } shadow-close`}
							// onClick={() => {
							// 	let newDisplayInfo = { ...displayInfo }
							// 	if (!newDisplayInfo.exports) newDisplayInfo.exports = {}
							// 	newDisplayInfo.exports.show = !newDisplayInfo.exports.show
							// 	setDisplayInfo(newDisplayInfo)
							// }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
							</svg>
						</div>
						{/* </a> */}
					</span>
					{/* <a */}
					<span
						// href="#configs"
						onClick={(e) => {
							if (configsRef && configsRef.current && sidebarRef && sidebarRef.current) {
								// configsRef.current.scrollIntoView()
								scrollParentToChild(sidebarRef && sidebarRef.current, configsRef && configsRef.current)
							}
						}}
					>
						<div
							className="sidebar-link shadow-close"
							// className={`sidebar-link${
							// 	displayInfo.configs && (displayInfo.configs.fixed || displayInfo.configs.show) ? ' active' : ''
							// } shadow-close`}
							// onClick={() => {
							// 	let newDisplayInfo = { ...displayInfo }
							// 	if (!newDisplayInfo.configs) newDisplayInfo.configs = {}
							// 	newDisplayInfo.configs.show = !newDisplayInfo.configs.show
							// 	setDisplayInfo(newDisplayInfo)
							// }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
							</svg>
						</div>
						{/* </a> */}
					</span>
					{/* <a */}
					<span
						// href="#utilita"
						onClick={(e) => {
							if (utilityRef && utilityRef.current && sidebarRef && sidebarRef.current) {
								// utilityRef.current.scrollIntoView()
								scrollParentToChild(sidebarRef && sidebarRef.current, utilityRef && utilityRef.current)
							}
						}}
					>
						<div
							className="sidebar-link shadow-close"
							// className={`sidebar-link${
							// 	displayInfo.utility && (displayInfo.utility.fixed || displayInfo.utility.show) ? ' active' : ''
							// } shadow-close`}
							// onClick={() => {
							// 	let newDisplayInfo = { ...displayInfo }
							// 	if (!newDisplayInfo.utility) newDisplayInfo.utility = {}
							// 	newDisplayInfo.utility.show = !newDisplayInfo.utility.show
							// 	setDisplayInfo(newDisplayInfo)
							// }}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
							</svg>
						</div>
						{/* </a> */}
					</span>
				</div>
			</div>
		</>
	)
}
