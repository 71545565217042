import create from 'zustand'
import { persist } from 'zustand/middleware'
import axios from 'axios'
import IDBStorage from '../IDBStorage'
import useUserStore from './user'
import documentStore from './document'

let serverUrl = process.env.REACT_APP_SERVER_URL

// export const asyncPersist = (config, options) => {
// 	const { setItem, getItem, removeItem } = options.getStorage()

// 	options.storage = {
// 		setItem: async (...args) => setItem(...args),
// 		getItem: async (...args) => getItem(...args),
// 		// removeItem: async (...args) => removeItem(...args),
// 	}

// 	return persist(config, options)
// }

const uploadQuote = (set, get, name) => {
	const token = useUserStore.getState().token
	const store = documentStore.getState()
	const referer = store.document.referer
	const business = store.document.business
	const email = store.document.email
	const phone = store.document.phone
	const quoteDate = store.document.quoteDate
	const documnetName = store.document.name
	const register = store.register
	const idList = store.idList
	const searchTags = store.document.searchTags
	const updateData = { referer: referer, idList: idList }

	if (register) {
		updateData.register = register
	}
	if (searchTags) {
		updateData.searchTags = searchTags
	}

	if (!name) {
		name = documnetName
	}
	if (!name && !business) {
		name = window.prompt('Inserisci Nome per il preventivo:', '')
		// documentStore.setState({ document: { name: name }, name: name })
	}
	if (name) {
		updateData.name = name
		store.setName(name)
	}
	if (quoteDate !== undefined) {
		updateData.quoteDate = quoteDate
	}

	if (email !== undefined) {
		updateData.email = email
	}
	if (phone !== undefined) {
		updateData.phone = phone
	}
	if (business !== undefined) {
		updateData.business = business
	}

	if (store.status) {
		updateData.status = store.status
	}
	if (store.workStatus) {
		updateData.workStatus = store.workStatus
	}
	if (store.paymentStatus) {
		updateData.paymentStatus = store.paymentStatus
	}
	if (store.duePayment) {
		updateData.duePayment = store.duePayment
	}

	const exportedDcoument = store.exportDocument()
	if (name) exportedDcoument.name = name
	const quote = JSON.stringify(exportedDcoument)
	updateData.quote = quote

	updateData.priceTotal = store.priceTotal

	updateData.quoteIdentifier = undefined

	return axios
		.post(`${serverUrl}/api/quotes/user`, updateData, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			// console.log(res)

			store.overrideDocument(res.data)

			// if (res.data && (res.data.quoteIdentifier || res.data._id)) {
			// 	store.setId(res.data.quoteIdentifier || res.data._id, res.data.quoteIdentifier ? true : false)
			// }
			if (res.data && res.data._id) {
				store.setId(res.data._id, false)
				if (res.data.quoteIdentifier) store.setIdentifier(res.data.quoteIdentifier)
			}
			if (res.data && res.data.name !== null && res.data.name !== undefined) {
				store.setName(res.data.name)
			}
			if (res.data && res.data.referer !== null && res.data.referer !== undefined) {
				store.setReferer(res.data.referer)
			}
			if (res.data && res.data.searchTags !== null && res.data.searchTags !== undefined) {
				store.setSearchTags(res.data.searchTags)
			}

			return true
		})
	// .catch((e) => {
	// 	console.error(e)
	// 	return false
	// })
	// }
}
const replaceQuote = (set, get, id, isId, name) => {
	const token = useUserStore.getState().token
	const store = documentStore.getState()
	const referer = store.document.referer
	const business = store.document.business
	const email = store.document.email
	const phone = store.document.phone
	const quoteDate = store.document.quoteDate
	const documnetName = store.document.name
	const idList = store.idList
	const searchTags = store.document.searchTags
	const quote = JSON.stringify(store.exportDocument())
	const register = store.register
	const data = get()
	const updateData = { quote: quote, referer: referer, idList: idList }

	if (register) {
		updateData.register = register
	}
	if (searchTags) {
		updateData.searchTags = searchTags
	}
	if (!name) {
		name = documnetName
	}
	if (name) {
		updateData.name = name
	}

	if (email !== undefined) {
		updateData.email = email
	}
	if (phone !== undefined) {
		updateData.phone = phone
	}
	if (business !== undefined) {
		updateData.business = business
	}

	if (store.status) {
		updateData.status = store.status
	}
	if (store.workStatus) {
		updateData.workStatus = store.workStatus
	}
	if (store.paymentStatus) {
		updateData.paymentStatus = store.paymentStatus
	}
	if (store.duePayment) {
		updateData.duePayment = store.duePayment
	}

	if (quoteDate !== undefined) {
		updateData.quoteDate = quoteDate
	}

	updateData.priceTotal = store.priceTotal

	updateData.quoteIdentifier = undefined

	return axios
		.put(`${serverUrl}/api/quotes/user/${isId ? '' : 'id/'}${id ? id : ''}`, updateData, {
			headers: {
				authorization: token,
			},
		})
		.then((res) => {
			// console.log(res)
			const quotes = Array.from(data.quotes || [])
			const index = Array.isArray(quotes) && quotes.findIndex((quote) => (isId ? quote._id === id : quote.quoteIdentifier === id))
			if (typeof index === 'number' && index !== -1) {
				quotes[index] = res.data
				set({ quotes: quotes })
			}

			// console.log(res.data)
			store.overrideDocument(res.data)
		})
		.catch((error) => {
			console.error(error)
			throw error
		})
}

//TODO: Sync local document with db one
const initialQuotesStore = {
	quotes: [],
}

// Store for user Document, possible TODO: load document info from server??
const useQuotesStore = create(
	persist(
		(set, get) => ({
			...initialQuotesStore,
			clearStore: () => set({ ...initialQuotesStore }),
			fetchQuotes: ({ page = 0, limit = 50, filters = {}, sorting }) => {
				const token = useUserStore.getState().token

				return (
					axios
						// .get(`${serverUrl}/api/quotes/user`, {
						.post(
							`${serverUrl}/api/quotes/user/filter`,
							{ page: page, limit: limit, filters: filters, sorting: sorting },
							{
								headers: {
									authorization: token,
								},
							}
						)
						.then((res) => {
							let fetchedData = res && res.data
							let quotes = fetchedData && fetchedData.data
							set({ quotes: quotes, totalQuotes: fetchedData.total })
							return true
						})
						.catch((error) => {
							console.error(error)
							set({ quotes: [] })
							throw error
						})
				)
			},
			parseQuote: (quote, isString = true) => {
				try {
					const importObj = isString ? JSON.parse(quote) : quote
					if (importObj.verifyIfIsCorrentKey === 'VerifyKeyQuote') {
						const document = importObj.document
						delete document.id
						delete document.quoteIdentifier
						// document.status = 'pending'

						// set({ ...importObj, quote: null, id: null, quoteIdentifier: null, hasId: null, _id: null, document: document })
						// productsStore.setState({ selectedPriceList: importObj.usedPriceList })
						// return importObj.usedPriceList

						return { ...importObj, document: document, verifyIfIsCorrentKey: 'VerifyKeyQuote' }
					} else {
						alert('Errore nel parse del preventivo')
					}
				} catch (e) {
					console.error('quote parse error', e)
					alert('Errore nel parse del preventivo')
				}
			},
			uploadQuote: (name) => uploadQuote(set, get, name),
			replaceQuote: (id, isId, name) => replaceQuote(set, get, id, isId, name),
			deleteQuote: (id) => {
				const token = useUserStore.getState().token
				const data = get()

				axios
					.delete(`${serverUrl}/api/quotes/user/${id}`, {
						headers: {
							authorization: token,
						},
					})
					.then((res) => {
						// console.log(res)
						const quotes = Array.from(data.quotes || [])
						const index = Array.isArray(quotes) && quotes.findIndex((quote) => quote._id === id)
						if (typeof index === 'number' && index !== -1) {
							quotes.splice(index, 1)
							set({ quotes: quotes })
						}
						return true
					})
				// .catch((e) => {
				// 	console.error(e)
				// 	return false
				// })
			},
			deleteManyQuotes: (ids) => {
				const token = useUserStore.getState().token
				let data = get()

				return axios
					.delete(`${serverUrl}/api/quotes/user`, {
						headers: {
							authorization: token,
						},
						data: {
							ids: ids,
						},
					})
					.then((res) => {
						if (res.data) {
							const quotes = Array.from(data.quotes || [])
							ids.forEach((id) => {
								// registers.push(res.data)
								const index = quotes.findIndex((quote) => quote._id === id)
								if (index !== -1) {
									quotes.splice(index, 1)
								}
							})
							set({ quotes: quotes })
							return 'Success'
						}
					})
				// .catch((error) => {
				// 	console.error(error)
				// 	throw error
				// })
			},
			_hasHydrated: false,
		}),
		{
			name: 'quotes-storage',
			getStorage: () => IDBStorage,
			onRehydrateStorage: () => () => {
				useQuotesStore.setState({ _hasHydrated: true })
			},
		}
	)
)

export default useQuotesStore
