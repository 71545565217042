import { useEffect, useRef, useState } from 'react'

export default function Collapsable({ defaultState = true, children, style, className }) {
	const [collapsed, setCollapsed] = useState(defaultState)
	const [offsetHeight, setOffsetHeight] = useState(0)
	const contentRef = useRef()

	useEffect(() => {
		if (contentRef && contentRef.current) {
			setOffsetHeight(contentRef.current.offsetHeight)
		}
	}, [contentRef])

	// console.log(offsetHeight, contentRef)

	return (
		<div className={`collapsable ${collapsed ? 'closed' : 'open'}`}>
			<div className="collapsable-toggle shadow-close" onClick={() => setCollapsed(!collapsed)}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28">
					<path fill="none" d="M0 0h24v24H0z" />
					<path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
				</svg>
			</div>
			<div className={`collapsable-content`} style={{ maxHeight: collapsed ? 0 : offsetHeight }}>
				<div style={style} className={className} ref={contentRef}>
					{children}
				</div>
			</div>
		</div>
	)
}
