import { useNavigate, useLocation, Link } from 'react-router-dom'

import useUserStore from '../lib/stores/user'
// import useModalStore from '../lib/stores/modal'

import '../styles/layout/Header.scss'

export default function Header() {
	// const addModal = useModalStore((state) => state.addModal)
	const { logout, user } = useUserStore((state) => ({ logout: state.logout, user: state.user }))
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<header className="header">
			<div className="header-menu">
				<nav className="header-left">
					<div className={`header-item${location.pathname === '/' ? ' selected' : ''}`}>
						<Link to={`/`}>
							<div className={`header-link${location.pathname === '/' ? ' active' : ''}`}>Home</div>
						</Link>
					</div>
					<div className={`header-item${location.pathname === '/preventivi' ? ' selected' : ''}`}>
						<Link to={`/preventivi`}>
							<div className={`header-link${location.pathname === '/preventivi' ? ' active' : ''}`}>Preventivi</div>
						</Link>
					</div>
					{/* <div className="header-item">
						<div
							className="header-link"
							onClick={() => {
								addModal({ modal: <RegistersList />, id: 'RegistersListModal', background: true, replaceModal: false })
							}}
						>
							Clienti
						</div>
					</div> */}
					{user && user.authLevel >= 1000 && (
						<div className={`header-item${location.pathname === '/admin/users' ? ' selected' : ''}`}>
							<Link to={`/admin/users`}>
								<div className={`header-link${location.pathname === '/admin/users' ? ' active' : ''}`} onClick={() => navigate('/admin/users')}>
									Utenti
								</div>
							</Link>
						</div>
					)}
					{user && user.authLevel >= 750 && (
						<div className={`header-item${location.pathname === '/admin/quotes' ? ' selected' : ''}`}>
							<Link to={`/admin/quotes`}>
								<div
									className={`header-link${location.pathname === '/admin/quotes' ? ' active' : ''}`}
									onClick={() => navigate('/admin/quotes')}
								>
									Tutti i preventivi
								</div>
							</Link>
						</div>
					)}
					<div className={`header-item${location.pathname === '/config' ? ' selected' : ''}`}>
						<Link to={`/config`}>
							<div className={`header-link${location.pathname === '/config' ? ' active' : ''}`}>
								Configurazioni
								{/* <svg style={{ padding: '10px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="52" height="52">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M8.686 4l2.607-2.607a1 1 0 0 1 1.414 0L15.314 4H19a1 1 0 0 1 1 1v3.686l2.607 2.607a1 1 0 0 1 0 1.414L20 15.314V19a1 1 0 0 1-1 1h-3.686l-2.607 2.607a1 1 0 0 1-1.414 0L8.686 20H5a1 1 0 0 1-1-1v-3.686l-2.607-2.607a1 1 0 0 1 0-1.414L4 8.686V5a1 1 0 0 1 1-1h3.686zM6 6v3.515L3.515 12 6 14.485V18h3.515L12 20.485 14.485 18H18v-3.515L20.485 12 18 9.515V6h-3.515L12 3.515 9.515 6H6zm6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
								</svg> */}
							</div>
						</Link>
					</div>
					{user && user.authLevel >= 1000 && (
						<div className={`header-item${location.pathname === '/admin/analytics' ? ' selected' : ''}`}>
							<Link to={`/admin/analytics`}>
								<div
									className={`header-link${location.pathname === '/admin/analytics' ? ' active' : ''}`}
									onClick={() => navigate('/admin/analytics')}
								>
									Analytics
								</div>
							</Link>
						</div>
					)}
				</nav>
				<div className="header-right">
					{user && user.email && (
						<div className="header-item">
							<div className="header-button not-hoverable" style={{ cursor: 'auto' }}>
								{user.email}
							</div>
						</div>
					)}
					<div className="header-item selected">
						<div className="header-button active" onClick={() => logout()}>
							<span className="logout-text">Esci</span>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}
