import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { round } from 'mathjs'
import Select from 'react-select'

import Pagination from './Pagination'
import Filters from './Filters'
import Loading from './Loading'
import SelectUserGroup from '../elements/SelectUserGroup'

import useDocumentStore from '../../lib/stores/document'
import { getQuotes as adminGetQuotes, getUserGroups, getUsers, deleteQuote as adminDeleteQuote } from '../../lib/adminFunctions'

import '../../styles/components/List.scss'

export default function QuotesList() {
	const [quotes, setQuotes] = useState([])
	const [quotesTotal, setQuotesTotal] = useState(1)
	const [userGroups, setUserGroups] = useState([])
	const [users, setUsers] = useState([])

	const { clearStore, overrideDocument } = useDocumentStore((state) => ({
		clearStore: state.clearStore,
		overrideDocument: state.overrideDocument,
	}))
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)

	// Pagination Info
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(50)

	// Filters info
	const [userGroupId, setUserGroupId] = useState()

	const [searchString, setSearchString] = useState('')
	const [searchStringFilter, setSearchStringFilter] = useState('')
	const setFilterDeb = useRef()

	const [sortingInfo, setSortingInfo] = useState({})

	// Fetch userGroups on load
	useEffect(() => {
		getUserGroups().then((res) => {
			setUserGroups(res)
		})

		getUsers().then((res) => {
			setUsers(res)
		})

		return () => {
			clearTimeout(setFilterDeb && setFilterDeb.current)
		}
	}, [])

	useEffect(() => {
		if (setFilterDeb && setFilterDeb.current) clearTimeout(setFilterDeb.current)
		setFilterDeb.current = setTimeout(() => {
			setSearchStringFilter(searchString)
		}, 750)
	}, [searchString])

	// fetch quotes with filters, and pagination
	useEffect(() => {
		let extrafilters = []

		if (searchStringFilter.length > 0) {
			let userFilter = users
				.filter(
					(user) =>
						user?.slug?.toLowerCase().includes(searchStringFilter.toLowerCase()) ||
						user?.email?.toLowerCase().includes(searchStringFilter.toLowerCase())
				)
				.map((user) => user._id)
			extrafilters.push({ user_id: { $in: userFilter } })
			let userGroupFilter = userGroups
				.filter((userGroup) => userGroup?.code?.toLowerCase().includes(searchStringFilter.toLowerCase()))
				.map((userGroup) => userGroup._id)

			extrafilters.push({ userGroup_id: { $in: userGroupFilter } })
		}

		// console.log(users, userGroups, extrafilters)

		let completeId = ''
		let isCompleteId = false
		if (/^([^-\n ]+)-(\d+)-(\d+)$/gm.test(searchStringFilter)) {
			let matches = [...searchStringFilter.matchAll(/^([^-\n ]+)-(\d+)-(\d+)$/gm)]
			isCompleteId = true
			completeId = matches[0][3].toString()
		}

		let filter = {
			$or: [
				{ name: { $regex: searchStringFilter, $options: 'i' } },
				{ referer: { $regex: searchStringFilter, $options: 'i' } },
				{ business: { $regex: searchStringFilter, $options: 'i' } },
				{ email: { $regex: searchStringFilter, $options: 'i' } },
				{ phone: { $regex: searchStringFilter, $options: 'i' } },
				{ quoteIdentifier: { $regex: isCompleteId ? completeId : searchStringFilter, $options: 'i' } },
				{ idList: searchStringFilter },
				...extrafilters,
			],
		}

		// console.log(filter)

		if (!searchStringFilter) {
			filter = { userGroup_id: userGroupId }
		} else {
			filter.userGroup_id = userGroupId
		}

		setLoading(true)
		adminGetQuotes({ page: currentPage, limit: pageSize, filters: filter, sorting: sortingInfo }).then((res) => {
			setLoading(false)
			// setQuotes(res)

			let quotesFetched = res && res.data
			if (Array.isArray(quotesFetched)) setQuotes(quotesFetched)
			setQuotesTotal(res && res.total)
		})
	}, [currentPage, pageSize, searchStringFilter, userGroupId, sortingInfo, users, userGroups])

	return (
		<div className="list-list">
			<div className="list-actions">
				<div
					className="action-button"
					onClick={() => {
						let extrafilters = []

						if (searchStringFilter.length > 0) {
							let userFilter = users
								.filter(
									(user) =>
										user?.slug?.toLowerCase().includes(searchStringFilter.toLowerCase()) ||
										user?.email?.toLowerCase().includes(searchStringFilter.toLowerCase())
								)
								.map((user) => user._id)
							extrafilters.push({ user_id: { $in: userFilter } })
							let userGroupFilter = userGroups
								.filter((userGroup) => userGroup?.code?.toLowerCase().includes(searchStringFilter.toLowerCase()))
								.map((userGroup) => userGroup._id)

							extrafilters.push({ userGroup_id: { $in: userGroupFilter } })
						}

						let filter = {
							$or: [
								{ name: { $regex: searchStringFilter, $options: 'i' } },
								{ referer: { $regex: searchStringFilter, $options: 'i' } },
								{ business: { $regex: searchStringFilter, $options: 'i' } },
								{ email: { $regex: searchStringFilter, $options: 'i' } },
								{ phone: { $regex: searchStringFilter, $options: 'i' } },
								{ quoteIdentifier: { $regex: searchStringFilter, $options: 'i' } },
								{ idList: searchStringFilter },
								...extrafilters,
							],
						}

						if (!searchStringFilter) {
							filter = { userGroup_id: userGroupId }
						} else {
							filter.userGroup_id = userGroupId
						}

						setLoading(true)
						adminGetQuotes({ page: currentPage, limit: pageSize, filters: filter }).then((res) => {
							setLoading(false)
							// setQuotes(res)

							let quotesFetched = res && res.data
							if (Array.isArray(quotesFetched)) setQuotes(quotesFetched)
							setQuotesTotal(res && res.total)
						})

						getUserGroups().then((res) => {
							setUserGroups(res)
						})
					}}
				>
					Sincronizza
				</div>
				<div
					className="action-button"
					onClick={() => {
						clearStore()
						// uploadQuote()
						navigate('/')
						// fetchQuotes()
					}}
				>
					Nuovo
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Filters data={quotes} searchString={searchString} setSearchString={setSearchString} />
				<div>
					{/* <Select /> */}
					<SelectUserGroup
						value={userGroupId}
						setValue={(option, index) => {
							if (userGroupId === option.value) {
								setUserGroupId(undefined)
							} else {
								setUserGroupId(option.value)
							}
						}}
					/>
				</div>
			</div>
			<Pagination
				// className="pagination-bar"
				currentPage={currentPage}
				totalCount={quotesTotal || 1}
				// totalCount={(filteredData && filteredData.length) || 1}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPageChange={(page) => setCurrentPage(page)}
			/>

			<div className="list-row row-header">
				<div className="list-column item-medium">Utente | gruppo</div>
				<div
					className="list-column item-small"
					onClick={() => {
						let newSorting = {}
						if (!sortingInfo.quoteIdentifier) {
							newSorting.quoteIdentifier = -1
						} else if (sortingInfo.quoteIdentifier === -1) {
							newSorting.quoteIdentifier = 1
						}
						// else {
						// 	delete newSorting.quoteIdentifier
						// }

						setSortingInfo(newSorting)
					}}
				>
					ID
					<span className="sorting-icon">
						{!sortingInfo.quoteIdentifier ? (
							<FontAwesomeIcon icon={faSort} />
						) : sortingInfo.quoteIdentifier === 1 ? (
							<FontAwesomeIcon icon={faSortUp} />
						) : (
							<FontAwesomeIcon icon={faSortDown} />
						)}
					</span>
				</div>
				<div className="list-column item-medium">Azienda / Nome</div>
				<div className="list-column item-medium">Riferimento / Email</div>
				<div
					className="list-column item-medium"
					onClick={() => {
						let newSorting = {}
						if (!sortingInfo.updatedAt) {
							newSorting.updatedAt = -1
						} else if (sortingInfo.updatedAt === -1) {
							newSorting.updatedAt = 1
						}
						// else {
						// 	delete newSorting.updatedAt
						// }

						setSortingInfo(newSorting)
					}}
				>
					Data ultima modifica
					<span className="sorting-icon">
						{!sortingInfo.updatedAt ? (
							<FontAwesomeIcon icon={faSort} />
						) : sortingInfo.updatedAt === 1 ? (
							<FontAwesomeIcon icon={faSortUp} />
						) : (
							<FontAwesomeIcon icon={faSortDown} />
						)}
					</span>
				</div>
				<div
					className="list-column item-medium"
					onClick={() => {
						let newSorting = {}
						if (!sortingInfo.createdAt) {
							newSorting.createdAt = -1
						} else if (sortingInfo.createdAt === -1) {
							newSorting.createdAt = 1
						}
						// else {
						// 	delete newSorting.createdAt
						// }

						setSortingInfo(newSorting)
					}}
				>
					Data creazione
					<span className="sorting-icon">
						{!sortingInfo.createdAt ? (
							<FontAwesomeIcon icon={faSort} />
						) : sortingInfo.createdAt === 1 ? (
							<FontAwesomeIcon icon={faSortUp} />
						) : (
							<FontAwesomeIcon icon={faSortDown} />
						)}
					</span>
				</div>
				<div className="list-column item-medium">Status</div>
				<div className="list-column item-medium">Totale</div>
				{/* <div className="list-column item-small">Apri</div> */}
				<div className="list-column item-small">Elimina</div>
			</div>
			{loading ? (
				<Loading />
			) : (
				Array.isArray(quotes) &&
				quotes.map((quote, i) => {
					let date
					// if (quote.quoteDate) {
					// 	date = new Date(quote.quoteDate)
					// } else
					if (quote.updatedAt) {
						date = new Date(quote.updatedAt)
					} else {
						date = 'Sconosciuto'
					}

					let creationDate
					if (quote.createdAt) {
						creationDate = new Date(quote.createdAt)
					} else {
						creationDate = 'Sconosciuto'
					}

					return (
						<div key={quote._id} className="list-row">
							<div className="list-column item-medium">
								<div className="list-column-label">Utente | gruppo</div>
								<div>
									{(Array.isArray(users) &&
										users.find((user) => user._id === quote.user_id) &&
										users.find((user) => user._id === quote.user_id).slug) ||
										'Nessuno'}
									{(Array.isArray(userGroups) &&
										userGroups.find((group) => group._id === quote.userGroup_id) &&
										` | ${userGroups.find((group) => group._id === quote.userGroup_id).code}`) ||
										''}
								</div>
							</div>
							<div className="list-column item-small">
								<div className="list-column-label">ID</div>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										overrideDocument(quote)
										navigate('/')
									}}
								>
									{quote.quoteIdentifier.replace(/^0+/, '')}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Nome</div>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										overrideDocument(quote)
										navigate('/')
									}}
								>
									{quote.business ? `${quote.business}${quote.name ? ' / ' : ''}` : ''}
									{quote.name}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Riferimento</div>
								<div>
									{quote.referer ? `${quote.referer}${quote.email ? ' / ' : ''}` : ''}
									{quote.email}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Data Ultima modifica</div>
								<div>{typeof date === 'string' ? date : `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Data Creazione</div>
								<div>
									{typeof creationDate === 'string'
										? creationDate
										: `${creationDate.getDate()} / ${creationDate.getMonth() + 1} / ${creationDate.getFullYear()}`}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Riferimento</div>
								<div>{quote.status}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Totale</div>
								<div>{!isNaN(quote.priceTotal) ? round(quote.priceTotal, 2) + ' €' : '0 €'}</div>
							</div>

							<div
								className="list-column item-small item-clickable"
								onClick={() => {
									const r = window.confirm(`Sei sicuro di voler cancellare il preventivo ${quote.name}, riferimento: ${quote.referer}?`)

									if (r) {
										adminDeleteQuote(quote._id).then(() => {
											let newQuotes = quotes.filter((item) => item._id !== quote._id)
											setQuotes(newQuotes)
										})
									}
								}}
							>
								Elimina
							</div>
							{/* <div
								className="list-column item-small item-clickable"
								onClick={() => {
									replaceDocument(quote._id, true)
									navigate('/')
									// const r = window.confirm(`Vuoi sovrascrivere il quote?`)

									// if (r) {
									// 	replaceQuote(quote.quoteIdentifier ? quote.quoteIdentifier : quote._id, quote.quoteIdentifier ? false : true)
									// }
								}}
							>
								Duplica
							</div>
							<div
								className="list-column item-small hoverable item-clickable"
								style={{ userSelect: 'none' }}
								onClick={(e) => {
									// e.preventDefault()
									if (selectedList[quote._id]) {
										delete selectedList[quote._id]
									} else {
										selectedList[quote._id] = true
									}
									setSelectedList({ ...selectedList })
								}}
							>
								<input type="checkbox" checked={selectedList[quote._id] || false} onChange={() => {}} />
							</div> */}
						</div>
					)
				})
			)}
		</div>
	)
}
