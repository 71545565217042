import { useEffect, useState } from 'react'
import axios from 'axios'

import useDocumentStore from '../../lib/stores/document'

async function DownloadFilesList(cods, setData) {
	let res = await axios.post(`https://backend.aeoss.lighting/api/files/list`, { cods: cods }, {})

	console.log(res)

	setData(res && res.data)

	return res
}

export default function FilesDownload() {
	const { document } = useDocumentStore((state) => ({ document: state.document }))
	// const data = []
	const [data, setData] = useState([])
	const [selected, setSelected] = useState([])
	const [cods, setCods] = useState([])
	// const [products, setProducts] = useState([])

	useEffect(() => {
		// console.log(document)

		const products = []

		if (Array.isArray(document && document.elements)) {
			document.elements.forEach((element) => {
				if (element.type === 'zone') {
					if (Array.isArray(element.items)) {
						element.items.forEach((item) => {
							if (item.product) {
								products.push(item.product)
							}
						})
					}
				}
			})
		}

		let newCods = products.map((product) => product.cod)
		setCods(newCods)

		// console.log(products)
	}, [document])

	useEffect(() => {
		if (Array.isArray(cods) && cods.length > 0) {
			DownloadFilesList(cods, setData)
		}
	}, [cods])

	useEffect(() => {
		setSelected(Array(data.length).fill(false))
	}, [data])

	return (
		<div>
			<button
				onClick={(event) => {
					// console.log(selected)

					let selectedItems = Array.isArray(data) && data.filter((item, i) => selected[i])

					console.log(selectedItems)
				}}
			>
				Scarica Files
			</button>
			<table>
				<thead>
					<tr>
						<th>Selezione</th>
						<th>Nome</th>
						<th>Nome file</th>
						<th>Descrizione</th>
						<th>Percorso</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(data) &&
						data.map((item, i) => {
							return (
								<tr key={i}>
									<td
										style={{ userSelect: 'none' }}
										onClick={() => {
											let newSelected = [...selected]

											newSelected[i] = !newSelected[i]

											setSelected(newSelected)
										}}
									>
										{selected[i] ? 'V' : 'X'}
									</td>
									<td>{item.name}</td>
									<td>{item.originalName}</td>
									<td>{item.description}</td>
									<td>{item.pathname}</td>
								</tr>
							)
						})}
				</tbody>
			</table>
		</div>
	)
}
