import { useMemo, useState } from 'react'

export default function Strip({ strips, stepSelect = () => {}, selected, collapsed, setCollapsed = () => {}, attributeInfo, selectedPriceList, data }) {
	// console.log(attributeInfo)

	let [filterString, setFilterString] = useState('')

	let stripsData = useMemo(() => {
		if (Array.isArray(strips)) {
			return strips.filter((strip) => {
				let stripName = ''
				// let stripDescription = ''
				switch (attributeInfo) {
					case 'led_pro':
						if (strip) {
							stripName = typeof strip.name_ledpro === 'string' ? strip.name_ledpro : strip.name
							// stripDescription = typeof strip.description_ledpro === 'string' ? strip.description_ledpro : strip.description
						}
						break
					case 'aeoss':
						if (strip) {
							stripName = strip.name
							// stripDescription = strip.description
						}
						break
					default:
						if (strip) {
							stripName = strip.name
							// stripDescription = strip.description
						}
				}

				let returnValue = false

				if (typeof stripName === 'string' && stripName !== '') {
					returnValue = stripName.toLowerCase().includes(filterString.toLowerCase()) ? true : false
				}
				if (!returnValue && typeof strip.cod === 'string') {
					returnValue = strip.cod.toLowerCase().includes(filterString.toLowerCase()) ? true : false
				}
				if (!returnValue) {
					let matches = [...filterString.matchAll(/(\d+[.,]?\d*)[ ]?W/gimu)]

					if (Array.isArray(matches) && matches.length > 0) {
						let number = matches[0][1]

						if (parseFloat(number.replace(',', '.')) === strip.power_m) {
							returnValue = true
						}
					}
				}

				return returnValue
			})
		} else {
			return []
		}
	}, [filterString, strips, attributeInfo])

	return (
		<>
			<div className="configurator-input-box">
				<input
					type="search"
					className="configurator-input"
					value={filterString}
					id={`configuratorStripFilter`}
					onInput={(e) => {
						setFilterString(e.target.value)
					}}
				/>
				<label htmlFor={`configuratorStripFilter`} className="configurator-input-label">
					Filtro
				</label>
			</div>
			<div className="step-strip-list">
				{Array.isArray(stripsData) &&
					stripsData.map((strip, i) => {
						let cssClass = ''
						if (selected) {
							if (selected.slug) {
								if (selected.slug === strip.slug) {
									cssClass = 'selected'
								}
							} else {
								if (selected.cod === strip.cod) {
									cssClass = 'selected'
								}
							}
						}

						if (!cssClass) {
							if (collapsed) {
								cssClass = 'collapsed'
							} else {
								cssClass += 'not-collapsed'
							}
						} else {
							cssClass += ' not-collapsed'
						}

						let stripName = ''
						let stripDescription = ''
						switch (attributeInfo) {
							case 'led_pro':
								if (strip) {
									stripName = typeof strip.name_ledpro === 'string' ? strip.name_ledpro : strip.name
									stripDescription = typeof strip.description_ledpro === 'string' ? strip.description_ledpro : strip.description
								}
								break
							case 'aeoss':
								if (strip) {
									stripName = strip.name
									stripDescription = strip.description
								}
								break
							default:
								if (strip) {
									stripName = strip.name
									stripDescription = strip.description
								}
						}

						let price = strip.price

						if (selectedPriceList && data && data.configs && data.configs.priceModifiers) {
							if (data.configs.priceModifiers[selectedPriceList.pricelist_id]) {
								let mult = data.configs.priceModifiers[selectedPriceList.pricelist_id].multiplier
								let priceModifier = parseFloat(mult)
								if (!isNaN(priceModifier)) {
									price = price * priceModifier
								}
							}
						}

						return (
							<div
								key={i}
								className={`step-strip-option ${cssClass}`}
								style={{ '--animation-delay': 100 + 50 * i + 'ms' }}
								onClick={() => {
									// setCollapsed(true)
									stepSelect(strip, strip.name)
								}}
							>
								<div className="strip-option-image">
									<img
										src={
											strip.image
												? `./images/strips/${strip.image}`
												: 'https://store.ledpro.it/media/img/custom-strips/images/led-strips/h-power-image.png'
										}
										alt={strip.imageAlt}
									/>
									{/* <img src="https://store.ledpro.it/media/img/custom-strips/images/led-strips/h-power-image.png" alt={strip.imageAlt} /> */}
								</div>
								<div className="strip-option-details">
									<h2 className="strip-details-title">
										{strip.made_in_italy && (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="1500"
												height="1000"
												viewBox="0 0 3 2"
												style={{ width: '30px', height: '18px', marginRight: '8px' }}
											>
												<path fill="#009246" d="M0 0H3V2H0z"></path>
												<path fill="#fff" d="M1 0H3V2H1z"></path>
												<path fill="#ce2b37" d="M2 0H3V2H2z"></path>
											</svg>
										)}
										{stripName}
									</h2>
									{/* {strip.made_in_italy && (
											<div className="strip-made-in-italy">
												<div></div>
												<div></div>
												<div></div>
												<div>Made in Italy</div>
											</div>
										)} */}

									<div className="strip-details-description">{stripDescription}</div>
									<div className="strip-details-power">
										{strip.power_m}
										<span className="details-power-unit">W/m</span> - {strip.volt}
										<span className="details-power-unit">V</span>
									</div>
									<div className="strip-details-colors">
										{Array.isArray(strip.colors) &&
											strip.colors.map((color, i) => {
												let colorString = typeof color === 'object' && color ? color.slug : color

												return <div key={i} className={`strip-color-option color-${colorString}`}></div>
											})}
									</div>
									{/* <ul>
									<li>taglio: {(strip.cut / 10).toFixed(2)}cm</li>
									<li>
										watt/m: {strip.power_m} lumen/m: {strip.lumen_m}
									</li>
									<li>taglio: {strip.cut}</li>
								</ul> */}
								</div>
								<div className="strip-option-price">
									{price.toFixed(1).replace(/\.0+$/, '')}€<span className="strip-option-price-unit">/m</span>
								</div>
							</div>
						)
					})}
			</div>
		</>
	)
}
