import { useState } from 'react'

import ContentEditable from '../ContentEditable'
import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'

import useDocumentStore from '../../lib/stores/document'

export default function Description({ description, elementIndex, provided }) {
	const setElement = useDocumentStore((state) => state.setElement)
	const [updateValueKey, setUpdateValueKey] = useState(Math.random())

	function handleInput(value, htmlText) {
		if (typeof setElement === 'function') {
			const element = { ...description }
			element.description = value
			element.plainText = htmlText
			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	return (
		<div className="element">
			<ContentEditable
				className="element-description element-full shadow"
				value={description.description || ''}
				onInput={(e) => {
					handleInput(e.target.value, e.target.htmlAsText)
					// handleInput('plainText', e.target.htmlAsText)
				}}
				placeholder="Descrizione..."
				updateValue={updateValueKey}
			/>
			{provided && <DragElement provided={provided} />}
			<RemoveElement element={description} elementIndex={elementIndex} />
			<div
				className="element-action icon-hover"
				style={{ position: 'absolute', right: 0, transform: `translate(40%, -50%)` }}
				onClick={(e) => {
					// console.log(description.description, description.plainText)
					const element = { ...description }
					element.description = typeof element.plainText === 'string' ? element.plainText.replace(/[\n\r]/gimu, '<br />') : ''
					setElement(element, elementIndex)
					setUpdateValueKey(Math.random())
				}}
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
					<path fill="none" d="M0 0h24v24H0z" />
					<path d="M16.536 15.95l2.12-2.122-3.181-3.182 3.535-3.535-2.12-2.121-3.536 3.535-3.182-3.182L8.05 7.464l8.486 8.486zm-1.415 1.414L6.636 8.879l-2.828 2.828 8.485 8.485 2.828-2.828zM13.354 5.697l2.828-2.829a1 1 0 0 1 1.414 0l3.536 3.536a1 1 0 0 1 0 1.414l-2.829 2.828 2.475 2.475a1 1 0 0 1 0 1.415L13 22.314a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414l7.778-7.778a1 1 0 0 1 1.415 0l2.475 2.475z" />
				</svg>
			</div>
		</div>
	)
}
