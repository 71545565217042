import { useState } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { toast } from 'react-toastify'

import FormEntry from '../graphic/FormEntry'

import useRegistersStore from '../../lib/stores/registers'

import '../../styles/components/Form.scss'
import useProductsStore from '../../lib/stores/products'

export default function RegisterForm({ registerData = {}, setRegisterData, mode = 'new' }) {
	const [register, setRegister] = useState(cloneDeep(registerData))
	const { registerRegister, editRegister, getRegister } = useRegistersStore((state) => ({
		registerRegister: state.registerRegister,
		editRegister: state.editRegister,
		getRegister: state.getRegister,
	}))
	const { priceListsNames } = useProductsStore((state) => ({ priceListsNames: state.priceListsNames }))

	function setRegisterAttribute(key, value) {
		const registerCopy = { ...register }
		registerCopy[key] = value
		setRegister(registerCopy)
	}

	// Submit button label
	function modeToLabel(mode) {
		switch (mode) {
			case 'edit':
				return 'Modifica'
			case 'new':
				return 'Crea'
			case 'editLocal':
				return 'Modifica'
			default:
				return mode
		}
	}

	// Preview address label
	function addressLabel(address) {
		let label = ''
		if (address.street) {
			label += address.street
		}
		if (address.streetNumber) {
			label += ' '
			label += address.streetNumber
		}
		if (address.zipcode) {
			label += ' - '
			label += address.zipcode
		}
		if (address.city) {
			label += ' '
			label += address.city
		}
		if (address.state) {
			label += ' ('
			label += address.state

			label += ')'
		}
		if (address.country) {
			label += ' - '
			label += address.country
		}

		return label || 'no address'
	}

	const required = {}
	switch (mode) {
		case 'new':
			required.firstname = true
			required.lastname = true
			required.email = true
			break
		case 'edit':
			required.firstname = true
			required.lastname = true
			required.email = true
			break
		default:
	}

	return (
		<div className="form-page">
			<form
				className="form-form"
				onSubmit={(e) => {
					e.preventDefault()

					let message = ''

					if (message !== '') {
						alert(message)
						return
					}

					let uploadData = { ...register }
					if (uploadData.defaultPriceList === '' || uploadData.defaultPriceList === 'noSelect') {
						delete uploadData.defaultPriceList
					}

					let id

					// perform action, creating toast modal to notify user
					switch (mode) {
						case 'new':
							id = toast.loading('Salvando Anagrafica...')

							registerRegister(uploadData)
								.then((res) => {
									// console.log(res)

									toast.update(id, {
										render: 'Anagrafica salvata!',
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)
									toast.update(id, {
										render: `Errore nel salvare l'anagrafica`,
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})

							break
						case 'edit':
							id = toast.loading(`Salvando modifiche dell'anagrafica...`)

							editRegister(uploadData)
								.then((res) => {
									// console.log(res)

									toast.update(id, {
										render: `Modifiche all'anagrafica apportate!`,
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)

									toast.update(id, {
										render: `Errore nell'apportare le modifiche dell'anagrafica`,
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
							break
						case 'editLocal':
							if (typeof setRegisterData === 'function') {
								setRegisterData(uploadData)
							}
							break
						default:
					}
				}}
			>
				<div className="form-actions">
					{mode === 'editLocal' && (
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								let id = toast.loading(`Scaricando anagrafica...`)

								getRegister(register._id)
									.then((res) => {
										// console.log(res)

										if (res) {
											setRegister(res)
											toast.update(id, {
												render: `Anagrafica caricata!`,
												type: 'success',
												autoClose: 5000,
												isLoading: false,
												closeOnClick: true,
												pauseOnHover: true,
											})
										}
									})
									.catch((e) => {
										console.error(e)
										toast.update(id, {
											render: `Errore nel caricare l'anagrafica`,
											type: 'error',
											autoClose: 5000,
											isLoading: false,
											closeOnClick: true,
											pauseOnHover: true,
										})
									})
							}}
						>
							Sincronizza
						</div>
					)}
				</div>
				<FormEntry
					id={`registerFirstname${register._id ? register._id : ''}`}
					label="Firstname"
					value={register.firstname || ''}
					onChange={(e) => setRegisterAttribute('firstname', e.target.value)}
				/>
				<FormEntry
					id={`registerLastname${register._id ? register._id : ''}`}
					label="Lastname"
					value={register.lastname || ''}
					onChange={(e) => setRegisterAttribute('lastname', e.target.value)}
				/>
				<FormEntry
					id={`registerCompany${register._id ? register._id : ''}`}
					label="Company"
					value={register.company || ''}
					onChange={(e) => setRegisterAttribute('company', e.target.value)}
				/>
				<FormEntry
					id={`registerEmail${register._id ? register._id : ''}`}
					label="Email"
					value={register.email || ''}
					onChange={(e) => setRegisterAttribute('email', e.target.value)}
				/>
				<FormEntry
					id={`registerVat${register._id ? register._id : ''}`}
					label="vat"
					value={register.vat || ''}
					onChange={(e) => setRegisterAttribute('vat', e.target.value)}
				/>
				<FormEntry
					id={`registerFiscalCode${register._id ? register._id : ''}`}
					label="Fiscal Code"
					value={register.fiscalCode || ''}
					onChange={(e) => setRegisterAttribute('fiscalCode', e.target.value)}
				/>
				<FormEntry
					id={`registerCertEmail${register._id ? register._id : ''}`}
					label="Cert Email"
					value={register.certEmail || ''}
					onChange={(e) => setRegisterAttribute('certEmail', e.target.value)}
				/>
				<div className="form-entry">
					<label htmlFor={`registerDefaultPriceList${register._id ? register._id : ''}`} className="form-label">
						{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM12 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-4.473 5a4.5 4.5 0 0 1 8.946 0H7.527z" />
						</svg> */}
						{/* <div style={{ fontSize: '24px', height: '24px' }}>
							icon
						</div> */}
						<div className="label">Listino di default</div>
					</label>
					<select
						className="form-input"
						id={`registerDefaultPriceList${register._id ? register._id : ''}`}
						name="AuthLevel"
						value={register.defaultPriceList !== 'undefined' ? register.defaultPriceList : 'noSelect'}
						onChange={(e) => {
							// if (e.target.value !== '' && e.target.value !== 'noSelect') {
							setRegisterAttribute('defaultPriceList', e.target.value)
							// }
						}}
					>
						<option value="noSelect">Select one...</option>
						{Array.isArray(priceListsNames) &&
							priceListsNames.map((priceList) => {
								return <option value={priceList}>{priceList}</option>
							})}
					</select>
				</div>
				<div className="form-entry" style={{ width: '100%' }}>
					<label className="form-label">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
						</svg>
						<div className="label">Indirizzi</div>
					</label>
					<div className="form-list">
						{Array.isArray(register.addresses) &&
							register.addresses.map((address, i) => {
								if (address.formViewMode !== 'edit') {
									return (
										<div key={i} className="form-list-item">
											<div style={{ width: 'calc(100% - 60px)', textAlign: 'left' }}>{addressLabel(address)}</div>
											<div
												className="list-item-preview-action icon-hover"
												onClick={() => {
													register.addresses[i].formViewMode = 'edit'
													setRegisterAttribute('addresses', register.addresses)
												}}
											>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z" />
												</svg>
											</div>
											<div
												className="list-item-preview-action icon-hover"
												onClick={() => {
													register.addresses.splice(i, 1)
													setRegisterAttribute('addresses', register.addresses)
												}}
											>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
												</svg>
											</div>
										</div>
									)
								}
								return (
									<div key={i} className="form-list-item">
										<FormEntry
											id={`registerAddressFirstame#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Firstname"
											value={register.addresses[i].firstname || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].firstname = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressLastname#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Lastname"
											value={register.addresses[i].lastname || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].lastname = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressCompany#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Company"
											value={register.addresses[i].company || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].company = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressCountry#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Country"
											value={register.addresses[i].country || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].country = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressState#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="State"
											value={register.addresses[i].state || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].state = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressZipcode#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Zipcode"
											value={register.addresses[i].zipcode || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].zipcode = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressCity#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="City"
											value={register.addresses[i].city || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].city = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressStreet#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Street"
											value={register.addresses[i].street || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].street = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressStreetNumber#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Street Num."
											value={register.addresses[i].streetNumber || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].streetNumber = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressPhone#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Phone"
											value={register.addresses[i].phone || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].phone = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressEmail#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Email"
											value={register.addresses[i].email || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].email = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<FormEntry
											id={`registerAddressNotes#${i}_${register._id ? register._id : ''}`}
											style={{ width: '31%', minWidth: '150px', padding: '5px 10px' }}
											label="Notes"
											value={register.addresses[i].notes || ''}
											onChange={(e) => {
												e.preventDefault()
												register.addresses[i].notes = e.target.value
												setRegisterAttribute('addresses', register.addresses)
											}}
										/>
										<div
											style={{ width: '100%', cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.2)', color: '#000' }}
											className="form-submit"
											onClick={() => {
												register.addresses[i].formViewMode = 'preview'
												setRegisterAttribute('addresses', register.addresses)
											}}
										>
											Done
										</div>
										<div className="list-item-remove">
											<div
												className="list-item-remove-icon icon-hover"
												onClick={() => {
													register.addresses.splice(i, 1)
													setRegisterAttribute('addresses', register.addresses)
												}}
											>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
												</svg>
											</div>
										</div>
									</div>
								)
							})}
					</div>
					<div
						style={{ width: '24px', height: '24px' }}
						onClick={() => {
							const addresses = register.addresses || []
							addresses.push({})
							setRegisterAttribute('addresses', addresses)
						}}
					>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" />
						</svg>
					</div>
				</div>
				<button type="submit" className="form-submit">
					{modeToLabel(mode)}
				</button>
			</form>
		</div>
	)
}
