import { usePagination, DOTS } from '../../lib/usePagination'

import '../../styles/components/Pagination.scss'

// handles pagination
const Pagination = (props) => {
	// load config options from props
	const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, setPageSize, hasNextPage } = props

	// calculate pagination range
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	})

	// if there is only 1 page, does not render anything
	if ((currentPage === 0 || paginationRange.length < 2) && typeof setPageSize !== 'function') {
		return null
	}

	if (currentPage > Math.ceil(totalCount / pageSize)) {
		onPageChange(Math.ceil(totalCount / pageSize))
	}

	const onNext = () => {
		onPageChange(currentPage + 1)
	}

	const onPrevious = () => {
		onPageChange(currentPage - 1)
	}

	let lastPage = paginationRange[paginationRange.length - 1]
	return (
		<ul className="pagination-list">
			<li
				// disabled === current page === 0
				className={`pagination-item ${currentPage !== 0 ? 'enabled' : ''}`}
				onClick={() => {
					if (currentPage !== 0) onPrevious()
				}}
			>
				&lt;
			</li>
			{paginationRange.map((pageNumber, i) => {
				if (pageNumber === DOTS) {
					return (
						<li key={(pageNumber, i)} className={`pagination-item`}>
							&#8230;
						</li>
					)
				}

				return (
					<li
						key={(pageNumber, i)}
						// selected === page number === current page
						className={`pagination-item enabled ${currentPage === pageNumber ? 'active' : ''}`}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber + 1}
					</li>
				)
			})}
			<li
				// disabled === current page === lastPage
				className={`pagination-item ${currentPage !== lastPage && hasNextPage !== false ? 'enabled' : ''}`}
				onClick={() => {
					if (currentPage !== lastPage && hasNextPage !== false) onNext()
				}}
			>
				&gt;
			</li>
			{typeof setPageSize === 'function' && (
				<li>
					<select
						className={`pagination-item enabled`}
						value={pageSize}
						onChange={(e) => {
							setPageSize(parseInt(e.target.value))
						}}
					>
						{/* <option value={1}>1</option> */}
						<option value={10}>10</option>
						<option value={25}>25</option>
						<option value={50}>50</option>
						<option value={100}>100</option>
					</select>
				</li>
			)}
		</ul>
	)
}

export default Pagination
