import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'

import OptionGraphic from './Option'

export default function Installations({ installations, installationMethod, setInstallationMethod }) {
	const [collapsed, setCollapsed] = useState()
	return (
		<div className="installations-step step">
			<h1 className="step-title">Installazioni</h1>
			<div className={`step-content-container`}>
				<div
					className={`step-option-header ${collapsed ? 'collapsed' : ''}`}
					onClick={() => {
						setCollapsed(!collapsed)
					}}
				>
					<div className={`step-collapse-icon ${collapsed ? 'collapsed' : ''}`} style={{ fontSize: '28px' }}>
						<FontAwesomeIcon icon={faAngleDoubleDown} />
					</div>

					<div className={`step-option-selected`}>
						{installationMethod ? (
							<>
								<h2 className={`step-selected-title`}>{installationMethod && installationMethod.name}</h2>
								<h3 className={`step-selected-subtitle`}>{installationMethod && installationMethod.description}</h3>
							</>
						) : (
							<h2 className={`step-selected-title`}>Selezionare Installazione</h2>
						)}
					</div>
				</div>
				{Array.isArray(installations) && (
					<OptionGraphic
						options={installations}
						stepSelect={(installation, value) => {
							// let newLengths = [...lengths]
							// if (installation.measurement) {
							// 	newLengths = newLengths.map((len) => {
							// 		len.measureUsed = installation.measurement
							// 		return len
							// 	})
							// }
							// if (installation.invert) {
							// 	newLengths = newLengths.map((len, i) => {
							// 		if (installation.invert === 2) {
							// 			len.actualIndex = (i + 1) % 2
							// 		}
							// 		return len
							// 	})
							// }
							// setLengths(newLengths)
							setInstallationMethod(installation)
						}}
						selected={installationMethod}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
					/>
				)}
			</div>
			{/* <h1 className="step-title">Installazioni</h1>
			<div className="step-content-container">
				<div className="step-list">
					{Array.isArray(installationsMethods) && (
						<OptionGraphic
							options={installationsMethods}
							stepSelect={(installation, value) => {
								let newLengths = [...lengths]
								if (installation.measurement) {
									newLengths = newLengths.map((len) => {
										len.measureUsed = installation.measurement
										return len
									})
								}
								if (installation.invert) {
									newLengths = newLengths.map((len, i) => {
										if (installation.invert === 2) {
											len.actualIndex = (i + 1) % 2
										}
										return len
									})
								}
								setLengths(newLengths)
							}}
							selected={step.selected}
							collapsed={collapsed}
							setCollapsed={setCollapsed}
						/>
					)}
					 // installationsMethods.map((installation, i) => {
											// 	return (
											// 		<div
											// 			key={i}
											// 			className="step-option"
											// 			onClick={() => {
											// 				let newLengths = [...lengths]
											// 				if (installation.measurement) {
											// 					newLengths = newLengths.map((len) => {
											// 						len.measureUsed = installation.measurement
											// 						return len
											// 					})
											// 				}
											// 				if (installation.invert) {
											// 					newLengths = newLengths.map((len, i) => {
											// 						if (installation.invert === 2) {
											// 							len.actualIndex = (i + 1) % 2
											// 						}
											// 						return len
											// 					})
											// 				}
											// 				setLengths(newLengths)
											// 			}}
											// 		>
											// 			{installation.name}
											// 		</div>
											// 	)
											// })}
				</div>
			</div> */}
		</div>
	)
}
