/* eslint-disable no-useless-escape */
// eslint identifies \. in regex as useless escape
import { useState } from 'react'
import { FloatingDelayGroup } from '@floating-ui/react-dom-interactions'

import ContentEditable from '../ContentEditable'

import Tooltip from '../graphic/Tooltip'
// import cloneDeep from 'lodash.clonedeep'

export default function ZoneNote({ item, i, items, provided, handleItemInput, setItem, copyItem, addItem, deleteItem, setSelectedInput }) {
	const [actionsOpen, setActionsOpen] = useState(false)

	function handleKeyDownMacros(event, index) {
		if (event.ctrlKey) {
			switch (event.key) {
				case 'q':
					event.preventDefault()
					setSelectedInput(-1)
					break
				case 'Enter':
					event.preventDefault()
					addItem()
					break
				default:
			}
		} else {
			switch (event.key) {
				case 'Escape':
					event.preventDefault()
					setSelectedInput(-1)
					break
				default:
			}
		}
	}

	// let recentlyModified
	// if (item.dataModifiedByFunction && !configurations.hideAlert) {
	// 	let modifiedDate = new Date(item.dataModifiedByFunction)
	// 	let now = new Date()

	// 	if (now.getTime() - modifiedDate.getTime() <= 1000 * 60 * 60 * 24 * 7) {
	// 		recentlyModified = true
	// 	}
	// }

	return (
		<div
			ref={provided ? provided.innerRef : null}
			{...(provided ? provided.draggableProps : {})}
			className="zone-product"
			tabIndex="-1"
			onMouseLeave={(e) => {
				setActionsOpen(false)
			}}
			// style={{ alignSelf: item && item.product ? 'start' : 'center' }}
			// style={{ outline: 'none' }}
		>
			<div className="zone-product-entry">
				<label className="zone-product-entry-label" htmlFor={`product#${item.id}`}>
					Nota
				</label>
				<ContentEditable
					className="zone-product-input zone-product-input-info"
					// innerRef={this.contentEditable}
					value={item.name || ''}
					onKeyDown={(e) => handleKeyDownMacros(e, i)}
					onInput={(e) => {
						handleItemInput(i, ['name', 'namePlainText'], [e.target.value, e.target.htmlAsText])
					}}
				/>
			</div>
			{provided && (
				<div
					className="zone-product-drag "
					{...provided.dragHandleProps}
					tabIndex="-1"
					style={{ alignSelf: item && item.product ? 'start' : 'center' }}
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="90%" height="2rem">
						{/* <path fill="none" d="M0 0h24v24H0z" />
					<path d="M11 11V5.828L9.172 7.657 7.757 6.243 12 2l4.243 4.243-1.415 1.414L13 5.828V11h5.172l-1.829-1.828 1.414-1.415L22 12l-4.243 4.243-1.414-1.415L18.172 13H13v5.172l1.828-1.829 1.415 1.414L12 22l-4.243-4.243 1.415-1.414L11 18.172V13H5.828l1.829 1.828-1.414 1.415L2 12l4.243-4.243 1.414 1.415L5.828 11z" /> */}
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M12 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
					</svg>
				</div>
			)}
			{/* {recentlyModified && (
				<div className={`zone-product-alert`}>
					<FloatingDelayGroup delay={{ open: 700, close: 400 }}>
						<Tooltip label="Riga modificata nell'ultima settimana da una utilità" placement="top">
							<div className={`icon-hover active`} style={{ width: '26px', height: '26px', padding: '4px', cursor: 'default' }}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" />
								</svg>
							</div>
						</Tooltip>
					</FloatingDelayGroup>
				</div>
			)} */}
			<div className={`zone-product-actions ${actionsOpen ? 'open' : 'close'}`}>
				<div className="zone-product-actions-icon icon-hover" style={{ width: '100%', height: '100%' }} onClick={(e) => setActionsOpen(!actionsOpen)}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M2 12c0-.865.11-1.703.316-2.504A3 3 0 0 0 4.99 4.867a9.99 9.99 0 0 1 4.335-2.505 3 3 0 0 0 5.348 0 9.99 9.99 0 0 1 4.335 2.505 3 3 0 0 0 2.675 4.63c.206.8.316 1.638.316 2.503 0 .865-.11 1.703-.316 2.504a3 3 0 0 0-2.675 4.629 9.99 9.99 0 0 1-4.335 2.505 3 3 0 0 0-5.348 0 9.99 9.99 0 0 1-4.335-2.505 3 3 0 0 0-2.675-4.63C2.11 13.704 2 12.866 2 12zm4.804 3c.63 1.091.81 2.346.564 3.524.408.29.842.541 1.297.75A4.993 4.993 0 0 1 12 18c1.26 0 2.438.471 3.335 1.274.455-.209.889-.46 1.297-.75A4.993 4.993 0 0 1 17.196 15a4.993 4.993 0 0 1 2.77-2.25 8.126 8.126 0 0 0 0-1.5A4.993 4.993 0 0 1 17.195 9a4.993 4.993 0 0 1-.564-3.524 7.989 7.989 0 0 0-1.297-.75A4.993 4.993 0 0 1 12 6a4.993 4.993 0 0 1-3.335-1.274 7.99 7.99 0 0 0-1.297.75A4.993 4.993 0 0 1 6.804 9a4.993 4.993 0 0 1-2.77 2.25 8.126 8.126 0 0 0 0 1.5A4.993 4.993 0 0 1 6.805 15zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
					</svg>
				</div>
				<div className={`zone-product-actions-container`}>
					<div className={`zone-product-actions-list`}>
						<FloatingDelayGroup delay={{ open: 700, close: 400 }}>
							<Tooltip label="Copia riga" placement="top">
								<div className="zone-product-action">
									<div
										className={`icon-hover`}
										onClick={() => {
											copyItem(i)
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
										</svg>
									</div>
								</div>
							</Tooltip>
							<Tooltip label="Rimuovi riga" placement="top" delayTime={0}>
								<div className="zone-product-action">
									<div
										className="icon-hover"
										onClick={() => {
											deleteItem(i)
										}}
									>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
										</svg>
									</div>
								</div>
							</Tooltip>
						</FloatingDelayGroup>
					</div>
				</div>
			</div>
		</div>
	)
}
