import useConfigurationStore from './stores/configurations'
import useUserStore from './stores/user'

export default function retrieveSavedAttributes() {
	const userStore = useUserStore.getState()
	const configurationStore = useConfigurationStore.getState()

	let savedAttribute
	if (userStore.user && userStore.user.attributeNames && userStore.user.attributeNames[0]) {
		savedAttribute = configurationStore.configurations && configurationStore.configurations.attributeNames

		// if there was not saved attribute, get user's first attribute
		if (!savedAttribute) {
			savedAttribute = userStore.user.attributeNames[0]

			// check if saved configuration is present in user (if user changes saved attribute may not be available for new user)
		} else if (!userStore.user.attributeNames.find((name) => name === savedAttribute)) {
			savedAttribute = userStore.user.attributeNames[0]
			const configurations = configurationStore.configurations || {}
			configurations.attributeNames = savedAttribute
			useConfigurationStore.setState({ configurations: configurations })
		}
	}
	return savedAttribute
}
