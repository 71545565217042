import { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import axios from 'axios'

import useUploadsStore, { usePersistUploadsStore } from '../../lib/stores/uploads'
import useUserStore from '../../lib/stores/user'

export default function UploadsList({ onUploadClick }) {
	const { uploadsArray, fetchUploadsArray, _hasHydrated } = usePersistUploadsStore((state) => ({
		uploadsArray: state.uploadsArray,
		fetchUploadsArray: state.fetchUploadsArray,
		_hasHydrated: state._hasHydrated,
	}))

	const { token } = useUserStore((state) => ({ token: state.token }))
	const { uploads, setUploadData } = useUploadsStore((state) => ({ uploads: state.uploads, setUploadData: state.setUploadData }))

	const [filteredUploads, setFilteredUploads] = useState([])
	const [filterString, setFilterString] = useState('')

	useEffect(() => {
		if (_hasHydrated) {
			fetchUploadsArray()
		}
	}, [_hasHydrated, fetchUploadsArray])

	useEffect(() => {
		const searchTerms = filterString === '' ? [] : filterString.trim().replace(/,/gm, '.').toLowerCase().split(' ')

		if (searchTerms.length < 1 || searchTerms[0].length < 2) {
			setFilteredUploads(uploadsArray)
			return
		}

		let searchComponents = [{ name: 'name' }]

		const options = {
			isCaseSensitive: false,
			minMatchCharLength: 2,
			includeScore: true,
			keys: searchComponents,
			threshold: 0.05,
			findAllMatches: true,
			useExtendedSearch: false,
			ignoreLocation: true,
		}

		const fuse = new Fuse(uploadsArray, options)

		let result = fuse.search(searchTerms[0]).map((el) => el.item)

		// Fuse search for each term
		for (let i = 1; i < searchTerms.length; i++) {
			const fuser = new Fuse(result, options)

			const term = searchTerms[i]

			if (term.length < 2) continue

			result = fuser.search(term).map((el) => el.item)
		}

		setFilteredUploads(result)
	}, [uploadsArray, filterString])

	// return null

	return (
		<div className="uploads-list-modal">
			<h2>Filtra per nome</h2>
			<div>
				<input
					className="uploads-list-filter"
					value={filterString}
					onChange={(e) => {
						setFilterString(e.target.value)
					}}
				/>
			</div>
			<div className="uploads-list">
				{Array.isArray(filteredUploads) &&
					filteredUploads.map((upload) => {
						let size = upload.size
						let sizeLabel = 'B'

						while (size >= 1000) {
							switch (sizeLabel) {
								case 'B':
									sizeLabel = 'KB'
									break
								case 'KB':
									sizeLabel = 'MB'
									break
								case 'MB':
									sizeLabel = 'GB'
									break
								default:
							}
							size = size / 1000
						}

						return (
							<div
								className="uploads-list-item"
								onClick={() => {
									onUploadClick(upload, uploads && uploads[upload._id] ? uploads[upload._id] : null)
								}}
								key={upload._id}
							>
								{uploads && uploads[upload._id] ? (
									<div>
										<img style={{ maxWidth: '180px', maxHeight: '180px' }} alt={upload.name} src={uploads[upload._id]} />
									</div>
								) : (
									<div
										className="unavailable-image"
										onClick={(e) => {
											e.preventDefault()
											e.stopPropagation()
											axios
												.get(`${process.env.REACT_APP_SERVER_URL}/api/uploads/${upload._id}/${upload.name}`, {
													headers: { authorization: token },
													responseType: 'blob',
												})
												.then((fileRes) => {
													// console.log(fileRes, typeof fileRes.data)
													if (fileRes.data) {
														const reader = new FileReader()
														reader.readAsDataURL(fileRes.data)
														reader.onloadend = () => {
															const base64data = reader.result

															// setFileData(base64data)
															setUploadData(upload._id, base64data)
														}
													}
												})
												.catch((e) => {
													// console.error(e)
												})
										}}
									>
										Scarica immagine
									</div>
								)}
								<div>{upload.name}</div>
								<div>
									{upload.width}x{upload.height}
								</div>
								<div>
									{size.toFixed(2)}
									{sizeLabel}
								</div>
							</div>
						)
					})}
			</div>
		</div>
	)
}
