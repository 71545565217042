/* eslint-disable no-useless-escape */
export default function getDiscount(discountStr, qty, price) {
	let discount = cleanStringForEval(discountStr)

	// Multiply
	const multiply = /[*](\d*[.]?\d*)/gimu
	let multiplies = []

	let m

	while ((m = multiply.exec(discount)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === multiply.lastIndex) {
			multiply.lastIndex++
		}
		const numTemp = parseFloat(m[1])
		if (isNaN(numTemp)) break
		multiplies.push(numTemp)
	}

	discount = discount.replace(multiply, '')

	// Division
	const division = /[\/](\d*[.]?\d*)/gimu
	let divisions = []

	while ((m = division.exec(discount)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === division.lastIndex) {
			division.lastIndex++
		}
		const numTemp = parseFloat(m[1])
		if (isNaN(numTemp)) break
		divisions.push(numTemp)
	}

	discount = discount.replace(division, '')

	// Percentage add
	const percentageAdd = /[-](\d*[.]?\d*)[%]/gimu

	while ((m = percentageAdd.exec(discount)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === percentageAdd.lastIndex) {
			percentageAdd.lastIndex++
		}
		const numTemp = parseFloat(m[1])
		if (isNaN(numTemp)) break
		multiplies.push(1 + numTemp / 100)
	}
	discount = discount.replace(percentageAdd, '')

	// Percentage discount
	const percentage = /[+]?(\d*[.]?\d*)[%]/gimu

	while ((m = percentage.exec(discount)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === percentage.lastIndex) {
			percentage.lastIndex++
		}
		const numTemp = parseFloat(m[1])
		if (isNaN(numTemp)) break
		multiplies.push(1 - numTemp / 100)
	}

	discount = discount.replace(percentage, '')

	// Addition
	const addition = /[+](\d*[.]?\d*)/gimu
	let additions = []

	while ((m = addition.exec(discount)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === addition.lastIndex) {
			addition.lastIndex++
		}
		const numTemp = parseFloat(m[1])
		if (isNaN(numTemp)) break
		additions.push(numTemp)
	}

	discount = discount.replace(addition, '')

	// Subtraction
	const subtraction = /[-](\d*[.]?\d*)/gimu
	let subtractions = []

	while ((m = subtraction.exec(discount)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === subtraction.lastIndex) {
			subtraction.lastIndex++
		}
		const numTemp = parseFloat(m[1])
		if (isNaN(numTemp)) break
		subtractions.push(numTemp)
	}

	discount = discount.replace(subtraction, '')

	// Simple numbers
	const numbersSimple = /(\d*[.]?\d*)/gimu

	while ((m = numbersSimple.exec(discount)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === numbersSimple.lastIndex) {
			numbersSimple.lastIndex++
		}
		const numTemp = parseFloat(m[1])
		if (isNaN(numTemp)) break
		if (numTemp === 0) multiplies.push(1)
		else if (numTemp < 1) multiplies.push(numTemp)
		else subtractions.push(numTemp)
	}

	discount = discount.replace(numbersSimple, '')

	if (isNaN(price)) price = 0

	let tot = price

	multiplies.forEach((num) => {
		tot *= num
	})

	divisions.forEach((num) => {
		if (num) tot /= num
	})

	additions.forEach((num) => {
		tot += num
	})

	subtractions.forEach((num) => {
		tot -= num
	})

	let res = { price: tot }

	tot *= qty

	res = { ...res, tot: tot }

	return res
}

function cleanStringForEval(str) {
	if (!str) str = ''
	let cleanedString = str
		.replace(/\s/gm, '')
		.replace(/[,]/gm, '.')
		.replace(/[a-zA-Z]/gm, '')
		// useless replace
		// .replace(/[x]/gm, '*')
		.replace(/\n/gm, '')

	return cleanedString
}
