import '../../styles/components/ToggleSwitch.scss'

export default function ToggleSwitch({ label, value, setValue, center = true }) {
	return (
		<div className="toggle-switch" onClick={setValue} style={center ? {} : { margin: '0' }}>
			<input id="chck" type="checkbox" checked={value} onChange={(e) => e.preventDefault()} />
			<label htmlFor="chck" className="check-trail" onClick={(e) => e.preventDefault()}>
				<span className="check-handler"></span>
			</label>
		</div>
	)
}
