import { useEffect } from 'react'
import { useDebounce } from 'rooks'

import useModalStore from '../lib/stores/modal'

import '../styles/components/Modals.scss'

export default function ModalProvider() {
	const { visible, modals, toggleModalVisibility, removeModal } = useModalStore((state) => ({
		visible: state.visible,
		modals: state.modals,
		toggleModalVisibility: state.toggleModalVisibility,
		removeModal: state.removeModal,
	}))

	const removeModalDebounced = useDebounce(removeModalDeb, 350)

	function removeModalDeb(id) {
		if (typeof removeModal === 'function') {
			removeModal(id)
		}
	}

	useEffect(() => {
		if (visible) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}, [visible])

	return (
		<div className={`modal-provider ${visible ? 'show' : ''}`}>
			{Array.isArray(modals) &&
				modals.map((modal, i) => {
					return (
						<div className={`modal-container`} key={i}>
							<div
								className={`modal-content ${modal.visible ? 'show' : ''} ${modal.customModal ? 'invisible' : ''}`}
								style={{ zIndex: (i + 1) * 2 }}
							>
								{modal.modal}
								<div
									className="modal-close"
									onClick={() => {
										toggleModalVisibility(modal.id, false)

										if (modal.disposableModal) removeModalDebounced(modal.id)
									}}
								>
									{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
									</svg> */}
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
									</svg>
								</div>
							</div>
							{modal.background && (
								<div
									className={`modal-background ${modal.visible ? 'show' : ''}`}
									style={{ zIndex: (i + 1) * 2 - 1 }}
									onClick={() => {
										toggleModalVisibility(modal.id, false)

										if (modal.disposableModal) removeModalDebounced(modal.id)
									}}
								></div>
							)}
						</div>
					)
				})}
			{/* <div className={`modal-background ${visible ? 'show' : ''}`}></div> */}
		</div>
	)
}
