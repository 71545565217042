import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'

import useDocumentStore from '../../lib/stores/document'

export default function Subtitle({ subtitle, elementIndex, provided }) {
	const setElement = useDocumentStore((state) => state.setElement)

	function handleInput(key, value) {
		if (typeof setElement === 'function') {
			const element = { ...subtitle }
			element[key] = value
			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	return (
		<div className="element">
			<input
				className="element-subtitle element-full"
				value={subtitle.subtitle}
				placeholder="Subtitle..."
				onChange={(e) => {
					e.preventDefault()
					handleInput('subtitle', e.target.value)
				}}
			/>
			{provided && <DragElement provided={provided} />}
			<RemoveElement element={subtitle} elementIndex={elementIndex} />
		</div>
	)
}
