import { useMemo } from 'react'

export default function useFilters({ data, attributes, searchString, refreshes = [] }) {
	const filteredData = useMemo(() => {
		if (!Array.isArray(data)) {
			return []
		}
		let filteredData = []
		let filters = []
		let searchFilters = []
		if (Array.isArray(attributes)) {
			filters = attributes.filter((attribute) => attribute.enabled && attribute.value)
			searchFilters = attributes.filter((attribute) => !attribute.enabled || !attribute.value)
		}

		data.forEach((item) => {
			// Not used for now: uses column based filters
			let present = false
			let filterActive = false
			let hitValue = 0

			filters.forEach((filter) => {
				filterActive = true
				let attribute = item[filter.key]
				if (attribute) {
					switch (typeof attribute) {
						case 'string':
							if (attribute.includes(filter.value)) {
								present = true
								hitValue -= filter.weight || 1
							}
							break
						default:
							if (attribute === filter.value) {
								present = true
								hitValue -= filter.weight || 1
							}
					}
				}
			})

			// search product on attributes using searchString
			if (searchString !== '' && typeof searchString === 'string') {
				searchFilters.forEach((filter) => {
					filterActive = true
					let attribute = item[filter.key]

					const searchStringParts = searchString.split(' ')

					searchStringParts.forEach((value) => {
						if (attribute) {
							switch (typeof attribute) {
								case 'string':
									if (attribute.toLowerCase().includes(value.toLowerCase())) {
										present = true
										hitValue -= filter.weight || 1
									}
									break
								default:
									if (attribute === value) {
										present = true
										hitValue -= filter.weight || 1
									}
							}
						}
					})
				})
			}

			// if filter is not active (search string is not set or is '' and no filter is active) || row is present (not filtered)
			if (!filterActive || present) {
				filteredData.push({ ...item, hitValue: hitValue })
			}
		})

		return filteredData.sort((a, b) => b.hitValue - a.hitValue)
	}, [data, attributes, searchString])

	return filteredData
}
