import { ToastContainer, Flip } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'

import ModalProvider from './components/ModalProvider'
import LoginProvider from './components/login/LoginProvider'
import Layout from './components/Layout'

import './App.scss'
// import 'react-toastify/dist/ReactToastify.css'
import './styles/base/Toastify.scss'

function App() {
	return (
		<BrowserRouter
		// basename="/test-only"
		>
			<div className="App">
				<ToastContainer transition={Flip} theme="colored" position="bottom-left" />
				<LoginProvider>
					<ModalProvider />
					<Layout />
				</LoginProvider>
			</div>
		</BrowserRouter>
	)
}

export default App
