import useDocumentStore from '../../lib/stores/document'

import Select from './Select'

import '../../styles/components/StatusPanel.scss'

export default function StatusPanel() {
	const { status, workStatus, paymentStatus, duePayment, setStatus, setWorkingStatus, setPaymentStatus, setDuePayment } = useDocumentStore((state) => ({
		status: state.status,
		workStatus: state.workStatus,
		paymentStatus: state.paymentStatus,
		duePayment: state.duePayment,
		setStatus: state.setStatus,
		setWorkingStatus: state.setWorkingStatus,
		setPaymentStatus: state.setPaymentStatus,
		setDuePayment: state.setDuePayment,
	}))

	// console.log(document)

	return (
		<div className="status-panel">
			<div className="status-option">
				<div>Stato ordine</div>
				<Select
					selected={status || 'none'}
					// selected={document && document.status}
					options={[
						{ value: 'none', label: 'Nessun stato' },
						{ value: 'quote', label: 'Preventivo' },
						{ value: 'confirmed', label: 'Confermato' },
						{ value: 'completed', label: 'Completato' },
					]}
					onSelect={(option, i, event) => {
						setStatus(option.value)
					}}
				/>
			</div>
			<div className="status-option">
				<div>Stato pagamento</div>
				<Select
					selected={paymentStatus || 'none'}
					// selected={document && document.status}
					options={[
						{ value: 'none', label: 'Nessun stato' },
						{ value: 'notPayed', label: 'Non pagato' },
						{ value: 'waitingPayment', label: 'Aspettando pagamento' },
						{ value: 'payed', label: 'Pagato' },
					]}
					onSelect={(option, i, event) => {
						setPaymentStatus(option.value)
					}}
				/>
				<div>Data prevista pagamento</div>
				<input
					type="date"
					className="element-title element-full"
					style={{ fontSize: '150%', marginBottom: '6px', width: 'auto', marginLeft: 'auto' }}
					value={(() => {
						let date = duePayment || ''
						date = date.split('T')[0]
						return date
					})()}
					placeholder="Data..."
					onChange={(e) => {
						// console.log(e)
						setDuePayment(e.target.value)
					}}
				/>
			</div>
			<div className="status-option">
				<div>Stato lavorazione</div>
				<Select
					selected={workStatus || 'none'}
					// selected={document && document.status}
					options={[
						{ value: 'none', label: 'Nessun stato' },
						{ value: 'preparing', label: 'In attesa' },
						{ value: 'processing', label: 'In lavorazione' },
						{ value: 'ready', label: 'Pronto' },
						{ value: 'delivered', label: 'Consegnato' },
					]}
					onSelect={(option, i, event) => {
						setWorkingStatus(option.value)
					}}
				/>
			</div>
		</div>
	)
}
