import { chain, round, multiply, ceil, pow, divide } from 'mathjs'

function calcPriceToAdd(step, key, advanced) {
	let toAdd = step.selected[key]
	if (typeof toAdd !== 'number') toAdd = 0

	if (typeof step.quantity === 'number' && step.quantity > 0) toAdd *= step.quantity

	// Avoid paying double for power supplies (paying power supplies for each lenght)
	if (step.slug === 'power_supplies' && advanced) toAdd = divide(toAdd, 2)

	// Prezzo di 1 centrallina per ogni alimentatore, (diviso per i segmenti)
	// let powerSupply = steps[constants.current.indexes.power_supply[mode]]
	// if (step.slug === 'options' && typeof (powerSupply && powerSupply.quantity) === 'number') {
	// 	toAdd = divide(multiply(toAdd, powerSupply.quantity), advanced ? 2 : 1)
	// } else
	if (step.slug === 'options' && advanced) {
		toAdd = divide(toAdd, 2)
	}

	return toAdd
}

export default function calculatePrice({ steps, installationMethod, advanced, prices = [], lengths, selectedPriceList, mode, compositionInfo = null, data }) {
	if (!data) {
		return fetch('/data/data.json')
			.then((res) => res.json())
			.then((data) => {
				return calculateWithData({ steps, installationMethod, advanced, prices, lengths, selectedPriceList, mode, compositionInfo, data })
			})
	} else {
		return calculateWithData({ steps, installationMethod, advanced, prices, lengths, selectedPriceList, mode, compositionInfo, data })
	}
}

function calculateWithData({ steps, installationMethod, advanced, prices = [], lengths, selectedPriceList, mode, compositionInfo, data }) {
	let priceMeter = 0
	let priceFixed = 0
	let priceWork = 0
	let priceAccessories = 0

	// let length = 1

	// calculate option prices (fixed costs + cost per meter)
	if (Array.isArray(steps)) {
		steps.forEach((step) => {
			if (step.selected) {
				// Price meter
				let toAdd = calcPriceToAdd(step, 'price', advanced)
				priceMeter = chain(priceMeter).add(toAdd).done()

				// Price fixed
				toAdd = calcPriceToAdd(step, 'priceFixed', advanced)
				priceFixed = chain(priceFixed).add(toAdd).done()

				// Price work
				toAdd = calcPriceToAdd(step, 'work', advanced)
				priceWork = chain(priceWork).add(toAdd).done()

				// Price Accessories
				toAdd = calcPriceToAdd(step, 'priceAccessories', advanced)
				priceAccessories = chain(priceAccessories).add(toAdd).done()

				// check for multipliers
				if (Array.isArray(step.multiplierIndexes)) {
					if (typeof step.selected.multiplier === 'number') {
						step.multiplierIndexes.forEach((index) => {
							// Price meter
							if (steps[index].selected) {
								let toAdd = calcPriceToAdd(steps[index], 'price', advanced)
								let multiplyPrice = chain(toAdd)
									.multiply(step.selected.multiplier - 1)
									.done()
								priceMeter = chain(priceMeter).add(multiplyPrice).done()

								// Price fixed
								toAdd = calcPriceToAdd(steps[index], 'priceFixed', advanced)
								multiplyPrice = chain(toAdd)
									.multiply(step.selected.multiplier - 1)
									.done()
								priceFixed = chain(priceFixed).add(multiplyPrice).done()

								// Price work
								toAdd = calcPriceToAdd(steps[index], 'work', advanced)
								multiplyPrice = chain(toAdd)
									.multiply(step.selected.multiplier - 1)
									.done()
								priceWork = chain(priceWork).add(multiplyPrice).done()

								// Price Accessories
								toAdd = calcPriceToAdd(steps[index], 'priceAccessories', advanced)
								multiplyPrice = chain(toAdd)
									.multiply(step.selected.multiplier - 1)
									.done()
								priceAccessories = chain(priceAccessories).add(multiplyPrice).done()
							}
						})
					} else if (typeof step.selected.priceMultiplier === 'number') {
						step.multiplierIndexes.forEach((index) => {
							// Price meter
							if (steps[index].selected) {
								let toAdd = calcPriceToAdd(steps[index], 'price', advanced)
								let multiplyPrice = chain(toAdd)
									.multiply(step.selected.priceMultiplier - 1)
									.done()
								priceMeter = chain(priceMeter).add(multiplyPrice).done()
							}
						})
					}
				}

				if (Array.isArray(step.extraIndexes)) {
					if (typeof step.selected.extra === 'number') {
						step.extraIndexes.forEach((index) => {
							// Price meter
							if (steps[index].selected) {
								let toAdd = calcPriceToAdd(steps[index], 'price', advanced)
								let additionalPrice = chain(toAdd).multiply(step.selected.extra).subtract(toAdd).done()
								priceMeter = chain(priceMeter).add(additionalPrice).done()

								let toAddWork = calcPriceToAdd(steps[index], 'work', advanced)
								let additionalPriceWork = chain(toAddWork).multiply(step.selected.extra).subtract(toAddWork).done()
								priceWork = chain(priceWork).add(additionalPriceWork).done()
							}
						})
					}
				}
			}
		})
	}

	if (installationMethod && advanced) {
		let priceCut = installationMethod.priceCut
		if (typeof priceCut !== 'number') priceCut = 0
		priceFixed = chain(priceFixed).add(priceCut).done()
	}

	// calculate total price based on length / lengths
	let newPrices = [...prices]
	let totalPrice = -1
	lengths.forEach((length, i) => {
		let workCost = multiply(priceWork, (data && data.configs && data.configs.markup) || 1)
		let roundedLength = chain(typeof length.calculatedMax === 'number' ? length.calculatedMax : length.value)
			.divide(500)
			.ceil()
			.divide(2)
			.done()
		if (roundedLength <= 0) {
			roundedLength = 0.5
		}
		if (roundedLength > (mode === 'strip' ? 100000 : 5)) {
			roundedLength = 6
		}

		let lengthExtra = 0
		if (mode !== 'strip') {
			if (roundedLength > ((data && data.configs && data.configs.extra_length_thresold) || 3)) {
				// add extra misura
				lengthExtra = chain(lengthExtra)
					.add((data && data.configs && data.configs.extra_length) || 25)
					.done()
			}
		}

		if (mode === 'profile') {
			// Remove work cost, add linear cut cost
			workCost = 0
		}

		let accessoriesPrice = chain(ceil(roundedLength + 1))
			.multiply(priceAccessories)
			.done()

		let lengthPrice = chain(roundedLength).multiply(priceMeter).add(priceFixed).add(lengthExtra).add(workCost).add(accessoriesPrice).done()

		if (mode === 'profile' && !(compositionInfo && Array.isArray(compositionInfo.profiles))) {
			// Remove work cost, add linear cut cost

			if (!(compositionInfo && Array.isArray(compositionInfo.profiles))) {
				if (![1000, 1500, 2000, 2500, 3000].includes(typeof length.calculatedMax === 'number' ? length.calculatedMax : length.value)) {
					lengthPrice = chain(lengthPrice)
						.add((data && data.configs && data.configs.cut_linear) || 12)
						.done()
				}
			}
		}

		if (mode !== 'strip') {
			if (compositionInfo && Array.isArray(compositionInfo.profiles)) {
				let cutPrices = compositionInfo.profiles[i].cuts

				if (Array.isArray(cutPrices)) {
					for (let k = 0; k < cutPrices.length; k++) {
						switch (cutPrices[k]) {
							case 'linear':
								lengthPrice = chain(lengthPrice)
									.add((data && data.configs && data.configs.cut_linear) || 0)
									.done()
								break
							case 'angular':
								lengthPrice = chain(lengthPrice)
									.add((data && data.configs && data.configs.cut_angular) || 0)
									.done()
								break
							case 'angularSpecial':
								lengthPrice = chain(lengthPrice)
									.add((data && data.configs && data.configs.cut_angular_special) || 0)
									.done()
								break
							default:
						}
					}
				}
			}

			if (roundedLength > 1) {
				// add extra misura
				lengthPrice = chain(lengthPrice)
					.multiply(pow((data && data.configs && data.configs.meter_length_surplus) || 1.03, roundedLength - 1))
					.done()
			}
		}

		// Calculate extra pricing based on priceList
		if (selectedPriceList && data && data.configs && data.configs.priceModifiers) {
			if (data.configs.priceModifiers[selectedPriceList.pricelist_id]) {
				// apply price modifier
				let mult = data.configs.priceModifiers[selectedPriceList.pricelist_id].multiplier
				let priceModifier = parseFloat(mult)
				if (!isNaN(priceModifier)) {
					lengthPrice = lengthPrice * priceModifier
				}
			}
		}

		let rounded = round(lengthPrice, 2)

		if (newPrices[i] === undefined) {
			newPrices.push(rounded)
		} else {
			newPrices[i] = rounded
		}

		totalPrice += lengthPrice
	})

	if (compositionInfo) {
	}

	if (totalPrice === -1) {
		totalPrice = 0
	} else {
		totalPrice += 1
	}

	let rounded = round(totalPrice, 2)

	return [rounded, newPrices]
}
