import { useEffect } from 'react'

import LoginForm from './LoginForm'

import useUserStore from '../../lib/stores/user'
import useSessionStore from '../../lib/stores/session'

export default function LoginProvider({ children }) {
	const { token, checkLogin, refreshToken } = useUserStore((state) => ({
		token: state.token,
		checkLogin: state.checkLogin,
		refreshToken: state.refreshToken,
	}))
	const { loggedIn, loading, setLoading, setLoggedIn } = useSessionStore((state) => ({
		loggedIn: state.loggedIn,
		loading: state.loading,
		setLoading: state.setLoading,
		setLoggedIn: state.setLoggedIn,
	}))

	// on load and when token change check if user is logged in using the token
	useEffect(() => {
		if (typeof checkLogin === 'function') {
			checkLogin().then((res) => {
				setLoggedIn(res)
				setLoading(false)
				refreshToken()
			})
		}
	}, [token, checkLogin, refreshToken, setLoading, setLoggedIn])

	if (loading) {
		return null
	}

	if (!loggedIn) {
		return <LoginForm />
	}

	return children
}
