import { cloneElement, useState } from 'react'
import {
	// Placement,
	offset,
	flip,
	shift,
	autoUpdate,
	useFloating,
	useInteractions,
	useHover,
	useFocus,
	useRole,
	useDismiss,
	useDelayGroupContext,
	useDelayGroup,
} from '@floating-ui/react-dom-interactions'

import '../../styles/components/Tooltip.scss'

export default function Tooltip({ children, label, placement = 'top', delayTime = 100 }) {
	const { delay, setCurrentId } = useDelayGroupContext()
	const [open, setOpen] = useState(false)

	const { x, y, reference, floating, strategy, context } = useFloating({
		placement,
		open,
		onOpenChange(open) {
			setOpen(open)

			if (open) {
				setCurrentId(label)
			}
		},
		middleware: [offset(5), flip(), shift({ padding: 8 })],
		whileElementsMounted: autoUpdate,
	})

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useHover(context, { delay, restMs: 100 }),
		useFocus(context),
		useRole(context, { role: 'tooltip' }),
		useDismiss(context),
		useDelayGroup(context, { id: label }),
	])

	return (
		<>
			{cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
			{/* <AnimatePresence> */}
			{open && (
				<div
					{...getFloatingProps({
						ref: floating,
						className: 'Tooltip',
						style: {
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
						},
					})}
				>
					{label}
				</div>
			)}
			{/* </AnimatePresence> */}
		</>
	)
}
