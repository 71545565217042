import { useState } from 'react'
import { useFloating, shift, useClick, useInteractions } from '@floating-ui/react'

export default function DropDownMenu({ icon, content, reverse = false }) {
	const [visible, setVisible] = useState(false)
	const { x, y, strategy, refs, context } = useFloating({ placement: 'bottom-start', middleware: [shift()], open: visible, onOpenChange: setVisible })

	const click = useClick(context)

	const { getReferenceProps, getFloatingProps } = useInteractions([click])

	return (
		<div className="dropdown-menu">
			<div ref={refs.setReference} className="dropdown-menu-label" {...getReferenceProps()}>
				{icon}
			</div>
			{visible && (
				<div
					ref={refs.setFloating}
					className={`dropdown-menu-content`}
					style={{
						position: strategy,
						top: y ?? 0,
						left: x ?? 0,
						width: 'max-content',
					}}
					{...getFloatingProps()}
					// style={{ top: '100%', ...(reverse ? { left: 0 } : { right: 0 }) }}
				>
					{content}
				</div>
			)}
		</div>
	)
}
