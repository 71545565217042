import '../../styles/components/Loading.scss'

export default function Loading() {
	return (
		<div className="loading-boxes">
			<div className="loading-box"></div>
			<div className="loading-box"></div>
			<div className="loading-box"></div>
			<div className="loading-box"></div>
			<div className="loading-box"></div>
			<div className="loading-box"></div>
			<div className="loading-box"></div>
			<div className="loading-box"></div>
			<div className="loading-box"></div>
		</div>
	)
}
