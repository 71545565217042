import useProductsStore from '../stores/products'

export const profilesRelations = [
	{
		cod_family: '2EE0',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '2F44',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '3140',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '2FA8',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '300C',
		covers: [
			['', 'Nessuna copertura'],
			['2B03', 'COP_TECNICA'],
			['2B04', 'COP_TONDA'],
			['2B05', 'COP_PIATTA'],
			['2B06', 'TRASP'],
		],
	},
	{
		cod_family: '3070',
		covers: [
			['', 'Nessuna copertura'],
			['2B21', 'OPAL'],
			['2B22', 'TRASP'],
			['2B23', 'MBLACK'],
		],
	},
	{
		cod_family: '3080',
		covers: [
			['', 'Nessuna copertura'],
			['2B21', 'OPAL'],
			['2B22', 'TRASP'],
			['2B23', 'MBLACK'],
		],
	},
	{
		cod_family: '30D4',
		covers: [
			['', 'Nessuna copertura'],
			['2B35', 'OPAL'],
			['2B36', 'MICRO'],
			['2B37', 'MBLACK'],
		],
	},
	{
		cod_family: '3138',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '4E20',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '4E84',
		covers: [
			['', 'Nessuna copertura'],
			['2B0D', 'OPAL_1'],
			['2B0F', 'MBLACK_1'],
			['2B0E', 'TRASP_1'],
			['2B17', 'OPAL_2'],
			['2B00', 'TRASP_2'],
		],
	},
	{
		cod_family: '4E90',
		covers: [
			['', 'Nessuna copertura'],
			['2B21', 'OPAL'],
			['2B22', 'TRASP'],
			['2B23', 'MBLACK'],
		],
	},
	{
		cod_family: '55F0',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '56B8',
		covers: [
			['', 'Nessuna copertura'],
			['2B21', 'OPAL'],
			['2B22', 'TRASP'],
			['2B23', 'MBLACK'],
		],
	},
	{
		cod_family: '5654',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '571C',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '5780',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '5DC0',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '5E24',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '5E88',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '5EEC',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{ cod_family: '6018', covers: [['2B3F', 'OPAL']] },
	{ cod_family: '607C', covers: [['2B3F', 'OPAL']] },
	{
		cod_family: '6658',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '66BC',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '6720',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '6784',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '7530',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '7562',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '7594',
		covers: [
			['', 'Nessuna copertura'],
			['2B49', 'OPAL'],
			['2B4A', 'MICRO'],
			['2B4B', 'MBLACK'],
			['2B4C', 'OPBLACK'],
		],
	},
	{
		cod_family: '759E',
		covers: [
			['', 'Nessuna copertura'],
			['2B49', 'OPAL'],
			['2B4A', 'MICRO'],
			['2B4B', 'MBLACK'],
			['2B4C', 'OPBLACK'],
		],
	},
	{
		cod_family: '760C',
		covers: [
			['', 'Nessuna copertura'],
			['2B49', 'OPAL'],
			['2B4A', 'MICRO'],
			['2B4B', 'MBLACK'],
			['2B4C', 'OPBLACK'],
		],
	},
	{
		cod_family: '765C',
		covers: [
			['', 'Nessuna copertura'],
			['2B49', 'OPAL'],
			['2B4A', 'MICRO'],
			['2B4B', 'MBLACK'],
			['2B4C', 'OPBLACK'],
		],
	},
	{
		cod_family: '76C0',
		covers: [
			['', 'Nessuna copertura'],
			['2B49', 'OPAL'],
			['2B4A', 'MICRO'],
			['2B4B', 'MBLACK'],
			['2B4C', 'OPBLACK'],
		],
	},
	{
		cod_family: '76D4',
		covers: [
			['', 'Nessuna copertura'],
			['2B49', 'OPAL'],
			['2B4A', 'MICRO'],
			['2B4B', 'MBLACK'],
			['2B4C', 'OPBLACK'],
		],
	},
	{ cod_family: '7918', covers: [['2B53', 'OPAL']] },
	{
		cod_family: '797C',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '79E0',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '7B0C',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFC', 'COLL'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '7B70',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '7BD4',
		covers: [
			['', 'Nessuna copertura'],
			['2AF9', 'OPAL'],
			['2AFA', 'MILK'],
			['2AFB', 'TRASP'],
			['2AFE', 'MBLACK'],
		],
	},
	{
		cod_family: '7C38',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
	{
		cod_family: '7C42',
		covers: [
			['', 'Nessuna copertura'],
			['2B2B', 'OPAL'],
			['2B2C', 'MICRO'],
			['2B2E', 'MBLACK'],
		],
	},
]

export const cover_list = [
	{
		slug: '2AF9',
		name: 'Opale',
		default: true,
		price: 0,
	},
	{
		slug: '2AFB',
		name: 'Trasparente',
		price: 0,
	},
	{
		slug: '2AFE',
		name: 'Magic Black',
		price: 2,
	},
	{
		slug: '2AFA',
		name: 'Milk',
		price: 2,
	},
	{
		slug: '2AFC',
		name: 'Lente Collimata',
		price: 4,
	},
	{
		slug: '2B03',
		name: 'PMMA Frost Quadrata',
		price: 2,
	},
	{
		slug: '2B04',
		name: 'PMMA Frost Tonda',
		price: 2,
	},
	{
		slug: '2B05',
		name: 'Opale Piatta',
		price: 0,
		default: true,
	},
	{
		slug: '2B06',
		name: 'Trasparente Piatta',
		price: 0,
	},
	{
		slug: '2B0D',
		name: 'Opale Tonda',
		price: 0,
		default: true,
	},
	{
		slug: '2B0E',
		name: 'Trasparente Tonda',
		price: 0,
	},
	{
		slug: '2B17',
		name: 'Opale Quadrata',
		price: 0,
		default: true,
	},
	{
		slug: '2B00',
		name: 'Trasparente Quadrata',
		price: 0,
	},
	{
		slug: '2B21',
		name: 'Opale',
		price: 0,
		default: true,
	},
	{
		slug: '2B22',
		name: 'Trasparente',
		price: 0,
	},
	{
		slug: '2B2B',
		name: 'Opale',
		price: 0,
		default: true,
	},
	{
		slug: '2B2C',
		name: 'Microprismata Trasparente',
		price: 0,
	},
	{
		slug: '2B2E',
		name: 'Magic Black',
		price: 2,
	},
	{
		slug: '2B49',
		name: 'Opale',
		price: 0,
		default: true,
	},
	{
		slug: '2B4A',
		name: 'Microprismata Trasparente',
		price: 0,
	},
	{
		slug: '2B4B',
		name: 'Magic Black',
		price: 2,
	},
	{
		slug: '2B35',
		name: 'Opale',
		price: 0,
		default: true,
	},
	{
		slug: '2B36',
		name: 'Microprismata Trasparente',
		price: 0,
	},
	{
		slug: '2B37',
		name: 'Magic Black',
		price: 2,
	},
	{
		slug: '2B23',
		name: 'Magic Black',
		price: 2,
	},
	{
		slug: '2B4C',
		name: 'Nera Opaca',
		price: 2,
	},
	{
		slug: '2B0F',
		name: 'Magic Black Tonda',
		price: 2,
	},
]

export default function findVariant(baseProduct, cover = '', length = 0) {
	let products = useProductsStore.getState().products
	// console.log(products)

	if (Array.isArray(products) && baseProduct?.attributes?.cod_family) {
		let filteredProducts = products.filter((p) => p.is_variant)
		let sizeCod = ''

		let productVariants = filteredProducts.reduce((arr, item) => {
			if (item?.attributes?.cod_family === baseProduct?.attributes?.cod_family && item.cod !== baseProduct.cod) {
				arr.push(item)
			}

			return arr
		}, [])

		let isCmLength = false
		if (length === 0 || length > 300 || length % 50 !== 0) {
			sizeCod = 'CM'
			isCmLength = true
		} else {
			sizeCod = length + 'CM'
		}

		let variantCod = baseProduct.cod + '.' + sizeCod + (cover ? '-' + cover : '')

		let variant = productVariants.find((p) => p.cod === variantCod)

		// console.log(productVariants, filteredProducts, variantCod, baseProduct, variant)

		return [variant, isCmLength]
	}

	return []
}

export function getProductCovers(product) {
	if (product?.attributes?.cod_family) {
		let covers = profilesRelations.find((rel) => rel.cod_family === product.attributes.cod_family)

		if (covers) {
			return covers.covers.map((coverArr) => {
				let coverInfo = cover_list.find((cover) => cover.slug === coverArr[0])
				return { value: coverArr[0], label: coverInfo?.name ? coverInfo.name : coverArr[1] }
			})
		}
	}

	return [{ value: '', label: 'Nessuna copertura' }]
}
