import { useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import '../../styles/components/Filter.scss'

export default function Filters({ attributes, searchString, setSearchString }) {
	const id = useRef(uuidv4())

	// calculate attribtues to display (todo: add filters for single columns?)
	let filters = useMemo(() => {
		if (Array.isArray(attributes)) {
			const filters = attributes.filter((attribute) => attribute.visible)
			return filters
		}
		return []
	}, [attributes])

	return (
		<div className="filter-search">
			<div className="filter-search-general">
				<input
					autoComplete="off"
					name="filter-search-input"
					className="filter-search-input"
					value={searchString}
					id={`filter${id.current}`}
					placeholder="Cerca..."
					onChange={(e) => {
						if (typeof setSearchString === 'function') {
							setSearchString(e.target.value)
						} else {
							console.error('No SetSeachString provided')
						}
					}}
				/>
				<label htmlFor={`filter${id.current}`} className="filter-search-input-icon">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
					</svg>
				</label>
			</div>
			<div>
				{Array.isArray(filters) &&
					filters.map((filter, i) => {
						return <div key={i}>AAAA {filter.key}</div>
					})}
			</div>
		</div>
	)
}
