import useDocumentStore from '../../lib/stores/document'

export default function CopyElement({ element, elementIndex, mid }) {
	const copyElement = useDocumentStore((state) => state.copyElement)

	return (
		<div
			className={`element-action element-copy icon-hover`}
			onClick={typeof copyElement === 'function' ? () => copyElement(element, elementIndex) : () => {}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
				<path fill="none" d="M0 0h24v24H0z" />
				<path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z" />
			</svg>
		</div>
	)
}
