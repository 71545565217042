import useDocumentStore from '../lib/stores/document'

export default function RemoveElement({ element, elementIndex, mid }) {
	const removeElement = useDocumentStore((state) => state.removeElement)

	return (
		<div
			className={`element-action element-remove icon-hover ${mid ? 'element-remove-mid' : ''}`}
			onClick={typeof removeElement === 'function' ? () => removeElement(element, elementIndex) : () => {}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
				<path fill="none" d="M0 0h24v24H0z" />
				<path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
			</svg>
		</div>
	)
}
