import { round, ceil, floor, format } from 'mathjs'
import { useEffect, useRef, useState } from 'react'

export default function Length({ mode, length, lengths, steps, stripIndex, profileIndex, setLengths, index, advanced, data, cutAngle, setCutAngle }) {
	const [inputLength, setInputLength] = useState(0)
	const [inputWidth, setInputWidth] = useState(0)
	const lengthRef = useRef()

	useEffect(() => {
		if (length && length.name !== undefined) {
			if (typeof length.name === 'string') setInputLength(length.name.length)
			if (typeof length.name === 'number') setInputLength(length.name.toString().length)

			const canvas = document.createElement('canvas')
			const context = canvas.getContext('2d')

			// "Montserrat", Helvetica, Arial, sans-serif
			if (lengthRef && lengthRef.current) {
				context.font = getComputedStyle(lengthRef.current).font
			} else {
				context.font = getComputedStyle(document.body).font
			}

			setInputWidth(`${context.measureText(length.name).width}px`)
			// console.log()
		}
	}, [lengths, length])

	function stringToMilimeters(numString) {
		let numOnlyString = numString
			.trim()
			.replace(/[,]/gim, '.')
			.replace(/[^0-9.]/gim, '')

		let num = parseFloat(numOnlyString)

		if (numString.includes('mm')) {
			return num
		}

		// Always transform to mm
		if (num <= (mode === 'strip' ? 100 : 6)) {
			// Transform meters to mm
			num *= 1000
		} else if (num <= (mode === 'strip' ? 10000 : 600)) {
			// Transform cm to mm
			num *= 10
		}
		return num
	}

	function stringToUnit(numString) {
		let numOnlyString = numString
			.trim()
			.replace(/[,]/gim, '.')
			.replace(/[^0-9.]/gim, '')

		let num = parseFloat(numOnlyString)

		if (numString.includes('mm')) {
			return { unit: 'mm', multiplier: 1 }
		}

		if (num <= (mode === 'strip' ? 100 : 6)) {
			return { unit: 'm', multiplier: 0.001 }
		} else if (num <= (mode === 'strip' ? 10000 : 600)) {
			return { unit: 'cm', multiplier: 0.1 }
		}
		return { unit: 'mm', multiplier: 1 }
	}

	return (
		<div className={`length-input-box ${advanced ? 'animate' : ''}`}>
			{advanced && (
				<div className="length-measurement-type">
					<h2 className="measurement-type-title">Tipologia Misura</h2>
					<div className="measurement-type-choise">
						<div className="measurement-option-list">
							{[
								{ name: 'A', measurement: 'min' },
								{ name: 'B', measurement: 'mid' },
								{ name: 'C', measurement: 'max' },
							].map((measurement, i) => {
								return (
									<div
										key={i}
										className={`measurement-type-option ${length.lenMeasurement === measurement.measurement ? 'selected' : ''}`}
										onClick={() => {
											let newLengths = [...lengths]
											if (measurement.measurement) {
												// newLengths = newLengths.map((len) => {
												// 	len.lenMeasurement = measurement.measurement
												// 	return len
												// })
												newLengths[index].lenMeasurement = measurement.measurement
											}
											setLengths(newLengths)
										}}
									>
										<span className="measurement-type-option-number">
											{i === 0
												? format(round(length.calculatedMin ? length.calculatedMin : length.value, 2), {
														notation: 'fixed',
														precision: 2,
												  })
												: i === 1
												? format(round(length.calculatedMid ? length.calculatedMid : length.value, 2), {
														notation: 'fixed',
														precision: 2,
												  })
												: format(round(length.calculatedMax ? length.calculatedMax : length.value, 2), {
														notation: 'fixed',
														precision: 2,
												  })}
											&nbsp;mm
										</span>
										{/* {measurement.name} */}
										<div className="measurement-type-option-box"></div>
									</div>
								)
							})}
						</div>
						<div className="measurement-type-image">
							<img src="./tipologia-misura.png" alt="misure" />
						</div>
					</div>
				</div>
			)}
			<div className="step-length-option">
				<div className="step-length-inputBox configurator-input-box">
					<input
						ref={lengthRef}
						className="configurator-input step-length-input"
						value={(length && length.name) || ''}
						id={`length#${index}`}
						onInput={(e) => {
							//^[+-]?[0-9]*[\.,]?[0-9]*$/gimu
							const newLengths = [...lengths]

							let milimetersString = stringToMilimeters(e.target.value)
							let unit = stringToUnit(e.target.value)

							newLengths[index].name = e.target.value
							newLengths[index].unit = unit.unit
							newLengths[index].multiplier = unit.multiplier

							if (milimetersString >= 0 && milimetersString <= 999999) {
								newLengths[index].value = milimetersString

								if (steps[stripIndex] && steps[stripIndex].selected) {
									if (steps[stripIndex].selected.cut) {
										let calculatedLength = milimetersString / (steps[stripIndex].selected.cut / 10)
										newLengths[index].maxCutLen = ceil(calculatedLength) * steps[stripIndex].selected.cut
										newLengths[index].minCutLen = floor(calculatedLength) * steps[stripIndex].selected.cut
									}
								}
							}

							setLengths(newLengths)
						}}
					/>
					<div
						className="input-measurement-unit"
						style={{ '--length': inputLength, '--offset': inputWidth, left: `min(calc(12px + ${inputWidth}), calc(100% - 30px))` }}
					>
						{length.unit || 'cm'}
					</div>
					<label htmlFor={`length#${index}`} className="configurator-input-label">
						Lunghezza <b>{advanced ? (index === 0 ? 'A' : 'B') : ''}</b>
					</label>
				</div>

				<div className="input-lengths">
					<div className="input-recommended">Lunghezze Consigliate: </div>
					<div>
						<div
							className="input-min-length"
							onClick={() => {
								const newLengths = [...lengths]

								if (length.minCutLen) {
									newLengths[index].value = length.minCutLen
									newLengths[index].name = round(length.minCutLen * (length.multiplier || 1), 2)
								}

								setLengths(newLengths)
							}}
						>
							{length && length.minCutLen
								? `${round(length.minCutLen * (length.multiplier || 1), 2)} ${length.unit}`
								: `${length.value * length.multiplier} ${length.unit}`}
						</div>
						<div
							className="input-max-length"
							onClick={() => {
								const newLengths = [...lengths]

								if (length.maxCutLen) {
									newLengths[index].value = length.maxCutLen
									newLengths[index].name = round(length.maxCutLen * (length.multiplier || 1), 2)
								}

								setLengths(newLengths)
							}}
						>
							{length && length.maxCutLen ? `${round(length.maxCutLen * (length.multiplier || 1), 2)} ${length.unit}` : ' '}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
