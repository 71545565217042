import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'

import useDocumentStore from '../../lib/stores/document'

export default function DrawSpace({ drawSpace, elementIndex, provided }) {
	const setElement = useDocumentStore((state) => state.setElement)

	function handleInput(e) {
		if (typeof setElement === 'function') {
			const element = { ...drawSpace }
			element.title = e.target.value
			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	return (
		<div className="element">
			<h1>Area disegno</h1>
			<input
				className="element-draw element-full shadow"
				name={`drawSpace`}
				label={'Area disegno'}
				value={drawSpace.title || ''}
				onChange={(e) => {
					handleInput(e)
				}}
				placeholder="Titolo..."
			/>
			{provided && <DragElement provided={provided} />}
			<RemoveElement element={drawSpace} elementIndex={elementIndex} />
		</div>
	)
}
