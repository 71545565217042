import { useState } from 'react'
import { toast } from 'react-toastify'

import useUserStore from '../../lib/stores/user'

import '../../styles/pages/Login.scss'

export default function LoginForm() {
	const login = useUserStore((state) => state.login)
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [message, setMessage] = useState('')

	function messageLabel(s) {
		switch (s) {
			case 'Unable to login. Wrong Credentials!':
				return 'Login fallito, credenziali sbagliate'
			default:
				return s
		}
	}

	return (
		<div className="login-page">
			<form
				className="login-form"
				onSubmit={(e) => {
					e.preventDefault()

					let id = toast.loading('Login...')

					// console.log(login)

					login(username, password)
						.then((res) => {
							// console.log(res)

							toast.update(id, {
								render: 'Logged in!',
								type: 'success',
								autoClose: 5000,
								isLoading: false,
								closeOnClick: true,
								pauseOnHover: true,
							})
						})
						.catch((e) => {
							// console.dir(e)
							if (e.response && e.response.data) {
								setMessage(e.response.data.message)
							} else if (e.message) {
								setMessage(e.message)
							}

							toast.update(id, {
								render: `Login fallito`,
								type: 'error',
								autoClose: 5000,
								isLoading: false,
								closeOnClick: true,
								pauseOnHover: true,
							})
						})
				}}
			>
				<label htmlFor="username" className="login-form-label">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
					</svg>
					<div className="label">Email</div>
				</label>
				<input className="login-form-input" id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
				<label htmlFor="password" className="login-form-label">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zM5 12v8h14v-8H5zm6 2h2v4h-2v-4zm6-4V9A5 5 0 0 0 7 9v1h10z" />
					</svg>
					<div className="label">Password</div>
				</label>
				<input
					className="login-form-input"
					id="password"
					name="password"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<div className="login-form-error-label">{messageLabel(message)}</div>
				<button type="submit" className="login-form-submit">
					Login
				</button>
			</form>
		</div>
	)
}
