import create from 'zustand'
// import { sha256 } from 'js-sha256'
// import axios from 'axios'

// import useDocumentStore from './document'
// import useQuotesStore from './quotes'
// import useProductsStore from './products'
// import useRegistersStore from './registers'
// import useConfigurationStore from './configurations'

// let serverUrl = process.env.REACT_APP_SERVER_URL

const initialUtilsStore = {
	hoveredItem: null,
}

// Store for user User, possible TODO: load user info from server??
const useUtilsStore = create((set, get) => ({
	...initialUtilsStore,
	setHoveredItem: (item) => set({ hoveredItem: item }),
}))

export default useUtilsStore
