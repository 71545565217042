import { useEffect, useState } from 'react'
import { getUserGroups } from '../../lib/adminFunctions'
import Loading from '../graphic/Loading'
import axios from 'axios'
import useUserStore from '../../lib/stores/user'

export default function Analytics() {
	const [analytics, setAnalytics] = useState()
	const [userGroups, setUserGroups] = useState([])
	const [loading, setLoading] = useState(true)
	const { token } = useUserStore((state) => ({ token: state.token }))

	useEffect(() => {
		//
		axios
			.get(`${process.env.REACT_APP_SERVER_URL}/api/quotes/analytics`, { headers: { authorization: token } })
			.then((res) => {
				//
				if (res.data) {
					setAnalytics(res.data)
				}

				setLoading(false)
			})
			.catch((e) => console.error(e))
	}, [token])

	// Fetch userGroups on load
	useEffect(() => {
		getUserGroups().then((res) => {
			setUserGroups(res)
		})
	}, [])

	// console.log(analytics)

	return (
		<div className="list-list">
			<div className="list-actions">
				<div
					className="action-button"
					onClick={() => {
						setLoading(true)
						axios
							.get(`${process.env.REACT_APP_SERVER_URL}/api/quotes/analytics`, { headers: { authorization: token } })
							.then((res) => {
								//
								if (res.data) {
									setAnalytics(res.data)
								}

								setLoading(false)
							})
							.catch((e) => console.error(e))

						getUserGroups().then((res) => {
							setUserGroups(res)
						})
					}}
				>
					Sincronizza
				</div>
			</div>
			{/* <div style={{ display: 'flex', justifyContent: 'center' }}>
				<Filters data={quotes} searchString={searchString} setSearchString={setSearchString} />
				<div>
					<SelectUserGroup
						value={userGroupId}
						setValue={(option, index) => {
							if (userGroupId === option.value) {
								setUserGroupId(undefined)
							} else {
								setUserGroupId(option.value)
							}
						}}
					/>
				</div>
			</div> */}
			<div className="list-row row-header">
				<div className="list-column item-medium">Gruppo utente</div>
				<div className="list-column item-medium">Totale</div>
				<div className="list-column item-medium">Media</div>
				<div className="list-column item-medium">Media Sotto €10.000</div>
				<div className="list-column item-medium">Media Sotto €5.000</div>
			</div>
			{loading ? (
				<Loading />
			) : (
				Array.isArray(analytics) &&
				analytics.map((row, i) => {
					return (
						<div key={i} className="list-row">
							<div className="list-column item-medium">
								<div className="list-column-label">Gruppo utente</div>
								<div>
									{(Array.isArray(userGroups) &&
										userGroups.find((group) => group._id === row._id) &&
										userGroups.find((group) => group._id === row._id).code) ||
										'Gruppo non trovato'}
								</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Totale</div>
								<div>{row.total.toFixed(2)}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Media</div>
								<div>{row.avarageTotal.toFixed(2)}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Media Sotto €10.000</div>
								<div>{row.avarageTotal10k.toFixed(2)}</div>
							</div>
							<div className="list-column item-medium">
								<div className="list-column-label">Media Sotto €5.000</div>
								<div>{row.avarageTotal5k.toFixed(2)}</div>
							</div>
						</div>
					)
				})
			)}
		</div>
	)
}
