import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import Zone from './elements/Zone'
import Title from './elements/Title'
import Subtitle from './elements/Subtitle'
import Description from './elements/Description'
import HiddenNotes from './elements/HiddenNotes'
import Separator from './elements/Separator'
import Shipping from './elements/Shipping'
import CutList from './elements/CutList'
import DrawSpace from './elements/DrawSpace'
import PageBreak from './elements/PageBreak'
import CutDrawing from './elements/CutDrawing'
import DocumentHeader from './DocumentHeader'

// import getDiscount from '../lib/getDiscount'
import useDocumentStore from '../lib/stores/document'
import useProductsStore from '../lib/stores/products'
import useQuotesStore from '../lib/stores/quotes'
import useUserStore from '../lib/stores/user'
import useVersionStore from '../lib/stores/version'
import useConfigurationStore from '../lib/stores/configurations'

import { upsertQuote as adminUpsertQuote } from '../lib/adminFunctions'
import checkTokenError from '../lib/checkTokenError'
import calculateItemPrice from '../lib/utils/calculateItemPrice'

import '../styles/pages/Document.scss'
import useUtilsStore from '../lib/stores/utils'
import ImageUpload from './elements/ImageUpload'

// aron's quest

export default function Document() {
	const [priceSet, setPriceSet] = useState('')
	// const isMobile = useIsMobile()
	let isMobile = false

	const {
		document,
		setElements,
		setName,
		setReferer,
		setOrderRef,
		setBusiness,
		setPhone,
		setEmail,
		toggleSearchTag,
		refreshQuote,
		setQuoteDate,
		hasVersion,
	} = useDocumentStore((state) => ({
		document: state.document,
		setElements: state.setElements,
		setName: state.setName,
		setReferer: state.setReferer,
		setOrderRef: state.setOrderRef,
		setBusiness: state.setBusiness,
		setPhone: state.setPhone,
		setEmail: state.setEmail,
		toggleSearchTag: state.toggleSearchTag,
		refreshQuote: state.refreshQuote,
		setQuoteDate: state.setQuoteDate,
		hasVersion: state.hasVersion,
		backupData: state.backupData,
		note: state.note,
		setNote: state.setNote,
	}))

	const { updateVersion } = useVersionStore((state) => ({
		updateVersion: state.updateVersion,
	}))
	const { uploadQuote, replaceQuote } = useQuotesStore((state) => ({
		uploadQuote: state.uploadQuote,
		replaceQuote: state.replaceQuote,
	}))
	const { hoveredItem } = useUtilsStore((state) => ({ hoveredItem: state.hoveredItem }))

	const { user } = useUserStore((state) => ({ logout: state.logout, user: state.user }))
	const { _hasHydrated, fetchProducts, selectedPriceList } = useProductsStore((state) => ({
		_hasHydrated: state._hasHydrated,
		fetchProducts: state.fetchProducts,
		selectedPriceList: state.selectedPriceList,
	}))
	const { configurations } = useConfigurationStore((state) => ({ configurations: state.configurations }))
	const [newSearchTag, setNewSearchTag] = useState('')

	isMobile = configurations.disableDnD

	// console.log(user)

	useEffect(() => {
		if (_hasHydrated && typeof fetchProducts === 'function') {
			fetchProducts()
		}
	}, [_hasHydrated, fetchProducts])

	useEffect(() => {
		refreshQuote()
	}, [refreshQuote])

	function setDiscount() {
		let newDocument = { ...document }

		// console.log(document)
		if (Array.isArray(newDocument && newDocument.elements)) {
			let newElements = newDocument.elements.map((element) => {
				let newElement = { ...element }
				if (newElement.type === 'zone') {
					//
					if (Array.isArray(newElement.items)) {
						newElement.items = newElement.items.map((item) => {
							let newItem = { ...item }
							newItem.discount = priceSet

							newItem = calculateItemPrice(newItem, null, selectedPriceList)

							return newItem
						})
					}
				}

				return newElement
			})

			setElements(newElements)
		}
	}

	function handleKeyDown(event) {
		if (event.ctrlKey || event.metaKey) {
			switch (event.key) {
				case 's':
					event.preventDefault()
					const id = toast.loading(`Sostituendo ${hasVersion ? 'versione' : 'preventivo'}`)

					if (hasVersion) {
						updateVersion()
							.then((res) => {
								// console.log(res)

								toast.update(id, {
									render: 'Versione sostituita!',
									type: 'success',
									autoClose: 5000,
									isLoading: false,
									closeOnClick: true,
									pauseOnHover: true,
								})
							})
							.catch((e) => {
								console.error(e)

								let message = checkTokenError(e)
								if (!message) message = 'Errore sostituendo versione'

								toast.update(id, {
									render: message,
									type: 'error',
									autoClose: 5000,
									isLoading: false,
									closeOnClick: true,
									pauseOnHover: true,
								})
							})
					} else if (user && user.authLevel >= 750 && document.userGroupId) {
						adminUpsertQuote(document.id)
							.then((res) => {
								toast.update(id, {
									render: 'Preventivo sostituito!',
									type: 'success',
									autoClose: 5000,
									isLoading: false,
									closeOnClick: true,
									pauseOnHover: true,
								})
							})
							.catch((e) => {
								console.error(e)

								let message = checkTokenError(e)
								if (!message) message = 'Errore sostituendo preventivo'

								toast.update(id, {
									render: message,
									type: 'error',
									autoClose: 5000,
									isLoading: false,
									closeOnClick: true,
									pauseOnHover: true,
								})
							})
					} else {
						if (document.id) {
							replaceQuote(document.id, document.quoteIdentifier ? false : true)
								.then((res) => {
									// console.log(res)

									toast.update(id, {
										render: 'Preventivo sostituito!',
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)

									toast.update(id, {
										render: 'Errore sostituendo preventivo',
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
						} else {
							uploadQuote()
								.then((res) => {
									// console.log(res)

									toast.update(id, {
										render: 'Preventivo salvato!',
										type: 'success',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
								.catch((e) => {
									console.error(e)

									toast.update(id, {
										render: 'Errore salvando preventivo',
										type: 'error',
										autoClose: 5000,
										isLoading: false,
										closeOnClick: true,
										pauseOnHover: true,
									})
								})
						}
					}

					break

				case 'd':
					event.preventDefault()
					// console.log(hoveredItem)
					if (hoveredItem?.variant?.cod || hoveredItem?.product?.cod) {
						navigator.clipboard.writeText(hoveredItem.variant?.cod ? hoveredItem.variant.cod : hoveredItem.product.cod)
					}
					break
				default:
			}
		}
	}

	// console.log(hoveredItem)

	useEffect(() => {
		window.removeEventListener('keydown', handleKeyDown)
		window.addEventListener('keydown', handleKeyDown)

		// cleanup this component
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
		// eslint-disable-next-line
	}, [document, user, hasVersion, hoveredItem])

	function checkElement(element) {
		switch (element.type) {
			case 'zone':
			case 'title':
			case 'subtitle':
			case 'description':
			case 'hiddenNote':
			case 'separator':
			case 'shipping':
			case 'cutList':
			case 'drawSpace':
			case 'pageBreak':
			case 'cutDrawing':
			case 'imageUpload':
				return true
			default:
				return false
		}
	}

	// console.log(document)

	function elementToRender(element, i, props) {
		// console.log(element)
		switch (element.type) {
			case 'zone':
				return <Zone zone={element} elementIndex={i} {...props} />
			case 'title':
				return <Title title={element} elementIndex={i} {...props} />
			case 'subtitle':
				return <Subtitle subtitle={element} elementIndex={i} {...props} />
			case 'description':
				return <Description description={element} elementIndex={i} {...props} />
			case 'hiddenNote':
				return <HiddenNotes note={element} elementIndex={i} {...props} />
			case 'separator':
				return <Separator separator={element} elementIndex={i} {...props} />
			case 'shipping':
				return <Shipping shipping={element} elementIndex={i} {...props} />
			case 'cutList':
				return <CutList cutList={element} elementIndex={i} {...props} />
			case 'drawSpace':
				return <DrawSpace drawSpace={element} elementIndex={i} {...props} />
			case 'pageBreak':
				return <PageBreak pageBreak={element} elementIndex={i} {...props} />
			case 'cutDrawing':
				return <CutDrawing cutDrawing={element} elementIndex={i} {...props} />
			case 'imageUpload':
				return <ImageUpload image={element} elementIndex={i} {...props} />
			default:
				return null
		}
	}

	function handleDrop(draggable, announce) {
		// console.log(announce)
		// console.log(announce.announce())

		// console.log(draggable, announce)
		if (draggable.type === 'droppableElement') {
			if (!document || !Array.isArray(document.elements) || typeof setElements !== 'function') {
				return
			}
			const elements = Array.from(document.elements)

			if (draggable.source && draggable.destination) {
				const source = draggable.source.index
				const destination = draggable.destination.index

				const [removed] = elements.splice(source, 1)
				elements.splice(destination, 0, removed)

				// let i = source
				// let j = destination
				// let temp = elements[i]
				// if (source < destination) {
				// 	for (; i < j; i++) {
				// 		elements[i] = elements[i + 1]
				// 	}
				// } else {
				// 	for (; i > j; i--) {
				// 		elements[i] = elements[i - 1]
				// 	}
				// }
				// elements[j] = temp
				setElements(elements)
			}
		} else if (draggable.type === 'droppableZoneItem') {
			const elements = Array.from(document.elements)

			if (draggable.source && draggable.destination) {
				const sourceParentIndex = draggable.source.droppableId.split('droppableItemsElem_')[1]
				const destParentIndex = draggable.destination.droppableId.split('droppableItemsElem_')[1]

				const source = draggable.source.index
				const destination = draggable.destination.index

				const [removed] = elements[sourceParentIndex].items.splice(source, 1)
				elements[destParentIndex].items.splice(destination, 0, removed)

				setElements(elements)
			}
		}
	}

	// console.log(document)

	return (
		<div className="document">
			<DocumentHeader />
			<div className="element" style={{ display: 'flex', alignContent: 'center', marginBottom: '1.5rem', flexWrap: 'wrap' }}>
				<div className="document-input-with-label">
					<div
						className="document-input__label-zone clickable"
						onClick={(e) => {
							setDiscount()
						}}
					>
						<div className="input-label">Applica Sconto</div>
						<svg className="input-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm.707 2.122L3.828 12l8.486 8.485 7.778-7.778-1.06-7.425-7.425-1.06zm2.12 6.364a2 2 0 1 1 2.83-2.829 2 2 0 0 1-2.83 2.829z" />
						</svg>
					</div>
					<input
						className="element-title title-mini element-full"
						value={priceSet}
						placeholder="Sconto totale..."
						onChange={(e) => {
							setPriceSet(e.target.value)
						}}
					/>
				</div>
				<input
					type="date"
					className="element-title title-mini element-full"
					style={{ marginBottom: '6px', width: 'auto', marginLeft: 'auto' }}
					value={(() => {
						let date = document.quoteDate || document.updatedAt || ''
						date = date.split('T')[0]
						return date
					})()}
					placeholder="Data..."
					onChange={(e) => {
						// console.log(e)
						setQuoteDate(e.target.value)
					}}
				/>
				{document.quoteDate && (
					<div onClick={() => setQuoteDate(null)}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
						</svg>
					</div>
				)}
			</div>
			{!configurations.hideTags && (
				<>
					{Array.isArray(document?.searchTags) && (
						<div className="search-tags-list">
							{document.searchTags.map((tag) => {
								return (
									<div
										onClick={() => {
											toggleSearchTag(tag)
										}}
									>
										{tag}
									</div>
								)
							})}
						</div>
					)}
					<div className="element">
						<input
							className="element-title title-mini title-space element-full"
							value={newSearchTag}
							placeholder="Tag ricerca..."
							onKeyDown={(e) => {
								if (e.key.toLowerCase() === 'enter' && newSearchTag.trim()) {
									e.preventDefault()
									e.stopPropagation()
									toggleSearchTag(newSearchTag)
									setNewSearchTag('')
								}
							}}
							onChange={(e) => {
								setNewSearchTag(e.target.value)
							}}
						/>
					</div>
					<div className="search-tags-default-list">
						<div
							className={`${Array.isArray(document?.searchTags) && document.searchTags.includes('merce difettosa') ? 'active' : ''}`}
							onClick={() => {
								toggleSearchTag('merce difettosa')
							}}
						>
							MERCE DIFETTOSA
						</div>
						<div
							className={`${Array.isArray(document?.searchTags) && document.searchTags.includes('errore spedizione') ? 'active' : ''}`}
							onClick={() => {
								toggleSearchTag('errore spedizione')
							}}
						>
							ERRORE SPEDIZIONE
						</div>
						<div
							className={`${Array.isArray(document?.searchTags) && document.searchTags.includes('mancanza merce') ? 'active' : ''}`}
							onClick={() => {
								toggleSearchTag('mancanza merce')
							}}
						>
							MANCANZA MERCE
						</div>
						<div
							className={`${Array.isArray(document?.searchTags) && document.searchTags.includes('merce danneggiata') ? 'active' : ''}`}
							onClick={() => {
								toggleSearchTag('merce danneggiata')
							}}
						>
							MERCE DANNEGGIATA
						</div>
						<div
							className={`${Array.isArray(document?.searchTags) && document.searchTags.includes('errore inserimento ordine') ? 'active' : ''}`}
							onClick={() => {
								toggleSearchTag('errore inserimento ordine')
							}}
						>
							ERRORE INSERIMENTO ORDINE
						</div>
						<div
							className={`${Array.isArray(document?.searchTags) && document.searchTags.includes('campionatura') ? 'active' : ''}`}
							onClick={() => {
								toggleSearchTag('campionatura')
							}}
						>
							CAMPIONATURA
						</div>
					</div>
				</>
			)}

			{!(configurations && configurations.hideHeaderExtraInfo) && (
				<div className="element">
					<input
						className="element-title title-mini title-space element-full"
						value={document.business || ''}
						placeholder="Azienda..."
						onChange={(e) => setBusiness(e.target.value)}
					/>
				</div>
			)}
			{!hasVersion && (
				<div className="element">
					<input
						className="element-title title-mini title-space element-full"
						// value={hasVersion ? (note !== null && note !== undefined ? note : '') : document.name || ''}
						value={document.name || ''}
						placeholder="Nome..."
						// onChange={(e) => (hasVersion ? setNote(e.target.value) : setName(e.target.value))}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
			)}
			<div className="element">
				<input
					className="element-title title-mini title-space element-full"
					value={document.email || ''}
					placeholder="Email..."
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			{!(configurations && configurations.hideHeaderExtraInfo) && (
				<div className="element">
					<input
						className="element-title title-mini title-space element-full"
						value={document.phone || ''}
						placeholder="Telefono..."
						onChange={(e) => setPhone(e.target.value)}
					/>
				</div>
			)}
			{!(configurations && configurations.hideHeaderExtraInfo) && (
				<div className="element">
					<input
						className="element-title title-mini title-space element-full"
						value={document.referer || ''}
						placeholder="Riferimento..."
						onChange={(e) => setReferer(e.target.value)}
					/>
				</div>
			)}
			{!(configurations && configurations.hideHeaderExtraInfo) && (
				<div className="element">
					<input
						className="element-title title-mini title-space element-full"
						value={document.orderRef || ''}
						placeholder="Ordine Cliente..."
						onChange={(e) => setOrderRef(e.target.value)}
					/>
				</div>
			)}
			{isMobile ? (
				<div>
					{document &&
						Array.isArray(document.elements) &&
						document.elements.map((element, i) => {
							const renderElement = checkElement(element)
							if (renderElement) {
								return (
									<div
										className="element-container"
										tabIndex="-1"
										// style={{ outline: 'none' }}
									>
										{elementToRender(element, i)}
									</div>
								)
							}
							return null
						})}
				</div>
			) : (
				<DragDropContext onDragEnd={handleDrop}>
					<Droppable droppableId={`droppableElements`} type="droppableElement">
						{(provided, snapshot) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{document &&
									Array.isArray(document.elements) &&
									document.elements.map((element, i) => {
										const renderElement = checkElement(element)
										if (renderElement) {
											return (
												<Draggable draggableId={element.id} index={i} key={element.id}>
													{(provided, snapshot) => (
														<div ref={provided.innerRef} {...provided.draggableProps} className="element-container" tabIndex="-1">
															{elementToRender(element, i, { provided: provided })}
														</div>
													)}
												</Draggable>
											)
										}
										return null
									})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</div>
	)
}
