/* eslint-disable no-useless-escape */
// eslint identifies \. in regex as useless escape

// Disclamer: This component is not well written, it served a simple purpose and 4+ others were added over the time

import { useEffect, useState, useRef } from 'react'
import isEqual from 'lodash.isequal'
import axios from 'axios'
import { cos, max, sin, unit } from 'mathjs'
import cloneDeep from 'lodash.clonedeep'
import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeftRight, faL, faWindowMinimize, faZ, faArrowRotateRight, faU } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

import ContentEditable from '../ContentEditable'
import RemoveElement from '../RemoveElement'
import DragElement from '../graphic/DragElement'
import Configurator from '../extra/Configurator'

import calculatePrice from '../extra/configuratorCalculatePrice'
import parseCustomProduct from '../../lib/utils/parseCustomProduct'
import retrieveSavedAttributes from '../../lib/retrieveSavedAttribute'
import useDocumentStore from '../../lib/stores/document'
import useModalStore from '../../lib/stores/modal'
import useProductsStore from '../../lib/stores/products'
import useUserStore from '../../lib/stores/user'

import '../../styles/components/CutDrawing.scss'
import CustomProduct from './CustomProducts'
import calculatePowerInfo, { calculatePowerSupply } from '../extra/configuratorCalculatePowerInfo'

const defaults = {
	line: {
		dimmerIndex: -1,
		inverse: false,
		cables: [
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 270 },
			},
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: 0, type: 'dot', labelAngle: 90 },
			},
		],
		profiles: [
			{
				type: 'profile',
				length: 1600,
				graphicInfos: { x: 0, y: 0, type: 'line', length: 100, angle: 0, orientation: 'horizontal', inverseLabel: false },
			},
		],
	},
	single: {
		dimmerIndex: -1,
		inverse: false,
		cables: [
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 270 },
			},
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: 0, type: 'dot', labelAngle: 45 },
			},
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 180 },
			},
		],
		profiles: [
			{
				type: 'profile',
				length: 1600,
				graphicInfos: { x: 0, y: 0, type: 'line', length: 100, angle: 0, orientation: 'horizontal', inverseLabel: false },
			},
			{
				type: 'profile',
				length: 1000,
				graphicInfos: { x: 100, y: 0, type: 'line', length: (1000 / 1600) * 100, angle: 270, orientation: 'vertical', inverseLabel: false },
			},
		],
	},
	double: {
		dimmerIndex: -1,
		inverse: false,
		cables: [
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 315 },
			},
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: 0, type: 'dot', labelAngle: 45 },
			},
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 135 },
			},
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 225 },
			},
		],
		profiles: [
			{
				type: 'profile',
				length: 1600,
				graphicInfos: { x: 0, y: 0, type: 'line', length: 100, angle: 0, orientation: 'horizontal', inverseLabel: false },
			},
			{
				type: 'profile',
				length: 1000,
				graphicInfos: { x: 100, y: 0, type: 'line', length: (1000 / 1600) * 100, angle: 270, orientation: 'vertical', inverseLabel: false },
			},
			{
				type: 'profile',
				length: 1600,
				graphicInfos: {
					x: 0,
					y: (1000 / 1600) * 100,
					type: 'line',
					length: 100,
					angle: 0,
					orientation: 'horizontal',
					inverseLabel: true,
				},
			},
			{
				type: 'profile',
				length: 1000,
				graphicInfos: {
					x: 0,
					y: 0,
					type: 'line',
					length: (1000 / 1600) * 100,
					angle: 270,
					orientation: 'vertical',
					inverseLabel: true,
				},
			},
		],
	},
	triple: {
		dimmerIndex: -1,
		inverse: false,
		cables: [
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 315 },
			},
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: 0, type: 'dot', labelAngle: 45 },
			},
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: (1000 / 1600) * 100, type: 'dot', labelAngle: 180 },
			},
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: (700 / 1600) * 100, type: 'dot', labelAngle: 180 },
			},
		],
		profiles: [
			{
				type: 'profile',
				length: 1600,
				graphicInfos: { x: 0, y: 0, type: 'line', length: 100, angle: 0, orientation: 'horizontal', inverseLabel: false },
			},
			{
				type: 'profile',
				length: 1000,
				graphicInfos: { x: 100, y: 0, type: 'line', length: (1000 / 1600) * 100, angle: 270, orientation: 'vertical', inverseLabel: false },
			},
			{
				type: 'profile',
				length: 700,
				graphicInfos: {
					x: 0,
					y: 0,
					type: 'line',
					length: (700 / 1600) * 100,
					angle: 270,
					orientation: 'vertical',
					inverseLabel: true,
				},
			},
		],
	},
	zed: {
		dimmerIndex: -1,
		inverse: false,
		cables: [
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: 0, type: 'dot', labelAngle: 0 },
			},
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 0, y: (600 / 1600) * 100, type: 'dot', labelAngle: 225 },
			},
			{
				type: 'cable',
				status: ['none', 'cable'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: (600 / 1600) * 100, type: 'dot', labelAngle: 45 },
			},
			{
				type: 'cable',
				status: ['none', 'cable', 'dimmerTouch', 'dimmerOnOff', 'pir'],
				statusIndex: 0,
				graphicInfos: { x: 100, y: 100, type: 'dot', labelAngle: 180 },
			},
		],
		profiles: [
			{
				type: 'profile',
				length: 1600,
				graphicInfos: { x: 0, y: (600 / 1600) * 100, type: 'line', length: 100, angle: 0, orientation: 'horizontal', inverseLabel: false },
			},
			{
				type: 'profile',
				length: 1000,
				graphicInfos: {
					x: 100,
					y: (600 / 1600) * 100,
					type: 'line',
					length: (1000 / 1600) * 100,
					angle: 270,
					orientation: 'vertical',
					inverseLabel: false,
				},
			},
			{
				type: 'profile',
				length: 600,
				graphicInfos: {
					x: 0,
					y: 0,
					type: 'line',
					length: (600 / 1600) * 100,
					angle: 270,
					orientation: 'vertical',
					inverseLabel: false,
				},
			},
		],
	},
}

export default function CutDrawing({ cutDrawing, elementIndex, provided, drawOnly = false }) {
	const { addModal, removeModal } = useModalStore((state) => ({ addModal: state.addModal, removeModal: state.removeModal }))
	const { selectedPriceList } = useProductsStore((state) => ({
		selectedPriceList: state.selectedPriceList,
	}))
	const token = useUserStore((state) => state.token)
	const { setElement } = useDocumentStore((state) => ({ setElement: state.setElement }))
	const [updateValueKey, setUpdateValueKey] = useState(Math.random())
	const [firstInput, setFirstInput] = useState(
		Array.isArray(cutDrawing.profiles) && cutDrawing.profiles[0] && !isNaN(cutDrawing.profiles[0].length) ? cutDrawing.profiles[0].length + '' : ''
	)
	const [secondInput, setSecondInput] = useState(
		Array.isArray(cutDrawing.profiles) && cutDrawing.profiles[1] && !isNaN(cutDrawing.profiles[1].length) ? cutDrawing.profiles[1].length + '' : ''
	)
	const [thirdInput, setThirdInput] = useState(
		Array.isArray(cutDrawing.profiles) && cutDrawing.profiles[2] && !isNaN(cutDrawing.profiles[2].length) ? cutDrawing.profiles[2].length + '' : ''
	)
	const [compositionWarning, setCompositionWarning] = useState(false)
	const compositionAutoUpdateId = useRef()
	const [compositionPriceInput, setCompositionPriceInput] = useState('')

	const [inputLoaded, setInputLoaded] = useState(false)
	const firstInputId = useRef(uuidv4())
	const secondInputId = useRef(uuidv4())
	const thirdInputId = useRef(uuidv4())

	function setNotes(value, htmlText) {
		if (typeof setElement === 'function') {
			const element = { ...cutDrawing }
			element.notes = value
			element.notesPlainText = htmlText
			setElement(element, elementIndex)
		} else {
			console.error('document Set Element not defined')
		}
	}

	function viewTypeToLabel(viewType) {
		switch (viewType) {
			case 'upView':
				return "Vista dall'alto (planimetria)"

			case 'frontView':
				return 'Vista Frontale'

			default:
		}
	}

	// Set inputs text
	useEffect(() => {
		if (!inputLoaded && Array.isArray(cutDrawing.profiles) && !isNaN(cutDrawing.profiles[0].length)) {
			setFirstInput(cutDrawing.profiles[0].length)

			if (cutDrawing.profiles.length > 1 && !isNaN(cutDrawing.profiles[1].length)) {
				setSecondInput(cutDrawing.profiles[1].length)
				if ((cutDrawing.drawType === 'triple' || cutDrawing.drawType === 'zed') && !isNaN(cutDrawing.profiles[2].length))
					setThirdInput(cutDrawing.profiles[2].length)
			}

			if (cutDrawing.compositionInfo) {
				setCompositionPriceInput(cutDrawing.compositionInfo.price)
			}
			setInputLoaded(true)
		}
	}, [cutDrawing, inputLoaded])

	// check if composition is updated, in case set auto update function
	useEffect(() => {
		if (cutDrawing.compositionInfo && cutDrawing.compositionInfo.lengths && cutDrawing.profiles) {
			if (
				cutDrawing.compositionInfo.lengths.length !== cutDrawing.profiles.length ||
				cutDrawing.profiles.find((profile, i) => profile.length !== cutDrawing.compositionInfo.lengths[i].value)
			) {
				setCompositionWarning(true)

				// Set timer to auto Update composition
				clearTimeout(compositionAutoUpdateId.current)

				let cutDrawingToUpdate = cloneDeep(cutDrawing)
				cutDrawingToUpdate.compositionInfo.lengths = cutDrawingToUpdate.profiles.map((profile) => {
					return {
						value: profile.length,
						name: profile.length,
						unit: 'mm',
						multiplier: 1,
						calculatedMax: profile.length,
						calculatedMid: profile.length,
						calculatedMin: profile.length,
					}
				})

				compositionAutoUpdateId.current = setTimeout(async () => {
					// console.log('autoUpdate composition', cutDrawingToUpdate, cutDrawing)

					let newPowerInfo = calculatePowerInfo({
						steps: cutDrawingToUpdate.compositionInfo.steps,
						lengths: cutDrawingToUpdate.compositionInfo.lengths,
					})

					calculatePowerSupply({
						steps: cutDrawingToUpdate.compositionInfo.steps,
						newPowerInfo,
						setSteps: (newSteps) => {
							cutDrawingToUpdate.compositionInfo.steps = newSteps
						},
					})

					let [rounded, newPrices] = await Promise.resolve(
						calculatePrice({
							steps: cutDrawingToUpdate.compositionInfo.steps,
							installationMethod: cutDrawingToUpdate.compositionInfo.installationMethod,
							advanced: cutDrawingToUpdate.compositionInfo.advanced,
							selectedPriceList,
							mode: cutDrawingToUpdate.compositionInfo.mode,
							compositionInfo: {
								profiles: cutDrawing.profiles.map((profile, i, arr) => {
									if (cutDrawing.drawType === 'double') return { cuts: ['angular', 'angular'] }
									if (arr.length === 1) return { cuts: ['linear', 'none'] }
									if (arr.length === 3) {
										if (i === 0) return { cuts: ['angular', 'angular'] }
										if (i === 1) return { cuts: ['angular', 'none'] }
										if (i === 2) return { cuts: ['none', 'angular'] }
									}

									if (i === 0) return { cuts: ['none', 'angular'] }
									if (i === 1) return { cuts: ['angular', 'none'] }

									return { cuts: ['linear', 'none'] }
								}),
							},
							lengths: cutDrawingToUpdate.profiles.map((profile) => {
								return {
									value: profile.length,
									name: profile.length,
									unit: 'mm',
									multiplier: 1,
									calculatedMax: profile.length,
									calculatedMid: profile.length,
									calculatedMin: profile.length,
								}
							}),
						})
					)

					let productInfo = parseCustomProduct({ ...cutDrawingToUpdate.compositionInfo, powerInfo: newPowerInfo })

					productInfo.type = cutDrawingToUpdate.compositionInfo.type
					productInfo.shape = cutDrawingToUpdate.drawType

					if (
						!isEqual(
							{ ...productInfo, price: rounded, compositionPrice: rounded, prices: newPrices, id: null, shape: cutDrawingToUpdate.drawType },
							{ ...cutDrawingToUpdate.compositionInfo, id: null, shape: cutDrawingToUpdate.drawType }
						)
					) {
						let productRes = await axios.post(
							`${process.env.REACT_APP_SERVER_URL}/api/compositions/`,
							{
								price: rounded,
								compositionPrice: rounded,
								prices: newPrices,
								steps: cutDrawingToUpdate.compositionInfo.steps,
								...productInfo,
							},
							{
								headers: {
									authorization: token,
								},
							}
						)

						if (productRes && productRes.data) {
							productInfo.id = productRes.data.id
						}

						let element = { ...cutDrawingToUpdate }
						element.compositionInfo = {
							...productInfo,
							price: rounded,
							compositionPrice: rounded,
							prices: newPrices,
							steps: cutDrawingToUpdate.compositionInfo.steps,
						}

						setCompositionPriceInput(rounded)
						setElement(element, elementIndex)
					}
				}, 1000 * 10)
			} else {
				setCompositionWarning(false)

				clearTimeout(compositionAutoUpdateId.current)
			}
		} else {
			setCompositionWarning(false)

			clearTimeout(compositionAutoUpdateId.current)
		}
	}, [cutDrawing, elementIndex, setElement, token, selectedPriceList])

	// console.log(compositionWarning, cutDrawing)

	return (
		<div className="element">
			{provided && <DragElement provided={provided} />}
			{!drawOnly && <RemoveElement element={cutDrawing} elementIndex={elementIndex} />}

			<div className="cut-drawing">
				{!drawOnly && (
					<div className="drawing-head">
						<div>
							<button
								className="cut-drawing-icon"
								onClick={() => {
									// Set drawType to single
									let element = { ...cutDrawing }
									if (element.drawType !== 'line') {
										element.drawType = 'line'
										element = { ...element, ...cloneDeep(defaults.line) }
									}

									setElement(element, elementIndex)
									setInputLoaded(false)
								}}
							>
								<FontAwesomeIcon icon={faWindowMinimize} />
							</button>
							<button
								className="cut-drawing-icon"
								onClick={() => {
									// Set drawType to single
									let element = { ...cutDrawing }
									if (element.drawType !== 'single') {
										element.drawType = 'single'
										element = { ...element, ...cloneDeep(defaults.single) }
									}
									setElement(element, elementIndex)
									setInputLoaded(false)
								}}
							>
								<FontAwesomeIcon icon={faL} />
							</button>
							<button
								className="cut-drawing-icon"
								onClick={() => {
									// Set drawType to double
									let element = { ...cutDrawing }
									if (element.drawType !== 'double') {
										element.drawType = 'double'
										element = { ...element, ...cloneDeep(defaults && defaults.double) }
									}

									setElement(element, elementIndex)
									setInputLoaded(false)
								}}
							>
								<FontAwesomeIcon icon={faSquare} />
							</button>
							<button
								className="cut-drawing-icon"
								onClick={() => {
									// Set drawType to double
									let element = { ...cutDrawing }
									if (element.drawType !== 'triple') {
										element.drawType = 'triple'
										element = { ...element, ...cloneDeep(defaults && defaults.triple) }
									}

									setElement(element, elementIndex)
									setInputLoaded(false)
								}}
							>
								<FontAwesomeIcon icon={faU} />
							</button>
							<button
								className="cut-drawing-icon"
								onClick={() => {
									// Set drawType to single
									let element = { ...cutDrawing }
									if (element.drawType !== 'zed') {
										element.drawType = 'zed'
										element = { ...element, ...cloneDeep(defaults.zed) }
									}

									setElement(element, elementIndex)
									setInputLoaded(false)
								}}
							>
								<FontAwesomeIcon icon={faZ} />
							</button>
							{/* <div style={{ textAlign: 'left' }}>
								
							</div> */}
						</div>
						<div>
							<div>
								<button
									className="cut-drawing-icon"
									onClick={() => {
										// Toggle Wall visibility
										let element = { ...cutDrawing }
										element.showWall = !element.showWall

										setElement(element, elementIndex)
									}}
								>
									<svg style={{ transform: `rotateZ(45deg)` }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path d="M3 4H21V6H3V4ZM3 19H21V21H3V19ZM3 14H21V16H3V14ZM3 9H21V11H3V9Z"></path>
									</svg>
								</button>
								<button
									className="cut-drawing-icon"
									onClick={() => {
										// Toggle label position between internal and external
										let element = { ...cutDrawing }
										element.numeredElements = !element.numeredElements

										setElement(element, elementIndex)
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
										<path d="M11 7V20H9V7H3V5H15V7H11ZM19.5507 6.5803C19.7042 6.43453 19.8 6.22845 19.8 6C19.8 5.55817 19.4418 5.2 19 5.2C18.5582 5.2 18.2 5.55817 18.2 6C18.2 6.07624 18.2107 6.14999 18.2306 6.21983L17.0765 6.54958C17.0267 6.37497 17 6.1906 17 6C17 4.89543 17.8954 4 19 4C20.1046 4 21 4.89543 21 6C21 6.57273 20.7593 7.08923 20.3735 7.45384L18.7441 9H21V10H17V9L19.5507 6.5803V6.5803Z"></path>
									</svg>
								</button>
								<button
									className="cut-drawing-icon"
									onClick={() => {
										// Toggle label position between internal and external
										let element = { ...cutDrawing }
										if (element.labelPosition === 'external') {
											element.labelPosition = 'internal'
										} else {
											element.labelPosition = 'external'
										}

										setElement(element, elementIndex)
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
										<path fill="none" d="M0 0h24v24H0z" />
										<path d="M3 11h18v2H3v-2zm15 7v3h-2v-3h-3l4-4 4 4h-3zM8 18v3H6v-3H3l4-4 4 4H8zM18 6h3l-4 4-4-4h3V3h2v3zM8 6h3l-4 4-4-4h3V3h2v3z" />
									</svg>
								</button>
								<button
									className="cut-drawing-icon"
									disabled={cutDrawing.drawType === 'line'}
									onClick={() => {
										// if drawType = "single" invert second profile position from right to left, left to right
										let element = { ...cutDrawing }
										if (element.drawType === 'single') {
											let profiles = [...element.profiles]
											let cables = [...element.cables]

											// invert position, and labels (mirroring them)
											if (!element.inverse) {
												profiles[1].graphicInfos.x = 0
												profiles[1].graphicInfos.inverseLabel = true
												cables[0].graphicInfos.x = cables[1].graphicInfos.x
												cables[1].graphicInfos.x = 0
												cables[2].graphicInfos.x = 0
												cables[0].graphicInfos.labelAngle = 90
												cables[1].graphicInfos.labelAngle = 315
												cables[2].graphicInfos.labelAngle = 180
											} else {
												profiles[1].graphicInfos.x = cables[0].graphicInfos.x
												profiles[1].graphicInfos.inverseLabel = false
												cables[1].graphicInfos.x = cables[0].graphicInfos.x
												cables[2].graphicInfos.x = cables[0].graphicInfos.x
												cables[0].graphicInfos.x = 0
												cables[0].graphicInfos.labelAngle = 270
												cables[1].graphicInfos.labelAngle = 45
												cables[2].graphicInfos.labelAngle = 180
											}
											element.profiles = profiles
											element.cables = cables

											element.inverse = !element.inverse
										} else if (element.drawType === 'triple') {
											let profiles = [...element.profiles]
											let cables = [...element.cables]

											// invert position, and labels (mirroring them)
											if (!element.inverse) {
												let tempX = profiles[1].graphicInfos.x
												profiles[1].graphicInfos.x = 0
												profiles[1].graphicInfos.inverseLabel = true
												profiles[2].graphicInfos.x = tempX
												profiles[2].graphicInfos.inverseLabel = false

												let tempCableX = cables[2].graphicInfos.x
												cables[2].graphicInfos.x = 0
												cables[3].graphicInfos.x = tempCableX
											} else {
												let tempX = profiles[2].graphicInfos.x
												profiles[1].graphicInfos.x = tempX
												profiles[1].graphicInfos.inverseLabel = false
												profiles[2].graphicInfos.x = 0
												profiles[2].graphicInfos.inverseLabel = true

												let tempCableX = cables[3].graphicInfos.x
												cables[2].graphicInfos.x = tempCableX
												cables[3].graphicInfos.x = 0
											}
											element.profiles = profiles
											element.cables = cables

											element.inverse = !element.inverse
										} else if (element.drawType === 'zed') {
											let profiles = [...element.profiles]
											let cables = [...element.cables]

											// invert position, and labels (mirroring them)
											if (!element.inverse) {
												let tempX = profiles[1].graphicInfos.x
												profiles[1].graphicInfos.x = 0
												profiles[1].graphicInfos.inverseLabel = true
												profiles[2].graphicInfos.x = tempX
												profiles[2].graphicInfos.inverseLabel = true

												// let tempY = profiles[2].graphicInfos.length
												// profiles[0].graphicInfos.y = tempY
												// profiles[1].graphicInfos.y = tempY
												// profiles[2].graphicInfos.y = 0

												let tempCableX = cables[3].graphicInfos.x
												cables[0].graphicInfos.x = tempCableX
												cables[3].graphicInfos.x = 0

												// let tempCableY = profiles[1].graphicInfos.y + profiles[1].graphicInfos.length
												// cables[3].graphicInfos.y = tempCableY
												// cables[0].graphicInfos.y = 0

												cables[1].graphicInfos.labelAngle = 315
												cables[2].graphicInfos.labelAngle = 135
											} else {
												let tempX = profiles[2].graphicInfos.x
												profiles[2].graphicInfos.x = 0
												profiles[2].graphicInfos.inverseLabel = false
												profiles[1].graphicInfos.x = tempX
												profiles[1].graphicInfos.inverseLabel = false

												// let tempY = profiles[1].graphicInfos.length
												// profiles[0].graphicInfos.y = tempY
												// profiles[2].graphicInfos.y = tempY
												// profiles[1].graphicInfos.y = 0

												let tempCableX = cables[0].graphicInfos.x
												cables[3].graphicInfos.x = tempCableX
												cables[0].graphicInfos.x = 0

												// let tempCableY = profiles[2].graphicInfos.y + profiles[2].graphicInfos.length
												// cables[0].graphicInfos.y = tempCableY
												// cables[3].graphicInfos.y = 0

												cables[1].graphicInfos.labelAngle = 225
												cables[2].graphicInfos.labelAngle = 45
											}
											element.profiles = profiles
											element.cables = cables

											element.inverse = !element.inverse
										}

										setElement(element, elementIndex)
									}}
								>
									{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M16.05 12.05L21 17l-4.95 4.95-1.414-1.414 2.536-2.537L4 18v-2h13.172l-2.536-2.536 1.414-1.414zm-8.1-10l1.414 1.414L6.828 6 20 6v2H6.828l2.536 2.536L7.95 11.95 3 7l4.95-4.95z" />
							</svg> */}
									<FontAwesomeIcon icon={faLeftRight} />
									{/* inverti */}
								</button>
								<button
									className="cut-drawing-icon"
									onClick={() => {
										// Set drawType to single
										let element = { ...cutDrawing }
										if (typeof element.rotation !== 'number') element.rotation = 0
										element.rotation += 90

										element.rotation = element.rotation % 360

										setElement(element, elementIndex)
										setInputLoaded(false)
									}}
								>
									<FontAwesomeIcon icon={faArrowRotateRight} />
								</button>
							</div>
							<div>
								<button
									className={`cut-drawing-text-button ${cutDrawing.viewType === 'upView' ? 'active' : ''}`}
									onClick={() => {
										// Toggle label position between internal and external
										let element = { ...cutDrawing }
										element.viewType = 'upView'

										setElement(element, elementIndex)
									}}
								>
									Vista dall'alto (planimetria)
								</button>
								<button
									className={`cut-drawing-text-button ${cutDrawing.viewType === 'frontView' ? 'active' : ''}`}
									onClick={() => {
										// Toggle label position between internal and external
										let element = { ...cutDrawing }
										element.viewType = 'frontView'

										setElement(element, elementIndex)
									}}
								>
									Vista Frontale
								</button>
								<button
									className={`cut-drawing-text-button ${cutDrawing.viewType === null ? 'active' : ''}`}
									onClick={() => {
										// Toggle label position between internal and external
										let element = { ...cutDrawing }
										element.viewType = null

										setElement(element, elementIndex)
									}}
								>
									Nessuna Vista
								</button>
							</div>
						</div>
					</div>
				)}

				{cutDrawing.viewType && <h2 style={{ width: '100%' }}>{viewTypeToLabel(cutDrawing.viewType)}</h2>}
				<div className="drawing-body" id={cutDrawing.id}>
					<div className="drawing-zone" style={{ transform: `rotateZ(${cutDrawing.rotation ? cutDrawing.rotation : 0}deg)` }}>
						<div className="draw-area">
							{Array.isArray(cutDrawing && cutDrawing.cables) &&
								cutDrawing.cables.map((cable, i) => {
									let labelAngle = !isNaN(cable.graphicInfos && cable.graphicInfos.labelAngle) ? cable.graphicInfos.labelAngle : 0
									let finalAngle = labelAngle + (cutDrawing.rotation ? cutDrawing.rotation : 0)

									return (
										<div
											key={i}
											className="object cable"
											style={{
												top: `${cable.graphicInfos && cable.graphicInfos.y}%`,
												left: `${cable.graphicInfos && cable.graphicInfos.x}%`,
											}}
											onClick={() => {
												const element = { ...cutDrawing }
												let newcables = [...element.cables]

												let dimmerNames = ['dimmerTouch', 'dimmerOnOff', 'pir']

												// Select next available cable, avoiding duplicate dimmers
												let nextStatusIndex = (cable.statusIndex + 1) % cable.status.length
												if (element.dimmerIndex !== -1 && element.dimmerIndex !== i) {
													let iterations = 0
													while (dimmerNames.includes(cable.status[nextStatusIndex]) && iterations < 100) {
														nextStatusIndex = (nextStatusIndex + 1) % cable.status.length
														iterations++
													}
												}

												// Set new status index and if it's a dimmer set dimmerIndex
												newcables[i].statusIndex = nextStatusIndex
												if (dimmerNames.includes(cable.status[nextStatusIndex])) {
													element.dimmerIndex = i
												} else if (element.dimmerIndex === i) {
													element.dimmerIndex = -1
												}

												element.cables = newcables
												setElement(element, elementIndex)
											}}
										>
											{Array.isArray(cable.status) && typeof cable.statusIndex === 'number' && (
												<div
													className="dot-extra"
													style={{
														transform: `translateX(-50%) rotateZ(${labelAngle + 180}deg)`,
													}}
												>
													{cable.status[cable.statusIndex] === 'cable' && (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fillRule="evenodd"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeMiterlimit="5"
															clipRule="evenodd"
															viewBox="0 0 10 35"
															stroke="#000"
															// style={{
															// 	transform: `rotate(180deg)`,
															// }}
														>
															<g transform="rotate(180 455 17.5)">
																<g transform="matrix(.0625 0 0 .23333 836.25 0)">
																	<g transform="matrix(26.6667 0 0 7.14286 -23230.7 -609.973)">
																		<path
																			fill="none"
																			strokeWidth="0.6"
																			d="M912 86s-2 .863-2 4c0 4.137 5.233 4.763 5 8-.233 3.237-2.677 3.001-3 5-.236 1.464 1 1.517 1 3"
																		></path>
																	</g>
																</g>
															</g>
														</svg>
													)}
													{cable.status[cable.statusIndex] === 'dimmerTouch' && (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fillRule="evenodd"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeMiterlimit="1.5"
															clipRule="evenodd"
															viewBox="0 0 21 71"
															xmlSpace="preserve"
															stroke="#000"
														>
															<g transform="rotate(180 415.503 35.586)">
																<g transform="matrix(.13125 0 0 .47333 703.688 0)">
																	<g transform="matrix(0 -6.91421 13.1602 0 -170.664 6246.94)">
																		<path fill="none" strokeWidth="0.38" d="M882 75H893V86H882z"></path>
																	</g>
																	<g transform="matrix(12.6984 0 0 3.52113 -10717.4 -300.691)">
																		<path
																			fill="none"
																			strokeWidth="0.6"
																			d="M912 86s-2 .863-2 4c0 4.137 5.233 4.763 5 8-.233 3.237-2.677 2.401-3 5-.183 1.471 1 1.517 1 3"
																		></path>
																	</g>
																	<g transform="matrix(0 7.69794 -27.7614 0 2047.44 -6251.61)">
																		{finalAngle % 360 < 180 && finalAngle % 360 > 0 ? (
																			<text
																				x="-830.353"
																				y="-41.034"
																				fontFamily="TitilliumWeb-SemiBold, 'Titillium Web'"
																				fontSize="2.013"
																				fontWeight="600"
																				transform="scale(-1)"
																				stroke="none"
																			>
																				TOUCH
																			</text>
																		) : (
																			<text
																				x="822.748"
																				y="42.499"
																				fontFamily="TitilliumWeb-SemiBold, 'Titillium Web'"
																				fontSize="2.013"
																				fontWeight="600"
																				stroke="none"
																			>
																				TOUCH
																			</text>
																		)}
																	</g>
																</g>
															</g>
														</svg>
													)}
													{cable.status[cable.statusIndex] === 'dimmerOnOff' && (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fillRule="evenodd"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeMiterlimit="1.5"
															clipRule="evenodd"
															viewBox="0 0 21 71"
															xmlSpace="preserve"
															stroke="#000"
														>
															<g transform="rotate(180 430.5 35.5)">
																<g transform="matrix(.13125 0 0 .47333 733.687 0)">
																	<g transform="matrix(0 -6.91421 13.1602 0 -170.664 6246.94)">
																		<path fill="none" strokeWidth="0.38" d="M882 75H893V86H882z"></path>
																	</g>
																	<g transform="matrix(12.6984 0 0 3.52113 -10717.4 -300.691)">
																		<path
																			fill="none"
																			strokeWidth="0.6"
																			d="M912 86s-2 .863-2 4c0 4.137 5.233 4.763 5 8-.233 3.237-2.677 2.401-3 5-.183 1.471 1 1.517 1 3"
																		></path>
																	</g>
																	<g transform="matrix(0 7.69794 -27.7614 0 2047.16 -6256.92)">
																		{finalAngle % 360 < 180 && finalAngle % 360 > 0 ? (
																			<text
																				x="-831.271"
																				y="-41.129"
																				fontFamily="TitilliumWeb-SemiBold, 'Titillium Web'"
																				fontSize="2.013"
																				fontWeight="600"
																				transform="scale(-1)"
																				stroke="none"
																			>
																				ON/OFF
																			</text>
																		) : (
																			<text
																				x="823.447"
																				y="42.436"
																				fontFamily="TitilliumWeb-SemiBold, 'Titillium Web'"
																				fontSize="2.013"
																				fontWeight="600"
																				stroke="none"
																			>
																				ON/OFF
																			</text>
																		)}
																	</g>
																</g>
															</g>
														</svg>
													)}
													{cable.status[cable.statusIndex] === 'pir' && (
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fillRule="evenodd"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeMiterlimit="1.5"
															clipRule="evenodd"
															viewBox="0 0 21 54"
															xmlSpace="preserve"
															stroke="#000"
														>
															<g transform="translate(-870)">
																<g transform="matrix(-.13125 0 0 -.36 997.312 54)">
																	<g transform="matrix(12.6984 0 0 4.62963 -10717.4 -395.353)">
																		<path
																			fill="none"
																			strokeWidth="0.6"
																			d="M912 86s-2 .863-2 4c0 4.137 5.233 4.763 5 8-.233 3.237-2.677 2.401-3 5-.183 1.471 1 1.517 1 3"
																		></path>
																	</g>
																	<g transform="matrix(7.2381 0 0 2.63889 -5494.98 -4.89)">
																		<circle cx="882" cy="48" r="10" fill="none" strokeWidth="1.05"></circle>
																	</g>
																	<g transform="matrix(0 10.1214 -27.7614 0 2047.73 -8224.28)">
																		{finalAngle % 360 < 180 && finalAngle % 360 > 0 ? (
																			<text
																				x="-826.32"
																				y="-41.207"
																				fontFamily="TitilliumWeb-SemiBold, 'Titillium Web'"
																				fontSize="2.013"
																				fontWeight="600"
																				transform="scale(-1)"
																				stroke="none"
																			>
																				PIR
																			</text>
																		) : (
																			<text
																				x="822.901"
																				y="42.455"
																				fontFamily="TitilliumWeb-SemiBold, 'Titillium Web'"
																				fontSize="2.013"
																				fontWeight="600"
																				stroke="none"
																			>
																				PIR
																			</text>
																		)}
																	</g>
																</g>
															</g>
														</svg>
													)}
												</div>
											)}

											{cable.status[cable.statusIndex] !== 'none' && cutDrawing.numeredElements && (
												<div
													className="dot-extra"
													style={{
														transform: `translate(calc(-50% + ${(sin(unit(labelAngle + 50, 'deg')) * 35).toFixed(
															0
														)}px), calc(-100% + ${-(cos(unit(labelAngle + 50, 'deg')) * 35).toFixed(0)}px))`,
													}}
												>
													<div
														style={{
															transform: `rotateZ(${cutDrawing.rotation ? -cutDrawing.rotation : 0}deg)`,
														}}
													>
														{i + 1}
													</div>
												</div>
											)}
											<div className="dot"></div>
											<div className="ripple">
												<div className="bubble"></div>
												<div className="bubble"></div>
											</div>
										</div>
									)
								})}

							{Array.isArray(cutDrawing && cutDrawing.profiles) &&
								cutDrawing.profiles.map((profile, i) => {
									let size = '0px'
									if (profile.graphicInfos.length >= 100) {
										size = '100%'
									} else {
										size = `${profile.graphicInfos.length}%`
									}

									let styleSize = {}
									if (profile.graphicInfos) {
										if (profile.graphicInfos.orientation === 'vertical') {
											styleSize.height = size
										} else if (profile.graphicInfos.orientation === 'horizontal') {
											styleSize.width = size
										} else if (profile.graphicInfos.orientation === 'custom') {
											//
											styleSize.width = size
										}
									}

									return (
										<div
											key={i}
											className="object profile"
											// style={{
											// 	width: `${profile.length / 30}%`,
											// 	top: `${profile.graphicInfos && profile.graphicInfos.y / 3}%`,
											// 	left: `${profile.graphicInfos && profile.graphicInfos.x / 3}%`,
											// 	transform: `rotateZ(${-((profile.graphicInfos && profile.graphicInfos.angle) || 0)}deg)`,
											// 	transformOrigin: `left`,
											// }}

											style={{
												...styleSize,
												top: `${profile.graphicInfos && profile.graphicInfos.y}%`,
												left: `${profile.graphicInfos && profile.graphicInfos.x}%`,
											}}
										>
											{cutDrawing.drawType !== 'line' && cutDrawing.showWall && (
												<div
													className={`line-wall ${
														// cutDrawing.labelPosition XOR cutDrawing.inverse
														(
															cutDrawing.labelPosition === 'internal'
																? profile.graphicInfos.inverseLabel
																: !profile.graphicInfos.inverseLabel
														)
															? 'internal'
															: ''
													} ${profile.graphicInfos && profile.graphicInfos.orientation === 'vertical' ? 'vertical' : ''}`}
												></div>
											)}
											<div
												className={`line-label ${
													// cutDrawing.labelPosition XOR cutDrawing.inverse
													(
														cutDrawing.labelPosition === 'internal'
															? !profile.graphicInfos.inverseLabel
															: profile.graphicInfos.inverseLabel
													)
														? 'internal'
														: ''
												} ${profile.graphicInfos && profile.graphicInfos.orientation === 'vertical' ? 'vertical' : ''}`}
											>
												{/* {profile.length}&nbsp;mm */}
												{i === 0 ? (
													<div
														className="drawing-input"
														style={{
															transform: `rotateZ(${cutDrawing.rotation ? -cutDrawing.rotation : 0}deg)`,
															outline: 'none',
															border: 'none',
														}}
													>
														<input
															style={{ outline: 'none', border: 'none' }}
															id={firstInputId && firstInputId.current}
															value={firstInput}
															onChange={(e) => {
																// if input is a number, set first input / first length
																if (e.target.value.match(/^[+-]?[0-9]*[\.,]?[0-9]*$/gimu)) {
																	const element = { ...cutDrawing }
																	let profiles = [...element.profiles]
																	let cables = [...element.cables]

																	if (!isNaN(parseFloat(e.target.value))) {
																		if (element.drawType === 'line') {
																			profiles[0].length = parseFloat(e.target.value)
																			profiles[0].graphicInfos.length = 100
																		} else {
																			// Set profile length, calculate new Max Length
																			profiles[0].length = parseFloat(e.target.value)
																			let newMaxLength = max(profiles[0].length, profiles[1].length)
																			if (cutDrawing.drawType === 'triple' && profiles[2])
																				newMaxLength = max(newMaxLength, profiles[2].length)
																			if (cutDrawing.drawType === 'zed' && profiles[2]) {
																				newMaxLength = max(newMaxLength, profiles[1].length + profiles[2].length)
																			}

																			// Set new Graphic lengths and positions, Checking if proportions changed on other profile, cables
																			profiles[0].graphicInfos.length = max(50, (profiles[0].length / newMaxLength) * 100)
																			profiles[1].graphicInfos.length = max(50, (profiles[1].length / newMaxLength) * 100)
																			cables[2].graphicInfos.y = max(50, (profiles[1].length / newMaxLength) * 100)

																			// if drawing is triple also fix 3rd profile Y
																			if (element.drawType === 'triple' && profiles[2]) {
																				profiles[2].graphicInfos.length = max(
																					50,
																					(profiles[2].length / newMaxLength) * 100
																				)
																				cables[3].graphicInfos.y = max(50, (profiles[2].length / newMaxLength) * 100)
																			}
																			if (element.drawType === 'zed' && profiles[2]) {
																				profiles[0].graphicInfos.length = max(
																					30,
																					(profiles[0].length / newMaxLength) * 100
																				)
																				cables[2].graphicInfos.y = max(30, (profiles[1].length / newMaxLength) * 100)
																				profiles[1].graphicInfos.length = max(
																					30,
																					(profiles[1].length / newMaxLength) * 100
																				)
																				profiles[2].graphicInfos.length = max(
																					30,
																					(profiles[2].length / newMaxLength) * 100
																				)
																				profiles[0].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																				profiles[1].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																				cables[3].graphicInfos.y =
																					max(30, (profiles[1].length / newMaxLength) * 100) +
																					max(30, (profiles[2].length / newMaxLength) * 100)
																				cables[1].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																				cables[2].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																			}

																			// If drawType = double, set other profiles / cables lengths and positions
																			if (element.drawType === 'double' && profiles[2]) {
																				profiles[2].length = profiles[0].length
																				profiles[2].graphicInfos.length = profiles[0].graphicInfos.length

																				// Check if proportions changed on other profile
																				profiles[3].length = profiles[1].length
																				profiles[3].graphicInfos.length = profiles[1].graphicInfos.length

																				profiles[2].graphicInfos.y = max(50, (profiles[1].length / newMaxLength) * 100)
																				if (cables[3])
																					cables[3].graphicInfos.y = max(
																						50,
																						(profiles[1].length / newMaxLength) * 100
																					)
																			}

																			// if inverse is not set set right side profile infos
																			if (!element.inverse) {
																				if (element.drawType === 'zed') {
																					profiles[1].graphicInfos.x = max(
																						30,
																						(profiles[0].length / newMaxLength) * 100
																					)
																					cables[2].graphicInfos.x = max(
																						30,
																						(profiles[0].length / newMaxLength) * 100
																					)
																					cables[3].graphicInfos.x = max(
																						30,
																						(profiles[0].length / newMaxLength) * 100
																					)
																				} else {
																					profiles[1].graphicInfos.x = max(
																						50,
																						(profiles[0].length / newMaxLength) * 100
																					)
																					cables[1].graphicInfos.x = max(
																						50,
																						(profiles[0].length / newMaxLength) * 100
																					)
																					cables[2].graphicInfos.x = max(
																						50,
																						(profiles[0].length / newMaxLength) * 100
																					)
																				}
																			} else {
																				cables[0].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)

																				// if drawing is triple also fix 3rd profile X
																				if (element.drawType === 'triple' && profiles[2]) {
																					profiles[2].graphicInfos.x = max(
																						50,
																						(profiles[0].length / newMaxLength) * 100
																					)
																					cables[3].graphicInfos.x = max(
																						50,
																						(profiles[0].length / newMaxLength) * 100
																					)
																				}
																				if (element.drawType === 'zed' && profiles[2]) {
																					cables[0].graphicInfos.x = max(
																						30,
																						(profiles[0].length / newMaxLength) * 100
																					)
																					profiles[2].graphicInfos.x = max(
																						30,
																						(profiles[0].length / newMaxLength) * 100
																					)
																					cables[2].graphicInfos.x = max(
																						30,
																						(profiles[0].length / newMaxLength) * 100
																					)
																				}
																			}
																		}
																	}
																	element.profiles = profiles
																	element.cables = cables

																	setFirstInput(e.target.value)
																	setElement(element, elementIndex)
																}
															}}
														/>
														<label
															htmlFor={firstInputId && firstInputId.current}
															className="drawing-input-label"
															style={{ '--left-label-offset': `${Math.min(firstInput.toString().length, 7) * 10 + 6}px` }}
														>
															mm
														</label>
													</div>
												) : i === 1 && cutDrawing.drawType !== 'line' ? (
													<div
														className="drawing-input"
														style={{ transform: `rotateZ(${cutDrawing.rotation ? -cutDrawing.rotation : 0}deg)` }}
													>
														<input
															id={secondInputId && secondInputId.current}
															value={secondInput}
															onChange={(e) => {
																// if input is a number, set first input / first length
																if (e.target.value.match(/^[+-]?[0-9]*[\.,]?[0-9]*$/gimu)) {
																	const element = { ...cutDrawing }
																	let profiles = [...element.profiles]
																	let cables = [...element.cables]

																	if (!isNaN(parseFloat(e.target.value))) {
																		// Set profile length, calculate new Max Length
																		profiles[1].length = parseFloat(e.target.value)
																		let newMaxLength = max(profiles[0].length, profiles[1].length)
																		if (cutDrawing.drawType === 'triple' && profiles[2])
																			newMaxLength = max(newMaxLength, profiles[2].length)
																		if (cutDrawing.drawType === 'zed' && profiles[2]) {
																			newMaxLength = max(newMaxLength, profiles[1].length + profiles[2].length)
																		}

																		// Set new Graphic lengths and positions, Checking if proportions changed on other profile, cables
																		profiles[0].graphicInfos.length = max(50, (profiles[0].length / newMaxLength) * 100)
																		profiles[1].graphicInfos.length = max(50, (profiles[1].length / newMaxLength) * 100)
																		cables[2].graphicInfos.y = max(50, (profiles[1].length / newMaxLength) * 100)

																		if (element.drawType === 'triple' && profiles[2]) {
																			profiles[2].graphicInfos.length = max(50, (profiles[2].length / newMaxLength) * 100)
																			cables[3].graphicInfos.y = max(50, (profiles[2].length / newMaxLength) * 100)
																		}
																		if (element.drawType === 'zed' && profiles[2]) {
																			profiles[0].graphicInfos.length = max(30, (profiles[0].length / newMaxLength) * 100)
																			cables[2].graphicInfos.y = max(30, (profiles[1].length / newMaxLength) * 100)
																			profiles[1].graphicInfos.length = max(30, (profiles[1].length / newMaxLength) * 100)
																			profiles[2].graphicInfos.length = max(30, (profiles[2].length / newMaxLength) * 100)
																			profiles[0].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																			profiles[1].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																			cables[3].graphicInfos.y =
																				max(30, (profiles[1].length / newMaxLength) * 100) +
																				max(30, (profiles[2].length / newMaxLength) * 100)
																			cables[1].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																			cables[2].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																		}

																		// If drawType = double, set other profiles / cables lengths and positions
																		if (element.drawType === 'double' && profiles[3]) {
																			profiles[3].length = profiles[1].length
																			profiles[3].graphicInfos.length = profiles[1].graphicInfos.length

																			profiles[2].graphicInfos.y = max(50, (profiles[1].length / newMaxLength) * 100)
																			if (cables[3])
																				cables[3].graphicInfos.y = max(50, (profiles[1].length / newMaxLength) * 100)

																			// Check if proportions changed on other profile
																			profiles[2].length = profiles[0].length
																			profiles[2].graphicInfos.length = profiles[0].graphicInfos.length
																		}

																		// if inverse is not set set right side profile infos
																		if (!element.inverse) {
																			if (element.drawType === 'zed') {
																				profiles[1].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				cables[2].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				cables[3].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																			} else {
																				profiles[1].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																				cables[1].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																				cables[2].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																			}
																		} else {
																			cables[0].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)

																			// if drawing is triple also fix 3rd profile X
																			if (element.drawType === 'triple' && profiles[2]) {
																				profiles[2].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																				cables[3].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																			}
																			if (element.drawType === 'zed' && profiles[2]) {
																				cables[0].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				profiles[2].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				cables[2].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																			}
																		}
																	}
																	element.profiles = profiles
																	element.cables = cables

																	setSecondInput(e.target.value)
																	setElement(element, elementIndex)
																}
															}}
														/>
														<label
															htmlFor={secondInputId && secondInputId.current}
															className="drawing-input-label"
															style={{ '--left-label-offset': `${Math.min(secondInput.toString().length, 7) * 10 + 6}px` }}
														>
															mm
														</label>
													</div>
												) : i === 2 && (cutDrawing.drawType === 'triple' || cutDrawing.drawType === 'zed') ? (
													<div
														className="drawing-input"
														style={{ transform: `rotateZ(${cutDrawing.rotation ? -cutDrawing.rotation : 0}deg)` }}
													>
														<input
															id={thirdInputId && thirdInputId.current}
															value={thirdInput}
															onChange={(e) => {
																// if input is a number, set first input / first length
																if (e.target.value.match(/^[+-]?[0-9]*[\.,]?[0-9]*$/gimu)) {
																	const element = { ...cutDrawing }
																	let profiles = [...element.profiles]
																	let cables = [...element.cables]

																	if (!isNaN(parseFloat(e.target.value))) {
																		// Set profile length, calculate new Max Length
																		profiles[2].length = parseFloat(e.target.value)
																		let newMaxLength = max(profiles[0].length, profiles[1].length, profiles[2].length)
																		if (cutDrawing.drawType === 'zed' && profiles[2]) {
																			newMaxLength = max(newMaxLength, profiles[1].length + profiles[2].length)
																		}

																		// Set new Graphic lengths and positions, Checking if proportions changed on other profile, cables
																		profiles[0].graphicInfos.length = max(50, (profiles[0].length / newMaxLength) * 100)
																		profiles[1].graphicInfos.length = max(50, (profiles[1].length / newMaxLength) * 100)
																		cables[2].graphicInfos.y = max(50, (profiles[1].length / newMaxLength) * 100)

																		if (element.drawType === 'triple' && profiles[2]) {
																			profiles[2].graphicInfos.length = max(50, (profiles[2].length / newMaxLength) * 100)
																			cables[3].graphicInfos.y = max(50, (profiles[2].length / newMaxLength) * 100)
																		}
																		if (element.drawType === 'zed' && profiles[2]) {
																			profiles[0].graphicInfos.length = max(30, (profiles[0].length / newMaxLength) * 100)
																			cables[2].graphicInfos.y = max(30, (profiles[1].length / newMaxLength) * 100)
																			profiles[1].graphicInfos.length = max(30, (profiles[1].length / newMaxLength) * 100)
																			profiles[2].graphicInfos.length = max(30, (profiles[2].length / newMaxLength) * 100)
																			profiles[0].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																			profiles[1].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																			cables[3].graphicInfos.y =
																				max(30, (profiles[1].length / newMaxLength) * 100) +
																				max(30, (profiles[2].length / newMaxLength) * 100)
																			cables[1].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																			cables[2].graphicInfos.y = max(30, (profiles[2].length / newMaxLength) * 100)
																		}

																		// if inverse is not set set right side profile infos
																		if (!element.inverse) {
																			if (element.drawType === 'zed') {
																				profiles[1].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				cables[2].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				cables[3].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																			} else {
																				profiles[1].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																				cables[1].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																				cables[2].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																			}
																		} else {
																			cables[0].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)

																			// if drawing is triple also fix 3rd profile X
																			if (element.drawType === 'triple' && profiles[2]) {
																				profiles[2].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																				cables[3].graphicInfos.x = max(50, (profiles[0].length / newMaxLength) * 100)
																			}
																			if (element.drawType === 'zed' && profiles[2]) {
																				cables[0].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				profiles[2].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																				cables[2].graphicInfos.x = max(30, (profiles[0].length / newMaxLength) * 100)
																			}
																		}
																	}
																	element.profiles = profiles
																	element.cables = cables

																	setThirdInput(e.target.value)
																	setElement(element, elementIndex)
																}
															}}
														/>
														<label
															htmlFor={thirdInputId && thirdInputId.current}
															className="drawing-input-label"
															style={{ '--left-label-offset': `${Math.min(thirdInput.toString().length, 7) * 10 + 6}px` }}
														>
															mm
														</label>
													</div>
												) : null}
											</div>
											<div
												className={`line ${profile.graphicInfos && profile.graphicInfos.orientation === 'vertical' ? 'vertical' : ''}`}
											></div>
										</div>
									)
								})}
						</div>
					</div>
				</div>
			</div>

			{compositionWarning && <h2 style={{ borderBottom: 'solid 3px red' }}>Composizione non aggiornata</h2>}
			{cutDrawing && cutDrawing.compositionInfo && (
				<div className={`composition-info`} style={{ textAlign: 'left' }}>
					<CustomProduct itemInfo={cutDrawing.compositionInfo} quantity={1} composition={true} />

					<div className="composition-price">
						<span className="composition-price-label">Prezzo: </span>
						<input
							className="composition-price-input"
							value={compositionPriceInput}
							onChange={(e) => {
								//
								if (!isNaN(parseFloat(e.target.value))) {
									let element = { ...cutDrawing }
									element.compositionInfo.price = parseFloat(e.target.value)

									setElement(element, elementIndex)
								}

								setCompositionPriceInput(e.target.value)
							}}
						/>
					</div>
				</div>
			)}
			{/* {cutDrawing && cutDrawing.compositionInfo && <div>AAAAAAAAAAAAAAAAAAAAAAAAAAA</div>} */}

			{!drawOnly && (
				<>
					{cutDrawing && cutDrawing.compositionInfo ? (
						<div className="composition-buttons-list">
							<div
								className="composition-button"
								onClick={() => {
									let element = { ...cutDrawing }
									delete element.compositionInfo

									setElement(element, elementIndex)
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
									fill="currentColor"
									style={{ marginRight: '3px' }}
								>
									<path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z"></path>
								</svg>
								Rimuovi Composizione
							</div>
							<div
								className="composition-button"
								onClick={() => {
									if (typeof addModal === 'function') {
										let savedAttribute = retrieveSavedAttributes()
										addModal({
											modal: (
												<div style={{ width: '85vw', height: '85vh' }}>
													<Configurator
														importInfo={cutDrawing.compositionInfo}
														// configMode="lamp"
														fixedLengths={cutDrawing.profiles.map((profile) => {
															return {
																value: profile.length,
																name: profile.length,
																unit: 'mm',
																multiplier: 1,
																calculatedMax: profile.length,
																calculatedMid: profile.length,
																calculatedMin: profile.length,
															}
														})}
														compositionInfo={{
															profiles: cutDrawing.profiles.map((profile, i, arr) => {
																if (cutDrawing.drawType === 'double') return { cuts: ['angular', 'angular'] }
																if (arr.length === 1) return { cuts: ['linear', 'none'] }
																if (arr.length === 3) {
																	if (i === 0) return { cuts: ['angular', 'angular'] }
																	if (i === 1) return { cuts: ['angular', 'none'] }
																	if (i === 2) return { cuts: ['none', 'angular'] }
																}

																if (i === 0) return { cuts: ['none', 'angular'] }
																if (i === 1) return { cuts: ['angular', 'none'] }

																return { cuts: ['linear', 'none'] }
															}),
														}}
														exportFunction={async ({ price, ...productData }) => {
															// let lengths = cutDrawing.profiles.map((profile) => {
															// 	return {
															// 		value: profile.length,
															// 		name: profile.length,
															// 		unit: 'mm',
															// 		multiplier: 1,
															// 		calculatedMax: profile.length,
															// 		calculatedMid: profile.length,
															// 		calculatedMin: profile.length,
															// 	}
															// })

															let newPowerInfo = calculatePowerInfo({ steps: productData.steps, lengths: productData.lengths })

															calculatePowerSupply({
																steps: productData.steps,
																newPowerInfo,
																setSteps: (newSteps) => {
																	productData.steps = newSteps
																},
															})

															let [rounded, newPrices] = await Promise.resolve(
																calculatePrice({
																	steps: productData.steps,
																	installationMethod: productData.installationMethod,
																	advanced: productData.advanced,
																	selectedPriceList,
																	mode: productData.mode,
																	compositionInfo: {
																		profiles: cutDrawing.profiles.map((profile, i, arr) => {
																			if (cutDrawing.drawType === 'double') return { cuts: ['angular', 'angular'] }
																			if (arr.length === 1) return { cuts: ['linear', 'none'] }
																			if (arr.length === 3) {
																				if (i === 0) return { cuts: ['angular', 'angular'] }
																				if (i === 1) return { cuts: ['angular', 'none'] }
																				if (i === 2) return { cuts: ['none', 'angular'] }
																			}

																			if (i === 0) return { cuts: ['none', 'angular'] }
																			if (i === 1) return { cuts: ['angular', 'none'] }

																			return { cuts: ['linear', 'none'] }
																		}),
																	},
																	lengths: productData.lengths,
																})
															)

															let productInfo = parseCustomProduct({ ...productData, powerInfo: newPowerInfo })

															productInfo.type = 'customLamp'
															productInfo.shape = cutDrawing.drawType

															if (
																!isEqual(
																	{
																		...productInfo,
																		price: rounded,
																		compositionPrice: rounded,
																		prices: newPrices,
																		id: null,
																		shape: cutDrawing.drawType,
																	},
																	{ ...cutDrawing.compositionInfo, id: null, shape: cutDrawing.drawType }
																)
															) {
																let productRes = await axios.post(
																	`${process.env.REACT_APP_SERVER_URL}/api/compositions/`,
																	{
																		price: rounded,
																		compositionPrice: rounded,
																		prices: newPrices,
																		steps: productData.steps,
																		...productInfo,
																	},
																	{
																		headers: {
																			authorization: token,
																		},
																	}
																)

																if (productRes && productRes.data) {
																	productInfo.id = productRes.data.id
																}

																clearTimeout(compositionAutoUpdateId.current)

																let element = { ...cutDrawing }
																element.compositionInfo = {
																	...productInfo,
																	price: rounded,
																	compositionPrice: rounded,
																	prices: newPrices,
																	steps: productData.steps,
																}

																setCompositionPriceInput(rounded)
																setElement(element, elementIndex)
															}

															removeModal('ConfiguratorModal')
														}}
														closeFunction={() => removeModal('ConfiguratorModal')}
														attributeInfo={savedAttribute}
														selectedPriceList={selectedPriceList}
													/>
												</div>
											),
											id: 'ConfiguratorModal',
											background: true,
											replaceModal: true,
											disposableModal: true,
										})
									} else {
										console.log('addModal not funciton?', addModal)
									}
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
								</svg>
								Cambia Composizione
							</div>
						</div>
					) : (
						<div className="composition-buttons-list">
							<div
								className="composition-button"
								onClick={() => {
									if (typeof addModal === 'function') {
										let savedAttribute = retrieveSavedAttributes()
										addModal({
											modal: (
												<div style={{ width: '85vw', height: '85vh' }}>
													<Configurator
														configMode="profile"
														fixedLengths={cutDrawing.profiles.map((profile) => {
															return {
																value: profile.length,
																name: profile.length,
																unit: 'mm',
																multiplier: 1,
																calculatedMax: profile.length,
																calculatedMid: profile.length,
																calculatedMin: profile.length,
															}
														})}
														compositionInfo={{
															profiles: cutDrawing.profiles.map((profile, i, arr) => {
																if (cutDrawing.drawType === 'double') return { cuts: ['angular', 'angular'] }
																if (arr.length === 1) return { cuts: ['linear', 'none'] }
																if (arr.length === 3) {
																	if (i === 0) return { cuts: ['angular', 'angular'] }
																	if (i === 1) return { cuts: ['angular', 'none'] }
																	if (i === 2) return { cuts: ['none', 'angular'] }
																}

																if (i === 0) return { cuts: ['none', 'angular'] }
																if (i === 1) return { cuts: ['angular', 'none'] }

																return { cuts: ['linear', 'none'] }
															}),
														}}
														exportFunction={async ({ price, ...productData }) => {
															// let lengths = cutDrawing.profiles.map((profile) => {
															// 	return {
															// 		value: profile.length,
															// 		name: profile.length,
															// 		unit: 'mm',
															// 		multiplier: 1,
															// 		calculatedMax: profile.length,
															// 		calculatedMid: profile.length,
															// 		calculatedMin: profile.length,
															// 	}
															// })

															let newPowerInfo = calculatePowerInfo({ steps: productData.steps, lengths: productData.lengths })

															calculatePowerSupply({
																steps: productData.steps,
																newPowerInfo,
																setSteps: (newSteps) => {
																	productData.steps = newSteps
																},
															})

															let [rounded, newPrices] = await Promise.resolve(
																calculatePrice({
																	steps: productData.steps,
																	installationMethod: productData.installationMethod,
																	advanced: productData.advanced,
																	selectedPriceList,
																	mode: productData.mode,
																	compositionInfo: {
																		profiles: cutDrawing.profiles.map((profile, i, arr) => {
																			if (cutDrawing.drawType === 'double') return { cuts: ['angular', 'angular'] }
																			if (arr.length === 1) return { cuts: ['linear', 'none'] }
																			if (arr.length === 3) {
																				if (i === 0) return { cuts: ['angular', 'angular'] }
																				if (i === 1) return { cuts: ['angular', 'none'] }
																				if (i === 2) return { cuts: ['none', 'angular'] }
																			}

																			if (i === 0) return { cuts: ['none', 'angular'] }
																			if (i === 1) return { cuts: ['angular', 'none'] }

																			return { cuts: ['linear', 'none'] }
																		}),
																	},
																	lengths: productData.lengths,
																})
															)

															let productInfo = parseCustomProduct({ ...productData, powerInfo: newPowerInfo })

															productInfo.type = 'customProfile'
															productInfo.shape = cutDrawing.drawType

															//
															let productRes = await axios.post(
																`${process.env.REACT_APP_SERVER_URL}/api/compositions/`,
																{
																	price: rounded,
																	compositionPrice: rounded,
																	prices: newPrices,
																	steps: productData.steps,
																	...productInfo,
																},
																{
																	headers: {
																		authorization: token,
																	},
																}
															)

															if (productRes && productRes.data) {
																productInfo.id = productRes.data.id
															}

															// addItem({
															// 	type: 'customProduct',
															// 	productInfo: productInfo,
															// 	price: price,
															// 	total: price,
															// 	priceCad: price,
															// })

															let element = { ...cutDrawing }
															element.compositionInfo = {
																...productInfo,
																price: rounded,
																compositionPrice: rounded,
																prices: newPrices,
																steps: productData.steps,
															}

															setElement(element, elementIndex)

															removeModal('ConfiguratorModal')
														}}
														closeFunction={() => removeModal('ConfiguratorModal')}
														attributeInfo={savedAttribute}
														selectedPriceList={selectedPriceList}
													/>
												</div>
											),
											id: 'ConfiguratorModal',
											background: true,
											replaceModal: true,
											disposableModal: true,
										})
									} else {
										console.log('addModal not funciton?', addModal)
									}
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
								</svg>
								Aggiungi Composizione Profilo
							</div>
							<div
								className="composition-button"
								onClick={() => {
									if (typeof addModal === 'function') {
										let savedAttribute = retrieveSavedAttributes()
										addModal({
											modal: (
												<div style={{ width: '85vw', height: '85vh' }}>
													<Configurator
														configMode="lamp"
														fixedLengths={cutDrawing.profiles.map((profile) => {
															return {
																value: profile.length,
																name: profile.length,
																unit: 'mm',
																multiplier: 1,
																calculatedMax: profile.length,
																calculatedMid: profile.length,
																calculatedMin: profile.length,
															}
														})}
														compositionInfo={{
															profiles: cutDrawing.profiles.map((profile, i, arr) => {
																if (cutDrawing.drawType === 'double') return { cuts: ['angular', 'angular'] }
																if (arr.length === 1) return { cuts: ['linear', 'none'] }
																if (arr.length === 3) {
																	if (i === 0) return { cuts: ['angular', 'angular'] }
																	if (i === 1) return { cuts: ['angular', 'none'] }
																	if (i === 2) return { cuts: ['none', 'angular'] }
																}

																if (i === 0) return { cuts: ['none', 'angular'] }
																if (i === 1) return { cuts: ['angular', 'none'] }

																return { cuts: ['linear', 'none'] }
															}),
														}}
														exportFunction={async ({ price, ...productData }) => {
															// let lengths = cutDrawing.profiles.map((profile) => {
															// 	return {
															// 		value: profile.length,
															// 		name: profile.length,
															// 		unit: 'mm',
															// 		multiplier: 1,
															// 		calculatedMax: profile.length,
															// 		calculatedMid: profile.length,
															// 		calculatedMin: profile.length,
															// 	}
															// })

															let newPowerInfo = calculatePowerInfo({ steps: productData.steps, lengths: productData.lengths })

															calculatePowerSupply({
																steps: productData.steps,
																newPowerInfo,
																setSteps: (newSteps) => {
																	productData.steps = newSteps
																},
															})

															let [rounded, newPrices] = await Promise.resolve(
																calculatePrice({
																	steps: productData.steps,
																	installationMethod: productData.installationMethod,
																	advanced: productData.advanced,
																	selectedPriceList,
																	mode: productData.mode,
																	compositionInfo: {
																		profiles: cutDrawing.profiles.map((profile, i, arr) => {
																			if (cutDrawing.drawType === 'double') return { cuts: ['angular', 'angular'] }
																			if (arr.length === 1) return { cuts: ['linear', 'none'] }
																			if (arr.length === 3) {
																				if (i === 0) return { cuts: ['angular', 'angular'] }
																				if (i === 1) return { cuts: ['angular', 'none'] }
																				if (i === 2) return { cuts: ['none', 'angular'] }
																			}

																			if (i === 0) return { cuts: ['none', 'angular'] }
																			if (i === 1) return { cuts: ['angular', 'none'] }

																			return { cuts: ['linear', 'none'] }
																		}),
																	},
																	lengths: productData.lengths,
																})
															)

															let productInfo = parseCustomProduct({ ...productData, powerInfo: newPowerInfo })

															productInfo.type = 'customLamp'
															productInfo.shape = cutDrawing.drawType

															//
															let productRes = await axios.post(
																`${process.env.REACT_APP_SERVER_URL}/api/compositions/`,
																{
																	price: rounded,
																	prices: newPrices,
																	steps: productData.steps,
																	...productInfo,
																},
																{
																	headers: {
																		authorization: token,
																	},
																}
															)

															if (productRes && productRes.data) {
																productInfo.id = productRes.data.id
															}

															// addItem({
															// 	type: 'customProduct',
															// 	productInfo: productInfo,
															// 	price: price,
															// 	total: price,
															// 	priceCad: price,
															// })

															let element = { ...cutDrawing }
															element.compositionInfo = {
																...productInfo,
																price: rounded,
																prices: newPrices,
																steps: productData.steps,
															}

															setCompositionPriceInput(rounded)
															setElement(element, elementIndex)

															removeModal('ConfiguratorModal')
														}}
														closeFunction={() => removeModal('ConfiguratorModal')}
														attributeInfo={savedAttribute}
														selectedPriceList={selectedPriceList}
													/>
												</div>
											),
											id: 'ConfiguratorModal',
											background: true,
											replaceModal: true,
											disposableModal: true,
										})
									} else {
										console.log('addModal not funciton?', addModal)
									}
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ marginRight: '3px' }}>
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z" />
								</svg>
								Aggiungi Composizione Lampada
							</div>
						</div>
					)}
					<div
						style={{
							position: 'relative',
						}}
					>
						<ContentEditable
							className="element-description element-full shadow"
							value={cutDrawing.notes || ''}
							onInput={(e) => {
								setNotes(e.target.value, e.target.htmlAsText)
							}}
							placeholder="Note..."
							updateValue={updateValueKey}
						/>
						<div
							className="element-action icon-hover"
							style={{ position: 'absolute', right: 0, transform: `translate(40%, -50%)` }}
							onClick={(e) => {
								const element = { ...cutDrawing }
								element.notes = typeof element.notesPlainText === 'string' ? element.notesPlainText.replace(/[\n\r]/gimu, '<br />') : ''
								setElement(element, elementIndex)
								setUpdateValueKey(Math.random())
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M16.536 15.95l2.12-2.122-3.181-3.182 3.535-3.535-2.12-2.121-3.536 3.535-3.182-3.182L8.05 7.464l8.486 8.486zm-1.415 1.414L6.636 8.879l-2.828 2.828 8.485 8.485 2.828-2.828zM13.354 5.697l2.828-2.829a1 1 0 0 1 1.414 0l3.536 3.536a1 1 0 0 1 0 1.414l-2.829 2.828 2.475 2.475a1 1 0 0 1 0 1.415L13 22.314a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414l7.778-7.778a1 1 0 0 1 1.415 0l2.475 2.475z" />
							</svg>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
